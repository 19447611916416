import React, { useEffect, useState} from "react";
import { Link as ScreensLink, useNavigate } from "react-router-dom";
import { Alert } from "@aws-amplify/ui-react";
import SearchQueryScreen from "./SearchQueryScreen.js";
import { API } from "aws-amplify";
import { listScreenReport, listRatioGalleries } from "../../graphql/queries";
import '../../styles/pages/query_result.scss';
// Import data table components
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { ThreeDots } from  'react-loader-spinner';

// Import CSV export component
import ExportCSV from './DownloadCsv';

function QueryResultsListView() {
  const navigate = useNavigate();

  const [screenReport, setScreenReport] = React.useState([]);
  const [errorMessage, setErrorMessage] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  
  const [source, setSource] = React.useState((queryParams.get("source") ? queryParams.get("source") :'')
    );
  const [query, setQuery] = React.useState((queryParams.get("query") ? queryParams.get("query") :'')
    );
  const [screen, setScreen] = React.useState(false);
  const [allOption, setRatioOption] = React.useState([]);
  const [ratioGalleryList, setRatioGalleryList] = React.useState(false);
  const [runThisQuery, setRunThisQuery] = React.useState(false);

  const [columns, setColumns] = React.useState([]);
  const [pageLoading, setPageLoading] = React.useState(true);
  const [tableData, setTableData] = React.useState([]);
  const [loadOnce, setLoadOnce] = React.useState(false);
  const [tableDataJson, setTableJson] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [rowPrePage, setRowPrePage] = React.useState(25);

  const [industries, setIndustries] = React.useState([]);
    const [industry_filter_open, setIndustryFilterOpen] = React.useState(false);
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);
    const [isFilter, setIsFilter] = useState(false);
    const [industry_selected, setIndustrySelected] = useState([]);
    
    const industryFilterMenu = document.querySelector("#industry-filter-menu");
    document.addEventListener("mousedown", (e) => {
      /**
       * If the user clicks outside of the profile menu, close the profile menu
       */
      if(industryFilterMenu){
        if (!industryFilterMenu.contains(e.target)) {
          setIndustryFilterOpen(false);
        }
      }
 
    });

  /**
   * @function useEffect - Call Required functions on state change
   * @returns {void}
   */

  useEffect(() => {
    if( ! loadOnce ){
      fetchScreenReport();
      fetchRatioGalleries();
      setLoadOnce(true);
    }
    if(tableDataJson){
      setReportTable();
    }else{
      if(runThisQuery){
        // setPage(1);
        fetchScreenReport();
      }
      if(isFilter){
        setPageLoading(true);
        setIsFilter(false);
        fetchScreenReport();
      }
    }

  }, [ query,runThisQuery, tableDataJson,isFilter,industry_selected]);

  let setReportTable = () => {
    let table_header_columns = getTableHeader(tableDataJson);
    setTableData({
       columns: table_header_columns,
       data: tableDataJson
    });
    setPageLoading(false);
    setTableJson(false);

    // Load more data after randaring the first page
    fetchScreenReport();
  }

  /**
   * @function fetchScreenReport - Fetch Screen Report from API
   * @returns {void}
   */
  async function fetchScreenReport() {
    setErrorMessage('');
    setRunThisQuery(false);
    var limit = rowPrePage;
    if(page === 1){
      setPageLoading(true);
      setTableData({
        columns: columns,
        data: [],
      });
      setPage('All');
    }else{
      limit = 1000;
      setPage(1);
    }
    
    try {
      let comma_sep_industry = '';
      if(industry_selected && industry_selected !== undefined){
        comma_sep_industry = industry_selected.map(item => item).join(',');
      }
      const result = await API.graphql({
          query: listScreenReport,
          variables: { search_query: query , screen_id:"", industry:comma_sep_industry, limit:limit, nextToken:"" },
          authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      var tblData = JSON.parse(result.data.listScreenReport.table);
      if (tblData.length) {
        if(page === 1){
          setRatioOption(result.data.listScreenReport.ratios);
          setIndustries(result.data.listScreenReport.industry_rows);
          setTableJson(JSON.parse(result.data.listScreenReport.table));
        }else{
          setTableData({
            data: JSON.parse(result.data.listScreenReport.table)
          });
        }
      }else{
        setTableData({
            data: JSON.parse(result.data.listScreenReport.table)
        });
        
        setPageLoading(false);
      }
    } catch (e) {
      setErrorMessage(`Invalid Search Query : [${query}]`);
      setPageLoading(false);
    }
  }

  /**
   * @function fetchRatioGalleries - Fetch Ratio Galleries from API
   * @returns {void}
   */
  async function fetchRatioGalleries() {
    const result = await API.graphql({
      query: listRatioGalleries,
      variables: {
        limit: 20,
        nextToken: "",
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    if (result.data.listRatioGalleries) {
      setRatioGalleryList(result.data.listRatioGalleries.items);
      setRatioOption(result.data.listRatioGalleries.items.all_ratios);
    }
  }
  let getTableHeader = (table_data) => {
    if(table_data.length > 0){
        let fisrt_row = [];
        let columns = [];
        fisrt_row = Object.keys(table_data[0]);
        fisrt_row.forEach(( key,  idx) => {
            if(idx === 0){
              columns.push({
                name: handleInfoHelpText(key),
                selector: row => row[key],
                cell: (row, index, column, id) => {
                  return row[key];
                },
                sortable: true,
                width:'150px',
              });
            }else{
              columns.push({
                name: handleInfoHelpText(key),
                selector: row => row[key],
                sortable: true,
                right: (idx !== 0) ? true: false,
                cell: (row, index, column, id) => {
                  if(row[key] !== null){
                    return (isNaN(row[key]) ? row[key] : row[key].toFixed(2));
                  }else{
                    return '0.00';
                  }
                }
              });
            }
         }
        );
        setColumns(columns);
        return columns;
    }
  };

  /**
   * @function handleInfoHelpText - Handle Info Help Text on table header column
   * @returns {Html Element}
   */ 
  let handleInfoHelpText = (screen_name) => {
    let matches = allOption.filter(
      (v) => v.screen_name.toLowerCase() === screen_name.toLocaleLowerCase()
    );
    if (typeof matches !== "undefined" && matches != null && matches.length) {
      var colOptionValue = matches[0];
      if (colOptionValue.unit !== "NA") {
        return (
          <span title={colOptionValue.name} className="tx-primary">
            {" "}
            {colOptionValue.screen_name}{" "}
            <span className="btn-white">{colOptionValue.unit}</span>{" "}
          </span>
        );
      } else {
        return (
          <span className="tx-primary" title={colOptionValue.name}>
            {" "}
            {screen_name}
            <span className="btn-white"></span>{" "}
          </span>
        );
      }
    } else {
      return (
        <span className="tx-primary" title={screen_name}>
          {" "}
          {screen_name}
          <span className="btn-white"></span>{" "}
        </span>
      );
    }
  };

  /**
   * @function handleSearchQuerySave - Submit the search query to the Save Query screen
   * @returns {void}
   */
  const handleSearchQuerySave = (e) => {
    e.preventDefault();
    navigate(`/screen/save/?source=${source}&query=${query}`);
  };

  const handleIndustryFilter = (e) => {
    if(! industry_filter_open ){
      setIndustryFilterOpen(true);
    }else{
      setIndustryFilterOpen(false);
    }
  }

  const handleSelectIndustry = (e) => {
    var target = e.target;
    if(target.checked === true){
      setIndustrySelected([...industry_selected, target.value ] )
    }else{
      var selected = industry_selected;
      var unSelected = selected.filter(function(indust) { 
          return indust !== target.value 
      });
      setIndustrySelected(unSelected);
    }
    setIsFilter(true); 
   };

  /**
   * @function return - Component return
   * @returns {html Element}
   */
  return (
    <div className="row">
      <div className="card card-body pd-20 __query_result_page">
        <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30 __page_head_bar">
          <div className="title">
            <h2 className="mg-b-0 tx-spacing--1">Query Results</h2>
          </div>
          <div className="save_query">
            <form method="get" onSubmit={handleSearchQuerySave} action="/screen/save/">
              <input type="hidden" name="source" id="id_source" value={source} />
              <input type="hidden" name="query" id="id_query" value={query} />
              <button className="btn btn-primary pd-x-20 btn-uppercase">
                {" "}
                Save This Query
              </button>
            </form>
          </div>
        </div>
        <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
          <div style={{ width: "60%" }}>
          {errorMessage && (
              <Alert
                variation="error"
                isDismissible={false}
                hasIcon={true}
                heading="Error"
              >
                {errorMessage}
              </Alert>
            )}
          </div>
          <div className="d-none d-md-block" style={{ position: "relative" }}>
            <button className="btn btn-white pd-x-20 btn-uppercase mg-x-10" onClick={handleIndustryFilter}>
              {" "}
              Industry
            </button>
            <ul  style={{ minWidth: "225px" }} className={(industry_filter_open) ? 'dropdown-content font-size-14 visible' : 'dropdown-content font-size-14' } id="industry-filter-menu">
              {/*<li data-idx="all">
                <label className="label-body">
                <input type="checkbox" name="selectAll" id="selectAll" onClick={handleSelectAll} /> <span> Check All </span>
                </label>
              </li> */}
              {industries.length > 0 ? (
                industries.map((col, index) => {
                  return (
                    <li key={index} className={index}>
                      <label className="label-body">
                        <input  onClick={handleSelectIndustry}  key={'ind_checkbox_'+ index} type="checkbox" value={col.Industry} /> 
                        <span> {col.Industry} - {col.company_count} </span>
                      </label>
                    </li>
                  );
                })
                ) : (
                <div></div>
              )}
            </ul>
            <ExportCSV {...tableData} />
            <ScreensLink
              to="/screen/columns"
              className="btn btn-outline-primary pd-x-20 btn-uppercase mg-x-10"
            >
              Edit Columns
            </ScreensLink>
          </div>
        </div>
        {screenReport && (    
        <div className="table-data-wrap">
          <DataTableExtensions columns={columns} filter={false} export={false} print={false} {...tableData}>
            <DataTable
              columns={columns}
              // data={dematReport}
              noHeader
              defaultSortField="MCap"
              defaultSortAsc={false}
              pagination 
              highlightOnHover
              persistTableHead
              paginationPerPage="25"
              noDataComponent={" No Data found in this search criteria. "}
              progressPending ={pageLoading}
              progressComponent ={<ThreeDots color="#e54f22" height={40} width={40} />}
            />
          </DataTableExtensions>
        </div>  
        )}
        {ratioGalleryList && <SearchQueryScreen {...{screen:screen,ratioGalleryList:ratioGalleryList, query:query, setPreQuery:setQuery,setRunThisQuery:setRunThisQuery }}/>}
      </div>
    </div>
  );
}
export default QueryResultsListView;
