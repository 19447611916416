import React, { useEffect } from "react";
import { Link as ScreensLink, useParams } from "react-router-dom";
import { API } from "aws-amplify";
import { Alert } from "@aws-amplify/ui-react";
import {
  getWatchlistById,
  getWatchlistCompaniesReport,
  listRatioGalleries,
} from "../../graphql/queries";

// Import data table components
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { ThreeDots } from  'react-loader-spinner';

// Import CSV export component
import ExportCSV from '../screen/DownloadCsv';

function WatchlistView() {
  const [watchlist, setWatchlist] = React.useState([]);
  const [watchlistCompanies, setWatchlistCompanies] = React.useState([]);
  const { wl_id } = useParams();
  const [allOption, setRatioOption] = React.useState([]);
  const [columns, setColumns] = React.useState([]);
  const [pageLoading, setPageLoading] = React.useState(true);
  const [tableData, setTableData] = React.useState([]);
  const [tableDataJson, setTableJson] = React.useState(false);
  const { messgage } = useParams();
  const [industries, setIndustries] = React.useState([]);
  const [industry_filter_open, setIndustryFilterOpen] = React.useState(false);
  const [isFilter, setIsFilter] = React.useState(false);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [industry_selected, setIndustrySelected] = React.useState([]);
  
  const industryFilterMenu = document.querySelector("#industry-filter-menu");
  document.addEventListener("mousedown", (e) => {
    /**
     * If the user clicks outside of the profile menu, close the profile menu
     */
    if(industryFilterMenu){
      if (!industryFilterMenu.contains(e.target)) {
        setIndustryFilterOpen(false);
      }
    }

  });

  useEffect(() => {
    if(tableDataJson){
      setReportTable();
    }else{
      if(!isLoaded){
        fetchWatchlistCompanies();
        fetchWatchlist();
      }
    }
    if(isFilter){
      setPageLoading(true);
      fetchWatchlistCompanies();
    }
 
  }, [tableDataJson, isFilter,industry_selected]);

  async function fetchWatchlist() {
    const result = await API.graphql({
      query: getWatchlistById,
      variables: { wl_id },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    if (result.data.getWatchlistById) {
      setWatchlist(result.data.getWatchlistById);
    }
  }
  async function fetchWatchlistCompanies() {
    setPageLoading(true);
    setIsLoaded(true);
    try {
      let comma_sep_industry = '';
      if(industry_selected && industry_selected !== undefined){
        comma_sep_industry = industry_selected.map(item => item).join(',');
      }
      const result = await API.graphql({
        query: getWatchlistCompaniesReport,
        variables: { wl_id, industry:comma_sep_industry, limit: 50, nextToken: "" },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      var tblData = JSON.parse(result.data.getWatchlistCompaniesReport.table);
      if (tblData.length) {
        if(isFilter){
          setTableData({
            data: JSON.parse(result.data.getWatchlistCompaniesReport.table)
          });
          setIsFilter(false);
          setPageLoading(false);
        }else{
          setRatioOption(result.data.getWatchlistCompaniesReport.ratios);
          setIndustries(result.data.getWatchlistCompaniesReport.industry_rows);
          setTableJson(JSON.parse(result.data.getWatchlistCompaniesReport.table));
        }
      }else{
        setTableData({
            data: JSON.parse(result.data.getWatchlistCompaniesReport.table)
        });
        setIsFilter(false);
        setPageLoading(false);
      }
    } catch (e) {
      // setErrorMessage(`Invalid Search Query : [${query}]`);
      setPageLoading(false);
    }
  }

  /**
   * 
   */
  let setReportTable = () => {
    let table_header_columns = getTableHeader(tableDataJson);
    setTableData({
       columns: table_header_columns,
       data: tableDataJson
    });
    setPageLoading(false);
    setTableJson(false);
  }
  

  async function fetchRatioGalleries() {
    const result = await API.graphql({
      query: listRatioGalleries,
      variables: {
        limit: 20,
        nextToken: "",
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    if (result.data.listRatioGalleries) {
      setRatioOption(result.data.listRatioGalleries.items.all_ratios);
    }
  }
  /**
   * 
   * @param {*} table_data 
   * @returns 
   */
   let getTableHeader = (table_data) => {
    if(table_data.length > 0){
        let fisrt_row = [];
        let columns = [];
        fisrt_row = Object.keys(table_data[0]);
        fisrt_row.forEach(( key,  idx) => {
            if(idx === 0){
              columns.push({
                name: handleInfoHelpText(key),
                selector: row => row[key],
                cell: (row, index, column, id) => {
                  return row[key];
                },
                sortable: true,
                width:'150px',
              });
            }else{
              columns.push({
                name: handleInfoHelpText(key),
                selector: row => row[key],
                sortable: true,
                right: (idx !== 0) ? true: false,
                cell: (row, index, column, id) => {
                  if(row[key] !== null){
                    return (isNaN(row[key]) ? row[key] : row[key].toFixed(2));
                  }else{
                    return '0.00';
                  }
                }
              });
            }
         }
        );
        setColumns(columns);
        return columns;
    }
  };

  /**
   * 
   * @param {*} screen_name - screen name 
   * @returns 
   */
  let handleInfoHelpText = (screen_name) => {
    let matches = allOption.filter(
      (v) => v.screen_name.toLowerCase() === screen_name.toLocaleLowerCase()
    );
    if (typeof matches !== "undefined" && matches != null && matches.length) {
      var colOptionValue = matches[0];
      if (colOptionValue.unit !== "NA") {
        return (
          <span title={colOptionValue.name} className="tx-primary">
            {" "}
            {colOptionValue.screen_name}{" "}
            <span className="btn-white">{colOptionValue.unit}</span>{" "}
          </span>
        );
      } else {
        return (
          <span className="tx-primary" title={colOptionValue.name}>
            {" "}
            {screen_name}
            <span className="btn-white"></span>{" "}
          </span>
        );
      }
    } else {
      return (
        <span className="tx-primary" title={screen_name}>
          {" "}
          {screen_name}
          <span className="btn-white"></span>{" "}
        </span>
      );
    }
  };

  const handleIndustryFilter = (e) => {
    if(! industry_filter_open ){
      setIndustryFilterOpen(true);
    }else{
      setIndustryFilterOpen(false);
    }
  }

  const handleSelectIndustry = (e) => {
    var target = e.target;
    if(target.checked === true){
      setIndustrySelected([...industry_selected, target.value ] )
    }else{
      var selected = industry_selected;
      var unSelected = selected.filter(function(indust) { 
          return indust !== target.value 
      });
      setIndustrySelected(unSelected);
    }
    setIsFilter(true); 
   };
  
   /**
    * @function return - Component return
    * @returns {html Element}
    */
   
  return (
    <div className="row">
      <div className="card card-body pd-20">
          {messgage && (
            <Alert
              variation="success"
              heading="Success"
              isDismissible="true"
              marginBottom="2rem"
            >
              {messgage}
            </Alert>
          )}
        <div className="d-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30 __page_head_bar">
          <div className="title">
            <h2 className="mg-b-0 tx-spacing--1">
              {watchlist.name}
              <ScreensLink
                to={"/watchlist/edit/" + wl_id}
                className="btn-uppercase mg-x-10"
              >
                Edit
              </ScreensLink>
            </h2>
          </div>
          
          <div className="manage_screen_btn">
            <ScreensLink
              to={"/watchlist/manage/" + wl_id}
              className="btn btn-outline-primary pd-x-20 btn-uppercase mg-x-10"
            >
              Manage Companies
            </ScreensLink>
          </div>
        </div>
        <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30"  style={{ position: "relative" }}>
          <div className="table_body_content">
            <p className="mg-b-0 tx-spacing--1">{watchlist.description}</p>
          </div>
          <div className="button_wrap">
            <button className="btn btn-white pd-x-20 btn-uppercase mg-x-10 mb-2 filter_icon_added" onClick={handleIndustryFilter}>
              {" "}
              Industry
            </button>
            <ul  style={{ minWidth: "225px" }} className={(industry_filter_open) ? 'dropdown-content font-size-14 visible' : 'dropdown-content font-size-14' } id="industry-filter-menu">
              {industries.length > 0 ? (
                industries.map((col, index) => {
                  return (
                    <li key={index} className={index}>
                      <label className="label-body">
                        <input  onClick={handleSelectIndustry}  key={'ind_checkbox_'+ index} type="checkbox" value={col.Industry} /> 
                        <span> {col.Industry} - {col.company_count} </span>
                      </label>
                    </li>
                  );
                })
                ) : (
                <div></div>
              )}
            </ul>
            <ExportCSV {...tableData} />
            <ScreensLink
              to={"/screen/columns/" + wl_id}
              className="btn btn-outline-primary pd-x-20 btn-uppercase mg-x-10 mb-2 edit-icon"
            >
              Edit Columns
            </ScreensLink>
          </div>
        </div>
        <div className="table-data-wrap">
          <DataTableExtensions  export={false} columns={columns} filter={false} print={false} {...tableData}>
          <DataTable
              columns={columns}
              // data={dematReport}
              noHeader
              defaultSortField="MCap"
              defaultSortAsc={false}
              pagination 
              highlightOnHover
              persistTableHead
              paginationPerPage="25"
              noDataComponent={" No Data found. "}
              progressPending ={pageLoading}
              progressComponent ={<ThreeDots color="#e54f22" height={40} width={40} />}
            />
          </DataTableExtensions>
        </div> 
      </div>
    </div>
  );
}
export default WatchlistView;
