const rightSection1 = [
    {
        name : "Power Stocks to Buy",
        url : "https://top10stockbroker.com/best-power-stocks-to-buy/",
    },
    {
        name : "IT Stocks to Buy",
        url : "https://top10stockbroker.com/best-it-stocks-to-buy/",
    },
    {
        name : "Real Estate Stocks to Buy",
        url : "https://top10stockbroker.com/real-estate-stocks-to-buy/",
    },
    {
        name : "Chemical Stocks to Buy",
        url : "https://top10stockbroker.com/chemical-stocks-to-buy/",
    },
    {
        name : "Banking Stocks to Buy",
        url : "https://top10stockbroker.com/best-bank-stocks-to-buy/",
    },
    {
        name : "FMCG Stocks to Buy",
        url : "https://top10stockbroker.com/best-fmcg-stocks-to-buy/",
    },
    {
        name : "Pharma Stocks to Buy",
        url : "https://top10stockbroker.com/best-pharma-stocks-to-buy/",
    },
    {
        name : "Defence Stocks to Buy",
        url : "https://top10stockbroker.com/stocks-to-buy/best-defence-stocks-to-buy/",
    },
    {
        name : "EV Stocks to Buy",
        url : "https://top10stockbroker.com/stocks-to-buy/best-ev-stocks-to-buy/",
    },
    {
        name : "Auto Stocks to Buy",
        url : "https://top10stockbroker.com/best-auto-stocks-to-buy/",
    }
];
  
export default rightSection1;
 
const rightSection2 = [
    {
        name : "Multibagger Stocks to Buy",
        url : "https://top10stockbroker.com/stocks-to-buy/best-multibagger-stocks-to-buy/",
    },
    {
        name : "Bluechip Stocks to Buy",
        url : "https://top10stockbroker.com/stocks-to-buy/best-blue-chip-stocks-to-buy/",
    },
    {
        name : "Dividend Paying Stocks to Buy",
        url : "https://top10stockbroker.com/stocks-to-buy/best-dividend-paying-stocks-to-buy/",
    },
    {
        name : "High Priced Stocks to Buy",
        url : "https://top10stockbroker.com/stocks-to-buy/best-high-priced-stocks-to-buy/",
    },
    {
        name : "Nifty 50 Stocks to Buy",
        url : "https://top10stockbroker.com/stocks-to-buy/best-nifty-stocks-to-buy/",
    },
    {
        name : "Penny Stocks to Buy",
        url : "https://top10stockbroker.com/stocks-to-buy/best-penny-stocks-to-buy/",
    },
    {
        name : "Long Term Stocks to Buy",
        url : "https://top10stockbroker.com/stocks-to-buy/best-long-term-stocks-to-buy/",
    },
    {
        name : "Intraday Stocks to Buy",
        url : "https://top10stockbroker.com/share-market-tips/best-intraday-stocks/",
    }
];
 
const rightSection3 = [
    {
        name : "Nifty 50",
        url : "https://top10stockbroker.com/share-market/nifty-50/"
    },
    {
        name : "Sensex",
        url : "https://top10stockbroker.com/share-market/sensex/"
    },
    {
        name : "Top Gainers",
        url : "https://top10stockbroker.com/share-market/nse-top-gainers/"
    },
    {
        name : "Top Losers",
        url : "https://top10stockbroker.com/share-market/nse-top-losers/"
    },
    {
        name : "52 Week Low",
        url : "https://top10stockbroker.com/share-market/52-week-low/"
    },
    {
        name : "Gold Rate",
        url : "https://top10stockbroker.com/gold-rate/"
    },
    {
        name : "Silver Rate",
        url : "https://top10stockbroker.com/silver-rate/"
    },
    {
        name : "Nifty 50 Option Chain",
        url : "https://top10stockbroker.com/option-chain/nifty-option-chain/"
    },
    {
        name : "Bank Nifty Option Chain",
        url : "https://top10stockbroker.com/option-chain/bank-nifty-option-chain/"
    },
    {
        name : "Nifty Futures",
        url : "https://top10stockbroker.com/futures/nifty-futures/"
    }
];
const rightSection4 = [
    {
        name : "Nifty 50 Prediction",
        url : "https://top10stockbroker.com/index-prediction/nifty-prediction/"
    },
    {
        name : "Bank Nifty Prediction",
        url : "https://top10stockbroker.com/index-prediction/nifty-bank-prediction/"
    },
    {
        name : "Sensex Prediction",
        url : "https://top10stockbroker.com/index-prediction/sensex-prediction/"
    },
    {
        name : "Gold Rate Prediction",
        url : "https://top10stockbroker.com/gold-rate/gold-rate-forecast/"
    },
    {
        name : "Silver Rate Prediction",
        url : "https://top10stockbroker.com/silver-rate/silver-rate-forecast/"
    },
    {
        name : "HDFC Bank Share Price Prediction",
        url : "https://top10stockbroker.com/stock-prediction/hdfc-bank-share-price-prediction/"
    },
    {
        name : "Reliance Share Price Prediction",
        url : "https://top10stockbroker.com/stock-prediction/reliance-industries-share-price-prediction/"
    },
    {
        name : "TCS Share Price Prediction",
        url : "https://top10stockbroker.com/stock-prediction/tcs-share-price-prediction/"
    },
    {
        name : "HUL Share Price Prediction",
        url : "https://top10stockbroker.com/stock-prediction/hul-share-price-prediction/"
    },
    {
        name : "Airtel Share Price Prediction",
        url : "https://top10stockbroker.com/stock-prediction/bharti-airtel-share-price-prediction/"
    }
];

const rightSection5 = [
    {
        name : "Share Market Courses",
        url : "https://top10stockbroker.com/share-market-education/"
    },
    {
        name : "Upcoming IPOs",
        url : "https://top10stockbroker.com/upcoming-ipo/"
    },
    {
        name : "Best Demat Account",
        url : "https://top10stockbroker.com/best-demat-account/"
    },
    {
        name : "Best Trading App",
        url : "https://top10stockbroker.com/best-trading-app-in-india/"
    },
    {
        name : "Top 10 Stock Brokers",
        url : "https://top10stockbroker.com/"
    },
    {
        name : "Best Sub Broker Franchise",
        url : "https://top10stockbroker.com/top-10-stock-market-investors/"
    },
    {
        name : "Best Forex Broker",
        url : "https://top10stockbroker.com/forex-trading/best-forex-broker/"
    },
    {
        name : "Top Forex App",
        url : "https://top10stockbroker.com/forex-trading/best-forex-app/"
    },
    {
        name : "Best Mutual Fund App",
        url : "https://top10stockbroker.com/mutual-fund/best-mutual-fund-app/"
    },
    {
        name : "Top Investors in India",
        url : "https://top10stockbroker.com/top-10-sub-broker-partner-india/"
    },
];
  
export {rightSection2, rightSection3, rightSection4, rightSection5};