import { Heading, Card, Text } from "@aws-amplify/ui-react";
function ProfilePage({ user }) {
  return (
    <Card backgroundColor="#0168fa" color={"white"} title="Welcome">
      <Heading level={3} color="white" fontWeight="bold">
        Welcome {user.attributes.given_name}
      </Heading>
      <Text
        fontWeight="bold"
        color="white"
        display="inline-block"
        paddingRight="12px"
      >
        Name#
      </Text>
      <Text fontWeight="normal" color="white" display="inline-block">
        {`${user.attributes.given_name} ${user.attributes.family_name}`}
      </Text>

      <br />
      <Text
        fontWeight="bold"
        color="white"
        display="inline-block"
        paddingRight="12px"
      >
        Phone#
      </Text>
      <Text fontWeight="normal" color="white" display="inline-block">
        {user.username}
      </Text>

      <br />
      <Text
        fontWeight="bold"
        color="white"
        display="inline-block"
        paddingRight="12px"
      >
        Email#
      </Text>
      <Text fontWeight="normal" color="white" display="inline-block">
        {user.attributes.email}
      </Text>

      <br />
      <Text
        fontWeight="bold"
        color="white"
        display="inline-block"
        paddingRight="12px"
      >
        City#
      </Text>
      <Text fontWeight="normal" color="white" display="inline-block">
        {user.attributes.address}
      </Text>
    </Card>
  );
}
export default ProfilePage;
