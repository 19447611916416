import React, { useEffect } from "react";
import { Link as ScreensLink, useParams } from "react-router-dom";
import { Alert } from "@aws-amplify/ui-react";
// React Data table Import
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { ThreeDots } from  'react-loader-spinner';
import { API } from "aws-amplify";
import {listOpenAccountRequest} from "../../graphql/queries";
// Import CSV export component
import DownloadDematReportCsv from './DownloadDematReportCsv';
 
function DematReport() {
  const [dematReport, setDematReport] = React.useState([]);
  const [tableData, setTableData] = React.useState([]);
  const [pageLoading, setPageLoading] = React.useState(true);

  const { messgage } = useParams();

  useEffect(() => {
    fetchOpenDematAccountRequest();
  }, []);

  /**
   * Define the columns for the data table
   */ 
  const columns = [
    {
      name: 'Request ID',
      selector: row => row.request_id,
      sortable: true,
    },
    {
      name: 'Name',
      selector: row => row.name,
      sortable: true,
    },
    {
      name: 'Mobile No',
      selector: row => row.mobile_number,
      sortable: true,
    },
    {
      name: 'City',
      selector: row => row.city,
      sortable: true,
    },
    {
      name: 'Service',
      selector: row => row.service_name,
      sortable: true,
    },
    {
      name: 'Created At',
      selector: row => row.createdAt,
      sortable: true,
    },
  ];

  /**
   * @function fetchOpenDematAccountRequest - Fetch Open Demat Account Request from API
   * @returns {void}
   * @memberof DematReport
   * @param {void}
   */
  async function fetchOpenDematAccountRequest() {
    const result = await API.graphql({
      query: listOpenAccountRequest,
      variables: { limit: 100, nextToken: "" },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    if (result.data.listOpenAccountRequest.items) {
      setTableData({columns:columns , data:result.data.listOpenAccountRequest.items});
      setDematReport(
        result.data.listOpenAccountRequest.items
      );
      setPageLoading(false);
    }
  }
  
  /**
   * Component Render Method
   * @returns {JSX.Element}
   * @memberof DematReport
   */
  return (
    <div className="card card-large pd-20">
        {messgage && (
          <Alert
            variation="success"
            heading="Success"
            isDismissible="true"
            marginBottom="2rem"
          >
            {messgage}
          </Alert>
        )}
      <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
        <div>
          <h4 className="mg-b-0 tx-spacing--1">
            Demat Account Requests
          </h4>
        </div>
      </div>
      <div className="main">
        <div className="text-right">
          <DownloadDematReportCsv {...tableData} />
        </div>
        <DataTableExtensions export={false}  print={false} {...tableData}>
          <DataTable
            columns={columns}
            data={dematReport}
            noHeader
            defaultSortField="id"
            defaultSortAsc={true}
            pagination
            highlightOnHover
            persistTableHead
            paginationPerPage="25"
            noDataComponent={" No data found. "}
            progressPending ={pageLoading}
            progressComponent ={<ThreeDots color="#e54f22" height={40} width={40} />}
          />
        </DataTableExtensions>
      </div>
    </div>
  );
}

export default DematReport;