import React, { useEffect } from "react";
import { Link as ScreensLink, useParams } from "react-router-dom";
import { Alert } from "@aws-amplify/ui-react";
// Export the react datatable components
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { ThreeDots } from  'react-loader-spinner';
// Import CSV export component
import DownloadUserReportCsv from './DownloadUserReportCsv';

import { API } from "aws-amplify";
import {listUsers} from "../../graphql/queries";
 
function UserReport() {
  const [tableData, setTableData] = React.useState([]);
  const [excelTableData, setExcelTableData] = React.useState([]);
  const [pageLoading, setPageLoading] = React.useState(true);
  
  const { messgage } = useParams();

  /**
   * useEffect - Fetch Open Demat Account Request from API
   * @param {}
   * @returns {}
   * @memberof UserReport
   */ 
  useEffect(() => {
    fetchListUsers();
  }, []);

  /**
   * Define the columns for the data table
   */
  const columns = [
    // {
    //   name: 'Username',
    //   selector: row => row.Username,
    //   sortable: true,
    // },
    {
        name: 'First Name',
        selector: row => row.Attributes,
        sortable: true,
        cell: d => <span>{d.Attributes.map((a_row) => (a_row.Name =='given_name')?a_row.Value:'')}</span>,
    },
    {
        name: 'Last Name',
        selector: row => row.Attributes,
        sortable: true,
        cell: d => <span>{d.Attributes.map((a_row) => (a_row.Name =='family_name')?a_row.Value:'')}</span>,
    },
    {
      name: 'Mobile No',
      selector: row => row.Attributes,
      sortable: true,
      cell: d => <span>{d.Attributes.map((a_row) => (a_row.Name =='phone_number')?a_row.Value:'')}</span>,
    },
    
    {
        name: 'Email',
        // selector: row => row.Attributes,
        sortable: true,
        cell: d => <span>{d.Attributes.map((a_row) => (a_row.Name =='email')?a_row.Value:'')}</span>,
    },
    {
        name: 'Address',
        selector: row => row.Attributes,
        sortable: true,
        cell: d => <span>{d.Attributes.map((a_row) => (a_row.Name =='address')?a_row.Value:'')}</span>,
      },
    {
      name: 'Enabled',
      selector: row => row.Enabled,
      sortable: true,
      cell: d => <span>{(d.Enabled) ? 'Enabled': 'Disabled'}</span>,
    },
    // {
    //   name: 'Status',
    //   selector: row => row.UserStatus,
    //   sortable: true,
    // },
    {
      name: 'Create Date',
      selector: row => row.UserCreateDate,
      sortable: true,
    },
    // {
    //   name: 'Last Modified Date',
    //   selector: row => row.UserLastModifiedDate,
    //   sortable: true,
    // },
  ];
  
  /**
   * @function fetchListUsers - Fetch List Users from API
   * @returns {void}
   * @memberof UserReport
   * @param {void}
   */
  async function fetchListUsers() {
    const result = await API.graphql({
      query: listUsers,
      variables: { limit: 50},
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    if (result.data.listUsers.users) {
        setTableData({
            columns: columns,
            data: result.data.listUsers.users || [],
            }
        );
        const listItems = result.data.listUsers.users.map((user) => {
          var attr_row = new Object();
          attr_row['Username'] = user.Username;
          user.Attributes.forEach(row=>{
             switch(row.Name){
               case 'given_name' :
                 attr_row['First Name'] = row.Value;
                 break;
               case 'family_name' :
                 attr_row['Last Name'] = row.Value;
                 break;
               case 'phone_number' :
                 attr_row['Mobile No'] = row.Value;
                 break;
               case 'email' :
                 attr_row['Email'] = row.Value;
                 break;
               case 'address' :
                 attr_row['Address'] = row.Value;
                 break;
             }
           })
           attr_row['Enabled'] = user.Enabled;
           attr_row['Created Date'] = user.UserCreateDate;
           return attr_row;
        }
        );
       setExcelTableData({
         // columns: ['Address','Mobile No'],
         data: listItems || [],
       }
     );
        setPageLoading(false);
    }else{
        setPageLoading(false);
    }
  }
  
  /**
   * @function render - Render the component
   * @returns {void}
   * @memberof UserReport
   * @param {void}
   */ 
  return (
    <div className="card card-large pd-20">
        {messgage && (
          <Alert
            variation="success"
            heading="Success"
            isDismissible="true"
            marginBottom="2rem"
          >
            {messgage}
          </Alert>
        )}
      <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
        <div>
          <h4 className="mg-b-0 tx-spacing--1">
            Registered Users List
          </h4>
        </div>
      </div>
      <div className="main">
        <div className="text-right">
          <DownloadUserReportCsv {...excelTableData} />
        </div>
        <DataTableExtensions export={false} filter={false} print={false} {...tableData}>
          <DataTable
            columns={columns}
            noHeader
            defaultSortField="id"
            defaultSortAsc={true}
            pagination
            highlightOnHover
            persistTableHead
            paginationPerPage="25"
            noDataComponent={" No data found. "}
            progressPending ={pageLoading}
            progressComponent ={<ThreeDots color="#e54f22" height={40} width={40} />}
          />
        </DataTableExtensions>
      </div>
    </div>
  );
}

export default UserReport;