import React, { useState } from "react";
// import { View,Link,Divider,Menu,MenuItem,Heading,Card,Flex,Button } from '@aws-amplify/ui-react';
import { Link as ScreensLink, useNavigate } from "react-router-dom";

import { API } from "aws-amplify";
import { Alert } from "@aws-amplify/ui-react";
import '../../styles/component/_add_watchlist.scss';

import { searchCompanyByName } from "../../graphql/queries";
import { createWatchlist, addWatchlistCompany } from "../../graphql/mutations";

function WatchlistAdd() {
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [nameError, setNameErrors] = useState(false);
  const [descriptionError, setDescriptionErrors] = useState(false);
  const [conpanyError, setConpanyErrors] = useState(false);
  const [watchlistCompanies, setWatchlistCompanies] = useState([]);
  const [searchCompanies, setSearchCompanies] = useState([]);
  const [errorMessage, setErrorMessage] = useState(false);
  

  const queryParams = new URLSearchParams(window.location.search);

  let handleSaveWatchlist = async (e) => {
    
    e.preventDefault();
    setErrorMessage(false);
    var er = 0;
    if (name === "") {
      er = 1;
      setNameErrors("Please enter watchlist name!");
    }
    if (description === "") {
      er = 2;
      setDescriptionErrors("Please enter watchlist description!");
    }
    if (!watchlistCompanies.length) {
      er = 3;
      setConpanyErrors("Please select watchlist companies!");
    }
    if (er) {
      return false;
    }
    let companies = "";

    watchlistCompanies.forEach((comRow) => {
      if( ! companies.includes(comRow.FINCODE)){
        companies += companies ? "," + comRow.FINCODE : comRow.FINCODE;
      }
      
    });

    try {
      const CreateWatchlistInput = {
        watchlist: {
          name: name,
          description: description,
          companies: companies,
        },
      };
      const result = await API.graphql({
        query: createWatchlist,
        variables: CreateWatchlistInput,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      navigate(`/watchlists/Watchlist has been saved successfully.`);
    } catch (err) {
      console.log(err);
      setErrorMessage("Error while saving watchlist! Please try again.");
    }

    return false;
  };
  let fetchCompanies = async (name) => {
    const result = await API.graphql({
      query: searchCompanyByName,
      variables: { search_text: name },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    if (result.data.searchCompanyByName.items.length) {
      setSearchCompanies(result.data.searchCompanyByName.items);
      setSearchCompanies((state) => {
        return state;
      });
      // console.log(result.data.searchCompanyByName.items);
    }
  };
  let handleSearchCompanies = async (event) => {
    event.preventDefault();
    var target = event.target;
    // console.log(target.value);
    var searchText = target.value;
    if (searchText.length < 3) {
      // document.getElementsByClassName('dropdown-content').removeClass('visible');
      return setSearchCompanies([]);
    } else {
      try {
        // document.getElementsByClassName('dropdown-content').addClass('dropdown-content', 'visible');
        fetchCompanies(searchText);
      } catch (err) {
        console.log(err);
      }
    }

    return false;
  };
  let handleSelectCompany = async (event) => {
    event.preventDefault();
    var target = event.target;
    var addNewCompany = {
      name: target.getAttribute("data-compname"),
      FINCODE: target.getAttribute("data-fincode"),
    };

    setWatchlistCompanies([...watchlistCompanies, addNewCompany]);
    setSearchCompanies([]);
    document.getElementById("watchlist-search").value = "";
    return false;
  };
  let handleRemoveCompany = (event) => {
    event.preventDefault();
    var target = event.target;
    var dataIdx = target.getAttribute("data-index");
    var fincode = target.getAttribute("data-fincode");
    if (fincode) {
      let updateCompanies = [];
      updateCompanies = watchlistCompanies.filter(
        (item) => item.FINCODE !== fincode
      );
      setWatchlistCompanies(updateCompanies);
    }
    return false;
  };

  return (
    <div className="row">
      <div className="card card-body mg-lg-x-200 add_watch_list">
        <div className="pd-lg-x-20">
          <form
            method="get"
            action="/screen/query-result/"
            onSubmit={handleSaveWatchlist}
          >
            <h3 className="tx-18 tx-lg-32">Create new watchlist</h3>
            {errorMessage && (
              <Alert variation="error" isDismissible="true" marginBottom="2rem">
                {errorMessage}
              </Alert>
            )}
            <div className="row">
              <div className="col-sm-12 form-field pd-y-10">
                <label htmlFor="name"> Name </label>
                <div className="flex-row flex-space-between flex-gap-16">
                  <input
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    type="text"
                    className="form-control"
                    name="name"
                    maxLength="50"
                    id="id_name"
                  />
                  {nameError ? (
                    <div
                      className="alert alert-danger alert-dismissible fade show mg-y-5"
                      role="alert"
                    >
                      {nameError}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="row tx-14">
              <div className="col-sm-12 form-field pd-y-10">
                <label htmlFor="description"> Description </label>
                <div className="flex-row flex-space-between flex-gap-16">
                  <textarea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className="form-control"
                    rows="3"
                    name="description"
                  ></textarea>
                  {descriptionError ? (
                    <div
                      className="alert alert-danger alert-dismissible fade show mg-y-5"
                      role="alert"
                    >
                      {descriptionError}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="row tx-14">
              <div className="col-sm-12 form-field pd-y-10">
                <label htmlFor="description"> Add companies to Watchlist</label>
                <div className="flex-row flex-space-between flex-gap-16">
                  <i className="addon icon-search"></i>
                  <div className="dropdown-typeahead">
                    <input
                      id="watchlist-search"
                      onKeyUp={handleSearchCompanies}
                      placeholder="eg. Infosys"
                      type="search"
                      className="u-full-width form-control"
                    />
                    <ul
                      className={
                        searchCompanies.length
                          ? "dropdown-content visible"
                          : "dropdown-content"
                      }
                      style={{
                        top: "77px",
                        left: "0px",
                        visibility: "visible",
                      }}
                    >
                      {searchCompanies.length > 0 ? (
                        searchCompanies.map((col, index) => {
                          return (
                            <li
                              onClick={handleSelectCompany}
                              key={"most_used_recent_" + index}
                              data-fincode={col.FINCODE}
                              data-compname={col.COMPNAME}
                            >
                              {col.COMPNAME}
                            </li>
                          );
                        })
                      ) : (
                        <li></li>
                      )}
                    </ul>
                    {conpanyError ? (
                      <div
                        className="alert alert-danger alert-dismissible fade show mg-y-5"
                        role="alert"
                      >
                        {conpanyError}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              {watchlistCompanies.length > 0 ? (
                watchlistCompanies.map((col, index) => {
                  return (
                    <div className="col-sm-4 mg-y-10" key={"wl_comp_" + index}>
                      <span className="shrink-text">{col.name}</span>
                      <button
                        type="button"
                        className="close mg-l-15 button-plain red float-right"
                        aria-label="Close"
                        
                      >
                        <span
                          aria-hidden="true"
                          onClick={handleRemoveCompany}
                          data-fincode={col.FINCODE}
                        >
                          &times;
                        </span>
                      </button>
                       
                    </div>
                  );
                })
              ) : (
                <div className="col-sm-4"></div>
              )}
            </div>

            <div className="flex-row flex-space-between flex-gap-20 mg-t-20">
              <div className="d-md-block">
                <button
                  className="btn btn-primary pd-x-15  btn-uppercase"
                  type="submit"
                >
                  <i className="fa far fa-save mg-x-3"></i>
                  Save watchlist
                </button>
                <div className="flex-row float-right">
                  <ScreensLink
                    to={"/watchlists"}
                    className="btn btn-primary pd-x-20  btn-uppercase"
                  >
                    ← Go Back
                  </ScreensLink>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
export default WatchlistAdd;
