 
const trendingStocks = [
    {
        name : "Reliance",
        url : "https://top10stockbroker.com/share-price/reliance-share-price/",
    },
    {
        name : "TCS",
        url : "https://top10stockbroker.com/share-price/tcs-share-price/",
    },
    {
        name : "HDFC Bank",
        url : "https://top10stockbroker.com/share-price/hdfc-bank-share-price/",
    },
    {
        name : "HUL",
        url : "https://top10stockbroker.com/share-price/hul-share-price/",
    },
    {
        name : "Infosys",
        url : "https://top10stockbroker.com/share-price/infosys-share-price/",
    },
    {
        name : "ICICI Bank",
        url : "https://top10stockbroker.com/share-price/icici-bank-share-price/",
    },
    // {
    //     name : "Bharti Airtel",
    //     url : "https://top10stockbroker.com/share-price/bharti-airtel-share-price/",
    // },
    // {
    //     name : "ITC",
    //     url : "https://top10stockbroker.com/share-price/itc-share-price/",
    // },
    // {
    //     name : "Asian Paints",
    //     url : "https://top10stockbroker.com/share-price/asian-paints-share-price/",
    // },
    // {
    //     name : "Maruti Suzuki",
    //     url : "https://top10stockbroker.com/share-price/maruti-suzuki-share-price/",
    // }
];
 
export default trendingStocks;

 
const topIndices = [
    {
        'logo': '../assets/img/logo/nifty-50.png',
        'name': 'Nifty',
        'live_name': 'Nifty Live',
        'live_link': 'https://top10stockbroker.com/share-market/nifty-50/',
        'prediction_name': 'Nifty Prediction',
        'prediction_link': 'https://top10stockbroker.com/index-prediction/nifty-prediction/',
    },
    {
        'logo': '../assets/img/logo/bank-nifty.png',
        'name': 'Bank Nifty',
        'live_name': 'Bank Nifty Live',
        'live_link': 'https://top10stockbroker.com/share-market/nifty-bank/',
        'prediction_name': 'Bank Nifty Prediction',
        'prediction_link': 'https://top10stockbroker.com/index-prediction/nifty-bank-prediction/',
    },
    {
        'logo': '../assets/img/logo/sensex.png',
        'name': 'Sensex',
        'live_name': 'Sensex Live',
        'live_link': 'https://top10stockbroker.com/share-market/sensex/',
        'prediction_name': 'Sensex Prediction',
        'prediction_link': 'https://top10stockbroker.com/index-prediction/sensex-prediction/',
    },
    {
        'logo': '../assets/img/logo/sensex-500.png',
        'name': 'Sensex 500',
        'live_name': 'Sensex 500 Live',
        'live_link': 'https://top10stockbroker.com/share-market/bse-500/',
        'prediction_name': 'Sensex 500 Prediction',
        'prediction_link': 'https://top10stockbroker.com/index-prediction/bse-500-prediction/',
    }
];

const top10Stocks =[
    {
        'logo': '../assets/img/logo/Reliance Industries Share Price.png',
        'name': 'Reliance',
        'live_name': 'Reliance Live',
        'live_link': 'https://top10stockbroker.com/share-price/reliance-share-price/',
        'prediction_name': 'Reliance Prediction',
        'prediction_link': 'https://top10stockbroker.com/stock-prediction/reliance-industries-share-price-prediction/',
    },
    {
        'logo': '../assets/img/logo/TCS Share Price.png',
        'name': 'TCS',
        'live_name': 'TCS Live',
        'live_link': 'https://top10stockbroker.com/share-price/tcs-share-price/',
        'prediction_name': 'TCS Prediction',
        'prediction_link': 'https://top10stockbroker.com/stock-prediction/tcs-share-price-prediction/',
    },
    {
        'logo': '../assets/img/logo/HDFC Bank Share Price.png',
        'name': 'HDFC Bank',
        'live_name': 'HDFC Bank Live',
        'live_link': 'https://top10stockbroker.com/share-price/hdfc-bank-share-price/',
        'prediction_name': 'HDFC Bank Prediction',
        'prediction_link': 'https://top10stockbroker.com/stock-prediction/hdfc-bank-share-price-prediction/',
    }, 
    {
        'logo': '../assets/img/logo/HUL Share Price.png',
        'name': 'HUL',
        'live_name': 'HUL Live',
        'live_link': 'https://top10stockbroker.com/share-price/hul-share-price/',
        'prediction_name': 'HUL Prediction',
        'prediction_link': 'https://top10stockbroker.com/stock-prediction/hul-share-price-prediction/',
    },
    {
        'logo': '../assets/img/logo/Infosys Share Price.png',
        'name': 'Infosys',
        'live_name': 'Infosys Live',
        'live_link': 'https://top10stockbroker.com/share-price/infosys-share-price/',
        'prediction_name': 'Infosys Prediction',
        'prediction_link': 'https://top10stockbroker.com/stock-prediction/infosys-share-price-prediction/',
    } ,
    {
        'logo': '../assets/img/logo/ICICI Bank Share Price.png',
        'name': 'ICICI Bank',
        'live_name': 'ICICI Bank Live',
        'live_link': 'https://top10stockbroker.com/share-price/icici-bank-share-price/',
        'prediction_name': 'ICICI Bank Prediction',
        'prediction_link': 'https://top10stockbroker.com/stock-prediction/icici-bank-share-price-prediction/',
    },
    {
        'logo': '../assets/img/logo/Bharti Airtel Share Price.png',
        'name': 'Bharti Airtel',
        'live_name': 'Bharti Airtel Live',
        'live_link': 'https://top10stockbroker.com/share-price/bharti-airtel-share-price/',
        'prediction_name': 'Bharti Airtel Prediction',
        'prediction_link': 'https://top10stockbroker.com/stock-prediction/bharti-airtel-share-price-prediction/',
    },
    {
        'logo': '../assets/img/logo/ITC Share Price.png',
        'name': 'ITC',
        'live_name': 'ITC Live',
        'live_link': 'https://top10stockbroker.com/share-price/itc-share-price/',
        'prediction_name': 'ITC Prediction',
        'prediction_link': 'https://top10stockbroker.com/stock-prediction/itc-share-price-prediction/',
    },
    {
        'logo': '../assets/img/logo/Asian Paints Share Price.png',
        'name': 'Asian Paints',
        'live_name': 'Asian Paints Live',
        'live_link': 'https://top10stockbroker.com/share-price/asian-paints-share-price/',
        'prediction_name': 'Asian Paints Prediction',
        'prediction_link': 'https://top10stockbroker.com/stock-prediction/asian-paints-share-price-prediction/',
    },
    {
        'logo': '../assets/img/logo/Maruti Suzuki Share Price.png',
        'name': 'Maruti Suzuki',
        'live_name': 'Maruti Suzuki Live',
        'live_link': 'https://top10stockbroker.com/share-price/maruti-suzuki-share-price/',
        'prediction_name': 'Maruti Suzuki Prediction',
        'prediction_link': 'https://top10stockbroker.com/stock-prediction/maruti-suzuki-share-price-prediction/',
    },
    {
        'logo': '../assets/img/logo/Larsen & Toubro Share Price.png',
        'name': 'Larsen & Toubro',
        'live_name': 'Larsen & Toubro Live',
        'live_link': 'https://top10stockbroker.com/share-price/l&t-share-price/',
        'prediction_name': 'Larsen & Toubro Prediction',
        'prediction_link': ' https://top10stockbroker.com/stock-prediction/lt-share-price-prediction/',
    },
    {
        'logo': '../assets/img/logo/Sun Pharma Share Price.png',
        'name': 'Sun Pharma',
        'live_name': 'Sun Pharma Live',
        'live_link': 'https://top10stockbroker.com/share-price/sun-pharma-share-price/',
        'prediction_name': 'Sun Pharma Prediction',
        'prediction_link': 'https://top10stockbroker.com/stock-prediction/sun-pharma-share-price-prediction/',
    }     
]
 
export {top10Stocks, topIndices};