import React, { useEffect, useState } from "react";
// import { View,Link,Divider,Menu,MenuItem,Heading,Card,Flex,Button } from '@aws-amplify/ui-react';
import { Link as ScreensLink, useParams } from "react-router-dom";

import { API } from "aws-amplify";
import {
  getWatchlistById,
  getWatchlistCompanies,
  searchCompanyByName,
} from "../../graphql/queries";
import {
  addWatchlistCompany,
  deleteWatchlistCompany,
} from "../../graphql/mutations";

function ManageCompnies() {
  const [watchlist, setWatchlist] = useState([]);
  const [watchlistCompanies, setWatchlistCompanies] = useState([]);
  const [conpanyError, setConpanyErrors] = useState(false);
  const [searchCompanies, setSearchCompanies] = useState([]);

  const { wl_id } = useParams();
  useEffect(() => {
    async function fetchWatchlist() {
      const result = await API.graphql({
        query: getWatchlistById,
        variables: { wl_id },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      if (result.data.getWatchlistById) {
        setWatchlist(result.data.getWatchlistById);
      }
    }
    fetchWatchlist();
    async function fetchWatchlistCompanies() {
      const result = await API.graphql({
        query: getWatchlistCompanies,
        variables: { wl_id, limit: 50, nextToken: "" },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      if (result.data.getWatchlistCompanies.items.length) {
        setWatchlistCompanies(result.data.getWatchlistCompanies.items);
      }
    }
    fetchWatchlistCompanies();
  }, []);

  let fetchCompanies = async (name) => {
    const result = await API.graphql({
      query: searchCompanyByName,
      variables: { search_text: name },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    if (result.data.searchCompanyByName.items.length) {
      setSearchCompanies(result.data.searchCompanyByName.items);
      setSearchCompanies((state) => {
        return state;
      });
    }
  };
  let handleSearchCompanies = async (event) => {
    event.preventDefault();
    var target = event.target;
    // console.log(target.value);
    var searchText = target.value;
    if (searchText.length < 3) {
      // document.getElementsByClassName('dropdown-content').removeClass('visible');
      return setSearchCompanies([]);
    } else {
      try {
        // document.getElementsByClassName('dropdown-content').addClass('dropdown-content', 'visible');
        fetchCompanies(searchText);
      } catch (err) {
        console.log(err);
      }
    }

    return false;
  };
  let handleSelectCompany = async (event) => {
    event.preventDefault();
    var target = event.target;
    var fincode = target.getAttribute("data-fincode");
    const AddCompanyToWatchlistInput = {
      watchlistCompanyInput: {
        FINCODE: fincode,
        wl_id: wl_id,
      },
    };
    const result = await API.graphql({
      query: addWatchlistCompany,
      variables: AddCompanyToWatchlistInput,
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    if (result.data.addWatchlistCompany) {
      setWatchlistCompanies([
        ...watchlistCompanies,
        result.data.addWatchlistCompany,
      ]);
    }
    setSearchCompanies([]);
    document.getElementById("watchlist-search").value = "";
  };
  let handleRemoveCompany = async (event) => {
    event.preventDefault();
    var target = event.target;
    var fincode = target.getAttribute("data-fincode");
    const deleteCompanyToWatchlistInput = {
      watchlistCompanyInput: {
        FINCODE: fincode,
        wl_id: wl_id,
      },
    };
    const result = await API.graphql({
      query: deleteWatchlistCompany,
      variables: deleteCompanyToWatchlistInput,
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    let shortableColumn1 = [];
    shortableColumn1 = watchlistCompanies.filter(
      (item) => item.FINCODE !== fincode
    );
    setWatchlistCompanies(shortableColumn1);
    setSearchCompanies([]);
    document.getElementById("watchlist-search").value = "";
  };
  return (
    <div className="row">
      <div className="card card-body mg-lg-x-200">
        <div className="pd-lg-x-20">
          <form method="get" action={"/watchlist/" + wl_id}>
            <h3>Add companies to {watchlist.name}</h3>
            <div className="row tx-14">
              <div className="col-sm-12 form-field pd-y-10">
                <label htmlFor="description"> Company name</label>
                <div className="flex-row flex-space-between flex-gap-16">
                  <i className="addon icon-search"></i>
                  <div className="dropdown-typeahead">
                    <input
                      id="watchlist-search"
                      onKeyUp={handleSearchCompanies}
                      placeholder="eg. Infosys"
                      type="search"
                      className="u-full-width form-control"
                    />
                    <ul
                      className={
                        searchCompanies.length
                          ? "dropdown-content visible"
                          : "dropdown-content"
                      }
                      style={{
                        top: "77px",
                        left: "0px",
                        visibility: "visible",
                      }}
                    >
                      {searchCompanies.length > 0 ? (
                        searchCompanies.map((col, index) => {
                          return (
                            <li className="mg-y-10"
                              onClick={handleSelectCompany}
                              key={"most_used_recent_" + index}
                              data-fincode={col.FINCODE}
                              data-compname={col.COMPNAME}
                            >
                              {col.COMPNAME}
                            </li>
                          );
                        })
                      ) : (
                        <li></li>
                      )}
                    </ul>
                    {conpanyError ? (
                      <div
                        className="alert alert-danger alert-dismissible fade show mg-y-5"
                        role="alert"
                      >
                        {conpanyError}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              {watchlistCompanies.length > 0 ? (
                watchlistCompanies.map((col, index) => {
                  return (
                    <div
                      className="col-sm-4 border-bottom flex-gap-20 mg-y-10"
                      key={"wl_comp_" + index}
                    >
                      <span className="shrink-text">{col.COMPNAME}</span>
                      <button
                        type="button"
                        className="close mg-l-15 button-plain red float-right"
                        aria-label="Close"
                      >
                        <span
                          aria-hidden="true"
                          onClick={handleRemoveCompany}
                          data-fincode={col.FINCODE}
                        >
                          &times;
                        </span>
                      </button>
                    </div>
                  );
                })
              ) : (
                <div className="col-sm-4"></div>
              )}
            </div>

            <div className="flex-row flex-space-between flex-gap-20 mg-t-10">
              <div className="d-md-block">
                <ScreensLink
                  to={"/watchlist/" + wl_id}
                  className="btn btn-primary pd-x-15  btn-uppercase"
                >
                  Done
                </ScreensLink>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
export default ManageCompnies;
