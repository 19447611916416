import React, { useEffect, useState, useMemo } from "react";

import { Tabs, TabItem, View, Heading, Flex } from "@aws-amplify/ui-react";
import { API } from "aws-amplify";
import { listRatioGalleries } from "../../graphql/queries";
import '../../styles/pages/create_search_query.scss';
import { Link as ScreensLink, useNavigate } from "react-router-dom";
 
function NewScreen() {
  const navigate = useNavigate();
  const [toggleRatio, setToggleRatio] = React.useState(true);
  const [galleryShowHideText, setShowHideText] =
    React.useState("Close Gallery");
  const [ratioMostUsed, setMostUsed] = React.useState([]);
  const [ratioIncomeStatement, setIncomeStatement] = React.useState([]);
  const [ratioFinancialRatios, setFinancialRatios] = React.useState([]);
  const [ratioBalanceSheet, setbalanceSheet] = React.useState([]);
  const [ratioCashFlow, setCashFlow] = React.useState([]);
  const [ratioQuarterlyResults, setQuarterlyResults] = React.useState([]);
  const [ratioPrice, setPrice] = React.useState([]);
  const [allOption, setRatioOption] = React.useState([]);
  const [isDataLoaded, setLoadingState] = React.useState(false);
  const [search, setSearch] = useState(false);
  const [search_query, setSearchQuery] = useState(false);
  

  
  useEffect(() => {
    if(!isDataLoaded) {
      fetchRatioGalleries();
    }else{
      // console.log('Data already loaded');
      handleApiDataonLoad()
    }
  }, [allOption]);

  
  async function fetchRatioGalleries() {
    // console.log('Get Options--- '); 
    const result = await API.graphql({
      query: listRatioGalleries,
      variables: { limit: 10, nextToken: null },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    if (
      result.data.listRatioGalleries &&
      result.data.listRatioGalleries.items
    ) {
      setLoadingState(true);
      setMostUsed(result.data.listRatioGalleries.items.most_used);
      setIncomeStatement(result.data.listRatioGalleries.items.income_statement);
      setFinancialRatios(result.data.listRatioGalleries.items.financial_ratios);
      setbalanceSheet(result.data.listRatioGalleries.items.balance_sheet);
      setCashFlow(result.data.listRatioGalleries.items.cash_flow);
      setQuarterlyResults(result.data.listRatioGalleries.items.quarterly_results);
      setPrice(result.data.listRatioGalleries.items.price);
      setRatioOption(result.data.listRatioGalleries.items.all_ratios);
      
      setSearch("");
    }
  }

  let Typeahead = (input, getOptions, selectOption) => {
    var self = { input: input };
    var menuOptions = [];
    var menuIndex = -1;
    var _req;
    // Check Event on search query box
    function handleKeyDown(event) {
      var maxIndex = menuOptions.length - 1;
      if (maxIndex < 0 || menuIndex < 0) return event; // Do not interfere if no menu
      switch (event.which) {
        case 40: // Down Key
          event.preventDefault();
          menuIndex++;
          if (menuIndex > maxIndex) {
            menuIndex = maxIndex;
          }
          updateActiveInMenu();
          break;
        case 38: // Up Key
          event.preventDefault();
          menuIndex--;
          if (menuIndex < 0) {
            menuIndex = maxIndex;
          }
          updateActiveInMenu();
          break;
        case 9: // Tab Key
        case 13: // Enter Key
          event.preventDefault();
          handleOptionSelect(menuIndex);
          break;
        case 27: // esc
          event.preventDefault();
          resetMenu();
          break;
      }
    }

    function handleInput(event) {
      var onGettingOptions = function (options) {
        // parse to json if string
        if (typeof options === "string") {
          options = JSON.parse(options);
        }
        // Replace options in menu
        menuOptions = options;
        menuIndex = 0;
        var newMenu = getNewMenu();
        var parent = self.menu.parentNode;
        parent.replaceChild(newMenu, self.menu);
        self.menu = newMenu;
      };
      if (_req) {
        _req.abort();
      }
      _req = getOptions(event.target.value, onGettingOptions);
    }

    function handleOptionSelect(index) {
      menuIndex = index;
      updateActiveInMenu();
      selectOption(menuOptions, menuIndex);
      resetMenu();
    }

    function handleBlur(event) {
      setTimeout(function () {
        resetMenu();
      }, 200);
    }

    function updateActiveInMenu() {
      var lis = self.menu.querySelectorAll("li");
      for (var i = 0; i < lis.length; i++) {
        lis[i].className = menuIndex === i ? "active" : "";
      }
    }

    function getNewMenu() {
      var menu = document.createElement("ul");
      menu.classList.add("dropdown-content", "visible");
      for (var i = 0; i < menuOptions.length; i++) {
        (function () {
          var index = i;
          var li = document.createElement("li");
          li.innerHTML = menuOptions[i].name;
          if (index === menuIndex) {
            li.className = "active";
          }
          li.addEventListener("mousedown", function () {
            handleOptionSelect(index);
          });
          menu.appendChild(li);
        })();
      }
      return menu;
    }

    function resetMenu() {
      menuIndex = -1;
      menuOptions = [];
      self.menu.classList.remove("visible");
    }

    // make the parent position default relative to set dropdown position
    var parent = input.parentNode;
    // Add Menu
    input.style.marginBottom = 0;
    self.menu = getNewMenu();
    parent.appendChild(self.menu);
    resetMenu();
    // Add listeners
    input.addEventListener("keydown", handleKeyDown);
    input.addEventListener("input", handleInput);
    input.addEventListener("blur", handleBlur);
    return self;
  };

  let getTillCursor = (textarea) => {
    var fullVal = textarea.value;
    var cursorPos = textarea.selectionStart;
    var tillCursor = fullVal.substring(0, cursorPos);
    return tillCursor;
  };

  let getLastWordBeforeCursor = (textarea) => {
    var tillCursor = getTillCursor(textarea);
    if (tillCursor.toLowerCase().endsWith(" and")) {
      return tillCursor.substring(tillCursor.length - 3);
    }
    // if (tillCursor.toLowerCase().endsWith(" or")) {
    //   return tillCursor.substring(tillCursor.length - 2);
    // }
    //var pattern = /\sand\s|\sor\s|[^a-z0-9 -]|\n/gi;
    var pattern = /\sand\s|[^a-z0-9 -]|\n/gi;
    var parts = tillCursor.split(pattern);
    if (parts.length) return parts[parts.length - 1].trim();
    return "";
  };

  let insertWord = (textarea, text, appendOnly) => {
    // Inserts the given word in the query-builder at cursor position
    var fullVal = textarea.value;
    // console.log('fullVal'+fullVal);
    var cursorPos = textarea.selectionStart;
    // console.log('cursorPos'+cursorPos);
    var tillCursor = fullVal.substring(0, cursorPos);
    // console.log('tillCursor'+tillCursor);
    var lastWord = getLastWordBeforeCursor(textarea);
    // console.log('lastWord'+lastWord);
    var insertPos = tillCursor.lastIndexOf(lastWord);
    // console.log('insertPos'+insertPos);
    if (appendOnly) insertPos = cursorPos;
    if (insertPos === -1) return;
    var before = fullVal.substring(0, insertPos);
    // console.log('before'+before);
    var after = fullVal.substring(cursorPos);
    var newVal = before + text + after;
    var newPos = newVal.length - after.length;
    textarea.value = newVal;
    textarea.selectionStart = newPos;
    textarea.selectionEnd = newPos;
  };

  
  window.Typeahead = Typeahead;
  let TypeaheadUtils = {
    insertWord: insertWord,
    getLastWordBeforeCursor: getLastWordBeforeCursor,
    getTillCursor: getTillCursor,
  };

  let showRatioHelp = (ratio) => {
    var el = document.getElementById("query-help-name");
    el.textContent = ratio.name;
    el = document.getElementById("query-help-description");
    el.textContent = ratio.description;
    el = document.getElementById("query-help-detail");
    el.textContent = "Value in: " + ratio.unit;
  };

  let updateDropdownPosition = (textarea, typeahead) => {
    var tillCursor = TypeaheadUtils.getTillCursor(textarea);
    var lines = tillCursor.split(/\n/);
    var lastLine = lines[lines.length - 1];
    var menu = typeahead.menu;
    var top = 15 + lines.length * 20;
    var left = lastLine.length * 10;
    var maxHeight = typeahead.input.offsetHeight;
    var maxWidth = typeahead.input.offsetWidth;
    if (top > maxHeight) top = maxHeight;
    if (left > maxWidth) left = maxWidth / 3;
    menu.style.top = top + "px";
    menu.style.left = left + "px";
  };

  let updateMenuPosDecorator = (textarea, typeahead, processOptions) => {
    updateDropdownPosition(textarea, typeahead);
    return function (options) {
      processOptions(options);
      updateDropdownPosition(textarea, typeahead);
    };
  };

  let selectRatio = (target) => {
    var textarea = document.getElementById("query-builder");
    var ratio = {
      name: target.getAttribute("data-name"),
      description: target.getAttribute("data-description"),
      unit: target.getAttribute("data-unit"),
    };
    TypeaheadUtils.insertWord(textarea, ratio.name + " ");
    window.showRatioHelp(ratio);
    textarea.focus();
  };

  let getRatioOptions = (textarea, typeahead, name, processOptions) => {
    var lastWord = TypeaheadUtils.getLastWordBeforeCursor(textarea).trim().toLowerCase();
    if (!isNaN(lastWord)) return processOptions([]); // don't process for numbers or empty string
    if (lastWord.toLowerCase() === "and") {
      TypeaheadUtils.insertWord(textarea, "AND\n");
      return processOptions([]);
    }
    // if (lastWord.toLowerCase() === "or") {
    //   TypeaheadUtils.insertWord(textarea, "OR\n");
    //   return processOptions([]);
    // }
    var searchOpt = [];
    updateDropdownPosition(textarea, typeahead);

    // type into the textarea and seach from the options
    // console.log(lastWord+"d"+allOption);
    // 
    allOption
      .filter((allOption) => allOption.name.toLowerCase().startsWith(lastWord))
      .map((allOption) => {
        searchOpt.push(allOption);
      });
      allOption
          .filter((allOption) =>allOption.keyword.toLowerCase().startsWith(lastWord))
          .map((allOption) => {
            searchOpt.push(allOption);
      });
      allOption
      .filter((allOption) => allOption.name.toLowerCase().includes(lastWord))
      .map((allOption) => {
        searchOpt.push(allOption);
      });
      allOption
      .filter((allOption) =>allOption.keyword.toLowerCase().includes(lastWord))
      .map((allOption) => {
        searchOpt.push(allOption);
      });
    processOptions(searchOpt);
    updateMenuPosDecorator(textarea, typeahead, processOptions);
  };

  let handleRatioSelect = (textarea, options, selectedIndex) => {
    var ratio = options[selectedIndex];
    TypeaheadUtils.insertWord(textarea, ratio.name + " ");
    showRatioHelp(ratio);
  };
  // Event Register after data loaded from api
  const handleApiDataonLoad = (event) => {
    window.showRatioHelp = showRatioHelp;
    var textarea = document.getElementById("query-builder");
    function onSearch(name, setOptions) {
      getRatioOptions(textarea, typeahead, name, setOptions);
    }
    function onSelect(options, index) {
      handleRatioSelect(textarea, options, index);
    }
    var typeahead = Typeahead(textarea, onSearch, onSelect);
  };
    
  const handleInsertOperator = (event) => {
    var target = event.target;
    event.preventDefault();
    var textarea = document.getElementById("query-builder");
    var appendOnly = true;
    var content = target.textContent;
    if (content === "AND" || content === "OR") content += "\n ";
    else content += " ";
    TypeaheadUtils.insertWord(textarea, content, appendOnly);
    textarea.focus();
  };

  const handleSelectRatio = (event) => {
    var target = event.target;
    event.preventDefault();
    var textarea = document.getElementById("query-builder");
    var ratio = {
      name: target.getAttribute("data-name"),
      description: target.getAttribute("data-description"),
      unit: target.getAttribute("data-unit"),
    };
    TypeaheadUtils.insertWord(textarea, ratio.name + " ");
    window.showRatioHelp(ratio);
    textarea.focus();
  };
 
  

  let handleRatioToggle = (e) => {
    e.preventDefault();
    if (toggleRatio) {
      setToggleRatio(false);
      setShowHideText("Show All Ratios");
    } else {
      setToggleRatio(true);
      setShowHideText("Close Gallery");
    }
    return false;
  };
  const filteredMostUsedRatios = useMemo(() => {
    if (search) {
      return ratioMostUsed.filter(
        (item) =>
          item.name.toLowerCase().indexOf(search.toLocaleLowerCase()) > -1
      );
    }
    return ratioMostUsed;
  }, [search]);
  const filteredIncomeStatementRatios = useMemo(() => {
    if (search) {
      return ratioIncomeStatement.filter(
        (item) =>
          item.name.toLowerCase().indexOf(search.toLocaleLowerCase()) > -1
      );
    }
    return ratioIncomeStatement;
  }, [search]);
  const filteredFinancialRatios = useMemo(() => {
    if (search) {
      return ratioFinancialRatios.filter(
        (item) =>
          item.name.toLowerCase().indexOf(search.toLocaleLowerCase()) > -1
      );
    }
    return ratioFinancialRatios;
  }, [search]);
  const filteredBalanceSheetRatios = useMemo(() => {
    if (search) {
      return ratioBalanceSheet.filter(
        (item) =>
          item.name.toLowerCase().indexOf(search.toLocaleLowerCase()) > -1
      );
    }
    return ratioBalanceSheet;
  }, [search]);
  const filteredCashFlowRatios = useMemo(() => {
    if (search) {
      return ratioCashFlow.filter(
        (item) =>
          item.name.toLowerCase().indexOf(search.toLocaleLowerCase()) > -1
      );
    }
    return ratioCashFlow;
  }, [search]);
  const filteredQuarterlyResultsRatios = useMemo(() => {
    if (search) {
      return ratioQuarterlyResults.filter(
        (item) =>
          item.name.toLowerCase().indexOf(search.toLocaleLowerCase()) > -1
      );
    }
    return ratioQuarterlyResults;
  }, [search]);
  const filteredPriceRatios = useMemo(() => {
    if (search) {
      return ratioPrice.filter(
        (item) =>
          item.name.toLowerCase().indexOf(search.toLocaleLowerCase()) > -1
      );
    }
    return ratioPrice;
  }, [search]);
  const handleSearchQueryResults = (event) => {
    event.preventDefault();
    navigate("/screen/query-result/?source=&query="+search_query);
    // console.log("submission prevented");
  };
  return (
    <div className="row">
      <div className="card card-body mg-lg-x-200 create_search_query">
        <div className="pd-lg-x-20">
          <form method="get" onSubmit={handleSearchQueryResults} action="/screen/query-result/">
            
            <div className="row">
            <div className="col-12 d-flex justify-content-between">
            <h3 className="tx-18 tx-lg-32 d-inline ">Create a Search Query</h3>
              <div className="flex-row float-right">
                <ScreensLink
                  to={"/screens"}
                  className="btn btn-primary pd-x-20  btn-uppercase"
                > ← Go Back
                </ScreensLink>
              </div>
            </div>
            
            </div>
            {/* <h5 htmlFor="query-builder"> Query </h5> */}
            <div className="row tx-14">
              <div className="col-sm-6 mg-y-10">
                <div className="flex-row flex-space-between flex-gap-16">
                  <div className="dropdown-typeahead">
                    <textarea
                      onChange={(e) => setSearchQuery(e.target.value)}
                      name="query"
                      cols="60"
                      rows="6"
                      id="query-builder"
                      required
                      className="amplify-textarea amplify-field-group__control"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 mg-y-10">
                <div className="card card-body bd">
                  <p className="bigger margin-bottom-0" id="query-help-name">
                    Custom query example
                  </p>
                  <p id="query-help-description">
                    Accumulated Depriciation &gt; 500 AND Adjustments &lt; 50
                    AND Advance to Customers &gt; 200
                  </p>
                  <div className="sub" id="query-help-detail">
                    <a href="/guides/creating-screens/">
                      Detailed guide on creating screens
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-field mg-y-10">
              <label className="label-body check_wrap">
                <input type="checkbox" name="latest" id="id_latest" />
                <span>Remove companies without recent quarterly result? </span>
              </label>
            </div>

            <div className="flex-row flex-space-between flex-gap-16">
              <div className="d-md-block">
                <button
                  className="btn btn-primary pd-x-20  btn-uppercase"
                  type="submit"
                >
                  <i className="icon-beaker"></i>
                  Run this Query
                </button>
                {/*
                    <ScreensLink to="/screen/view" className="btn btn-primary pd-x-20  btn-uppercase">Run this Query</ScreensLink>
                    */}

                <button
                  onClick={handleRatioToggle}
                  className="btn pd-x-20 btn-white btn-uppercase float-right"
                  type="button"
                >
                  <i className="icon-beaker"></i>
                  {galleryShowHideText}
                </button>
              </div>
            </div>
          </form>
        </div>
        {toggleRatio && (
          <div className="pd-lg-x-20">
            <View>
              <div className="d-md-block mg-t-20">
                <Heading level={3}>Ratio Gallery</Heading>
              </div>
              <Flex>
                <div className="d-md-block mg-t-20  mg-b-20">
                  <button
                    onClick={handleInsertOperator}
                    className="btn btn-white mg-r-10"
                  >
                    {">"}
                  </button>
                  <button
                    onClick={handleInsertOperator}
                    className="btn btn-white mg-x-10"
                  >
                    {"<"}
                  </button>
                  <button
                    onClick={handleInsertOperator}
                    className="btn btn-white mg-x-10"
                  >
                    AND
                  </button>
                </div>
              </Flex>
              <Flex className="search_tab_wrapp">
                <div className="d-md-block __querysearch">
                  <div className="search-form">
                    <input
                      type="search"
                      onChange={(e) => setSearch(e.target.value)}
                      className="form-control"
                      placeholder="eg.sales"
                    />
                    <button className="btn" type="button">
                      <i data-feather="search"></i>
                    </button>
                  </div>
                </div>
                <div className="horizontal_tabs">
                <Tabs className="horizontal_tabs_wrap">
                  <TabItem title="Most Used" >
                    <div className="row mg-t-30 tab_listing">
                      <div className="col-sm-12 col-lg-6 mg-t-10 mg-sm-t-0 list_col">
                        <h6>RECENT</h6>
                        <div className="flex-rows">
                          {filteredMostUsedRatios.length > 0 ? (
                            filteredMostUsedRatios.map((col, index) => {
                              if (col.sub_category === "Recent"){
                                return (
                                  <button
                                    onClick={handleSelectRatio}
                                    key={"most_used_recent_" + index}
                                    data-name={col.name}
                                    data-short-name={col.screen_name}
                                    data-description={col.description}
                                    data-unit={col.unit}
                                    className="btn pd-x-15 btn-white btn-uppercase mg-5 mg-b-10"
                                  >
                                    {col.name}
                                  </button>
                                );
                              }else{
                                return false;
                              }
                            })
                          ) : (
                            <div></div>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-6 col-lg-6 mg-t-10 mg-sm-t-0 list_col">
                        <h6>HISTORICAL</h6>
                        <div className="flex-row">
                          {filteredMostUsedRatios.length > 0 ? (
                            filteredMostUsedRatios.map((col, index) => {
                              if (col.sub_category === "Historical"){
                                return (
                                  <button
                                    onClick={handleSelectRatio}
                                    key={"most_used_historical_" + index}
                                    data-name={col.name}
                                    data-short-name={col.screen_name}
                                    data-description={col.description}
                                    data-unit={col.unit}
                                    className="btn pd-x-15 btn-white btn-uppercase mg-5 mg-b-10"
                                  >
                                    {col.name}
                                  </button>
                                );
                              }else{
                                return false;
                              }
                            })
                          ) : (
                            <div></div>
                          )}
                        </div>
                      </div>
                    </div>
                  </TabItem>
                  <TabItem title="Income Statement">
                    <div className="row mg-t-30">
                      <div className="col-sm-6 col-lg-6 mg-t-10 mg-sm-t-0">
                        <p>RECENT</p>
                        <div className="flex-row">
                          {filteredIncomeStatementRatios.length > 0 ? (
                            filteredIncomeStatementRatios.map((col, index) => {
                              if (col.sub_category === "Recent"){
                                return (
                                  <button
                                    onClick={handleSelectRatio}
                                    key={"income_stm_recent_" + index}
                                    data-name={col.name}
                                    data-short-name={col.screen_name}
                                    data-description={col.description}
                                    data-unit={col.unit}
                                    className="btn pd-x-15 btn-white btn-uppercase mg-5 mg-b-10"
                                  >
                                    {col.name}
                                  </button>
                                );
                              }else{
                                return false;
                              }
                            })
                          ) : (
                            <div></div>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-6 col-lg-6 mg-t-10 mg-sm-t-0">
                        <p>HISTORICAL</p>
                        <div className="flex-row">
                          {filteredIncomeStatementRatios.length > 0 ? (
                            filteredIncomeStatementRatios.map((col, index) => {
                              if (col.sub_category === "Historical"){
                                return (
                                  <button
                                    onClick={handleSelectRatio}
                                    key={"income_stm_historical_" + index}
                                    data-name={col.name}
                                    data-short-name={col.screen_name}
                                    data-description={col.description}
                                    data-unit={col.unit}
                                    className="btn pd-x-15 btn-white btn-uppercase mg-5 mg-b-10"
                                  >
                                    {col.name}
                                  </button>
                                );
                              }else{
                                return false;
                              }
                            })
                          ) : (
                            <div></div>
                          )}
                        </div>
                      </div>
                    </div>
                  </TabItem>
                  <TabItem title="Financial Ratios">
                    <div className="row mg-t-30">
                      <div className="col-sm-6 col-lg-6 mg-t-10 mg-sm-t-0">
                        <p>RECENT</p>
                        <div className="flex-row">
                          {filteredFinancialRatios.length > 0 ? (
                            filteredFinancialRatios.map((col, index) => {
                              if (col.sub_category === "Recent"){
                                return (
                                  <button
                                    onClick={handleSelectRatio}
                                    key={"financial_ratios_recent_" + index}
                                    data-name={col.name}
                                    data-short-name={col.screen_name}
                                    data-description={col.description}
                                    data-unit={col.unit}
                                    className="btn pd-x-15 btn-white btn-uppercase mg-5 mg-b-10"
                                  >
                                    {col.name}
                                  </button>
                                );
                              }else{
                                return false;
                              }
                            })
                          ) : (
                            <div></div>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-6 col-lg-6 mg-t-10 mg-sm-t-0">
                        <p>HISTORICAL</p>
                        <div className="flex-row">
                          {filteredFinancialRatios.length > 0 ? (
                            filteredFinancialRatios.map((col, index) => {
                              if (col.sub_category === "Historical"){
                                return (
                                  <button
                                    onClick={handleSelectRatio}
                                    key={"financial_ratios_historical_" + index}
                                    data-name={col.name}
                                    data-short-name={col.screen_name}
                                    data-description={col.description}
                                    data-unit={col.unit}
                                    className="btn pd-x-15 btn-white btn-uppercase mg-5 mg-b-10"
                                  >
                                    {col.name}
                                  </button>
                                );
                              }else{
                                return false;
                              }
                            })
                          ) : (
                            <div></div>
                          )}
                        </div>
                      </div>
                    </div>
                  </TabItem>
                  <TabItem title="Balance Sheet">
                    <div className="row mg-t-30">
                      <div className="col-sm-6 col-lg-6 mg-t-10 mg-sm-t-0">
                        <p>RECENT</p>
                        <div className="flex-row">
                          {filteredBalanceSheetRatios.length > 0 ? (
                            filteredBalanceSheetRatios.map((col, index) => {
                              if (col.sub_category === "Recent"){
                                return (
                                  <button
                                    onClick={handleSelectRatio}
                                    key={"balance_sheet_recent_" + index}
                                    data-name={col.name}
                                    data-short-name={col.screen_name}
                                    data-description={col.description}
                                    data-unit={col.unit}
                                    className="btn pd-x-15 btn-white btn-uppercase mg-5 mg-b-10"
                                  >
                                    {col.name}
                                  </button>
                                );
                              }else{
                                return false;
                              }
                            })
                          ) : (
                            <div></div>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-6 col-lg-6 mg-t-10 mg-sm-t-0">
                        <p>HISTORICAL</p>
                        <div className="flex-row">
                          {filteredBalanceSheetRatios.length > 0 ? (
                            filteredBalanceSheetRatios.map((col, index) => {
                              if (col.sub_category === "Historical"){
                                return (
                                  <button
                                    onClick={handleSelectRatio}
                                    key={"balance_sheet_historical_" + index}
                                    data-name={col.name}
                                    data-short-name={col.screen_name}
                                    data-description={col.description}
                                    data-unit={col.unit}
                                    className="btn pd-x-15 btn-white btn-uppercase mg-5 mg-b-10"
                                  >
                                    {col.name}
                                  </button>
                                );
                              }else{
                                return false;
                              }
                            })
                          ) : (
                            <div></div>
                          )}
                        </div>
                      </div>
                    </div>
                  </TabItem>
                  <TabItem title="Cash Flow">
                    <div className="row mg-t-30">
                      <div className="col-sm-6 col-lg-6 mg-t-10 mg-sm-t-0">
                        <p>RECENT</p>
                        <div className="flex-row">
                          {filteredCashFlowRatios.length > 0 ? (
                            filteredCashFlowRatios.map((col, index) => {
                              if (col.sub_category === "Recent"){
                                return (
                                  <button
                                    onClick={handleSelectRatio}
                                    key={"cash_flow_recent_" + index}
                                    data-name={col.name}
                                    data-short-name={col.screen_name}
                                    data-description={col.description}
                                    data-unit={col.unit}
                                    className="btn pd-x-15 btn-white btn-uppercase mg-5 mg-b-10"
                                  >
                                    {col.name}
                                  </button>
                                );
                              }else{
                                return false;
                              }
                            })
                          ) : (
                            <div></div>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-6 col-lg-6 mg-t-10 mg-sm-t-0">
                        <p>HISTORICAL</p>
                        <div className="flex-row">
                          {filteredCashFlowRatios.length > 0 ? (
                            filteredCashFlowRatios.map((col, index) => {
                              if (col.sub_category === "Historical"){
                                return (
                                  <button
                                    onClick={handleSelectRatio}
                                    key={"cash_flow_historical_" + index}
                                    data-name={col.name}
                                    data-short-name={col.screen_name}
                                    data-description={col.description}
                                    data-unit={col.unit}
                                    className="btn pd-x-15 btn-white btn-uppercase mg-5 mg-b-10"
                                  >
                                    {col.name}
                                  </button>
                                );
                              }else{
                                return false;
                              }
                            })
                          ) : (
                            <div></div>
                          )}
                        </div>
                      </div>
                    </div>
                  </TabItem>
                  <TabItem title="Quarterly Results">
                    <div className="row mg-t-30">
                      <div className="col-sm-6 col-lg-6 mg-t-10 mg-sm-t-0">
                        <p>RECENT</p>
                        <div className="flex-row">
                          {filteredQuarterlyResultsRatios.length > 0 ? (
                            filteredQuarterlyResultsRatios.map((col, index) => {
                              if (col.sub_category === "Recent"){
                                return (
                                  <button
                                    onClick={handleSelectRatio}
                                    key={"quarterly_results_recent_" + index}
                                    data-name={col.name}
                                    data-short-name={col.screen_name}
                                    data-description={col.description}
                                    data-unit={col.unit}
                                    className="btn pd-x-15 btn-white btn-uppercase mg-5 mg-b-10"
                                  >
                                    {col.name}
                                  </button>
                                );
                              }else{
                                return false;
                              }
                            })
                          ) : (
                            <div></div>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-6 col-lg-6 mg-t-10 mg-sm-t-0">
                        <p>HISTORICAL</p>
                        <div className="flex-row">
                          {filteredQuarterlyResultsRatios.length > 0 ? (
                            filteredQuarterlyResultsRatios.map((col, index) => {
                              if (col.sub_category === "Historical"){
                                return (
                                  <button
                                    onClick={handleSelectRatio}
                                    key={
                                      "quarterly_results_historical_" + index
                                    }
                                    data-name={col.name}
                                    data-short-name={col.screen_name}
                                    data-description={col.description}
                                    data-unit={col.unit}
                                    className="btn pd-x-15 btn-white btn-uppercase mg-5 mg-b-10"
                                  >
                                    {col.name}
                                  </button>
                                );
                              }else{
                                return false;
                              }
                            })
                          ) : (
                            <div></div>
                          )}
                        </div>
                      </div>
                    </div>
                  </TabItem>
                  <TabItem title="Price">
                    <div className="row row-xs mg-t-30">
                      <div className="col-sm-6 col-lg-6 mg-t-10 mg-sm-t-0">
                        <p>RECENT</p>
                        <div className="flex-row">
                          {filteredPriceRatios.length > 0 ? (
                            filteredPriceRatios.map((col, index) => {
                              if (col.sub_category === "Recent"){
                                return (
                                  <button
                                    onClick={handleSelectRatio}
                                    key={"price_recent_" + index}
                                    data-name={col.name}
                                    data-short-name={col.screen_name}
                                    data-description={col.description}
                                    data-unit={col.unit}
                                    className="btn pd-x-15 btn-white btn-uppercase mg-5 mg-b-10"
                                  >
                                    {col.name}
                                  </button>
                                );
                              }else{
                                return false;
                              }
                            })
                          ) : (
                            <div></div>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-6 col-lg-6 mg-t-10 mg-sm-t-0">
                        <p>HISTORICAL</p>
                        <div className="flex-row">
                          {filteredPriceRatios.length > 0 ? (
                            filteredPriceRatios.map((col, index) => {
                              if (col.sub_category === "Historical"){
                                return (
                                  <button
                                    onClick={handleSelectRatio}
                                    key={"price_historical_" + index}
                                    data-name={col.name}
                                    data-short-name={col.screen_name}
                                    data-description={col.description}
                                    data-unit={col.unit}
                                    className="btn pd-x-15 btn-white btn-uppercase mg-5 mg-b-10"
                                  >
                                    {col.name}
                                  </button>
                                );
                              }else{
                                return false;
                              }
                            })
                          ) : (
                            <div></div>
                          )}
                        </div>
                      </div>
                    </div>
                  </TabItem>
                </Tabs>
                </div>
              </Flex>
            </View>
          </div>
        )}
      </div>
    </div>
  );
}
export default NewScreen;
