import { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import {
    PhoneNumberField,
    Alert,
    TextField,
} from "@aws-amplify/ui-react";
import { Auth,API } from "aws-amplify"
import { authActions } from "../../store/auth";


import { createOpenAccountRequest } from "../../graphql/mutations";

function OpenDematAccountForm({ alert, alert_heading }) {
    const nameRef = useRef(null);
    const [invalidName, setInvalidName] = useState(false);
    const phoneRef = useRef(null);
    const countryCodeRef = useRef(null);
    const [invalidNumber, setInvalidNumber] = useState(false);
    const cityRef = useRef(null);
    const [invalidCity, setInvalidCity] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);

    const dispatch = useDispatch();
    useEffect(() => {
         
    }, []);

    const validFormInput = (name, ref, callback) => {
        const value = ref.current.value;
        switch (name) {
        case "Enter Your Name":
        case "Enter Your City":
            if (value.length === 0) {
            callback(`${name} is required.`);
            return false;
            } else if (value.length < 2) {
            callback(`${name} should be atleast 2 characters`);
            return false;
            } else {
            callback(false);
            return true;
            }
        case "Enter Your Number":
            const validRegex =
            /^[6789]\d{9}$/;
            if (!value.match(validRegex)) {
            callback("Invalid number! Use 10 digit numbers starting with 6, 7, 8 or 9.");
            return false;
            } else {
            callback(false);
            return true;
            }
        default:
            setErrorMessage("Please enter a valid value.");
            return false;
        }
    }; 
    const handleSubmitAccountRequest = async () => {
        setErrorMessage(false);
        setSuccessMessage(false);
        try {
            const name = nameRef.current.value;
            const countryCode = countryCodeRef.current.value;
            const phoneRefVal = phoneRef.current.value;
            const phoneNum = `${countryCode}${phoneRefVal}`;
            const city = cityRef.current.value;
            
            validFormInput("Enter Your Name", nameRef, setInvalidName);
            validFormInput("Enter Your Number", phoneRef, setInvalidNumber);
            validFormInput("Enter Your City", cityRef, setInvalidCity);
            if(validFormInput("Enter Your Name", nameRef, setInvalidName) 
            && validFormInput("Enter Your Number", phoneRef, setInvalidNumber)
            && validFormInput("Enter Your City", cityRef, setInvalidCity)){

            const OpenAccountInput = {
                openAccountRequest: {
                name: name,
                mobile_number: phoneNum,
                city: city,
                },
            };

            const result = await API.graphql({
                query: createOpenAccountRequest,
                variables: OpenAccountInput,
                authMode: "AMAZON_COGNITO_USER_POOLS",
            });
            
            // console.log(result);
            if(result.data.createOpenAccountRequest){
                setSuccessMessage("Your request has been saved successfully!");
                nameRef.current.value ="";
                phoneRef.current.value ="";
                cityRef.current.value ="";

            }else{
                // setErrorMessage("Please enter valid values.");
            }
            
            }else{
            // setErrorMessage("Please enter valid values.");
            }
        } catch (e) {
        if (e.code === "UsernameExistsException") {
            // setIsRegistered(true);
            // setContinueSignIn(true);
        } else {
            console.log(`OpenAccountError - ${e.message}`);
            setErrorMessage(
            e.code === "InvalidParameterException"
                ? e.message
                : `Something went wrong. Please try again later.`
            );
        }
        }
    };
   

  return (
    <>
     <div className="form-group mg-y-10">
          <div className="mg-y-20">
            <TextField
              autoComplete="off"
              //descriptiveText="Enter a valid first name"
              direction="column"
              errorMessage={invalidName}
              hasError={invalidName}
              inputMode="text"
              isDisabled={false}
              isReadOnly={false}
              isRequired={true}
              label="Enter Your Name"
              labelHidden={true}
              name="name"
              placeholder="Enter Your Name"
              type="text"
              wrap="nowrap"
              className="form-control"
              ref={nameRef}
              onInput={validFormInput.bind(
                null,
                "Enter Your Name",
                nameRef,
                setInvalidName
              )}
              autoFocus={true}
              textTransform="capitalize"
            />
          </div> 
          <div className="mg-y-20">
            <PhoneNumberField
            defaultCountryCode="+91"
            label="Phone number"
            labelHidden={true}
            // descriptiveText={"Enter your 10 digit phone number"}
            placeholder="8888888888"
            ref={phoneRef}
            dialCodeList={["+91"]}
            hasError={invalidNumber}
            errorMessage={invalidNumber}
            countryCodeRef={countryCodeRef} 
            onInput={validFormInput.bind(
              null,
              "Enter Your Number",
              phoneRef,
              setInvalidNumber
            )}
            minLength="10"
            maxLength="10"
            
          />
          </div> 
          <div className="mg-y-20">
          <TextField
            autoComplete="off"
            //descriptiveText="Enter a valid first name"
            direction="column"
            errorMessage={invalidCity}
            hasError={invalidCity}
            inputMode="text"
            isDisabled={false}
            isReadOnly={false}
            isRequired={true}
            label="City"
            labelHidden={true}
            name="city"
            placeholder="Enter your city"
            type="text"
            wrap="nowrap"
            className="form-control"
            ref={cityRef}
            onInput={validFormInput.bind(null, "City", cityRef, setInvalidCity)}
            textTransform="capitalize"
          />
          </div>
          <hr/>
          {successMessage && (
            <Alert isDismissible="true" variation="success" marginBottom="2rem">
              {successMessage}
            </Alert>
            ) 
          }
          {errorMessage && errorMessage && (
            <Alert
              variation="error"
              heading="Error"
              isDismissible="true"
            >
            {errorMessage}
            </Alert>
          
          )}
          <div className="mg-y-10 text-center">
            <button className="btn btn-primary btn btn-sm pd-x-15 btn-uppercase" onClick={handleSubmitAccountRequest}>
            Submit
            </button>
          </div>
        </div>     
    </>
  );
}

export default OpenDematAccountForm;
