import { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";

import {
    PhoneNumberField,
    Alert,
    TextField,
  } from "@aws-amplify/ui-react";

import { Auth } from "aws-amplify";
import { authActions } from "../../store/auth";
function EditProfile({ user }) {
    const dispatch = useDispatch();

    const [messgage, setmessgage] = useState(false);
    const [invalidFirstName, setInvalidFirstName] = useState(false);
    const [invalidLastName, setInvalidLastName] = useState(false);
    const [invalidEmail, setInvalidEmail] = useState(false);
    const [invalidCity, setInvalidCity] = useState(false);
    const firstNameRef = useRef(null);
    const lastNameRef = useRef(null);
    const emailRef = useRef(null);
    const cityRef = useRef(null);
    

    const handleUpdateProfile = async (e) => {
        e.preventDefault();
        const given_name = firstNameRef.current.value;
        const family_name = lastNameRef.current.value;
        const email = emailRef.current.value;
        const address = cityRef.current.value;
        const userAttributes = {
            email,
            given_name,
            family_name,
            address,
        };
        const user = await Auth.currentAuthenticatedUser();
        await Auth.updateUserAttributes(user,  userAttributes );
        const updateUser = await Auth.currentAuthenticatedUser();
        setmessgage("Profile updated successfully");
        dispatch(authActions.onLogin({ user: updateUser }));
    }
  return (
    <div className="row">
        <div className="card card-body mg-lg-x-200">
            <div className="pd-lg-x-20">
                
                <form
                method="get"
                action="/screen/query-result/"
                onSubmit={handleUpdateProfile}
                >
                    <h3>Edit Profile</h3>
                    {messgage ? (
                        <Alert isDismissible="true" variation="success" marginBottom="2rem">
                        {messgage}
                        </Alert>
                        ) : (
                            ""
                            )
                    }
                    <div className="row">
                        <div className="col-sm-12 form-field pd-y-10">
                            <TextField
                                autoComplete="off"
                                direction="column"
                                errorMessage={invalidFirstName ? "First name is required" : ""}
                                hasError={invalidFirstName}
                                inputMode="text"
                                isDisabled={false}
                                isReadOnly={false}
                                isRequired={true}
                                label="First Name"
                                labelHidden={false}
                                name="first_name"
                                defaultValue={`${user.attributes.given_name}`}
                                placeholder=""
                                type="text"
                                wrap="nowrap"
                                className="form-control"
                                ref={firstNameRef}
                                // onInput={validFormInput.bind(null, "City", cityRef, setInvalidCity)}
                                textTransform="capitalize"
                            />
                             
                        </div>
                        <div className="col-sm-12 form-field pd-y-10">
                            <TextField
                                autoComplete="off"
                                //descriptiveText="Enter a valid first name"
                                direction="column"
                                errorMessage={invalidLastName ? "Last name is required" : ""}
                                hasError={invalidLastName}
                                inputMode="text"
                                isDisabled={false}
                                isReadOnly={false}
                                isRequired={true}
                                label="Last Name"
                                labelHidden={false}
                                name="last_name"
                                defaultValue={`${user.attributes.family_name}`}
                                placeholder=""
                                type="text"
                                wrap="nowrap"
                                className="form-control"
                                ref={lastNameRef}
                                // onInput={validFormInput.bind(null, "City", cityRef, setInvalidCity)}
                                textTransform="capitalize"
                            />
                              
                        </div>
                        <div className="col-sm-12 form-field pd-y-10">
                            <div className="flex-row flex-space-between flex-gap-16">
                                <PhoneNumberField
                                    defaultCountryCode="+91"
                                    label="Phone number"
                                    placeholder="8888888888"
                                    dialCodeList={["+91"]}
                                    readOnly={true}
                                    minLength="10"
                                    maxLength="10"
                                    defaultValue={`${user.attributes.phone_number.replace("+91", '')}`}
                                />
                            </div>
                        </div>
                        <div className="col-sm-12 form-field pd-y-10">
                            <TextField
                                autoComplete="off"
                                //descriptiveText="Enter a valid first name"
                                direction="column"
                                errorMessage={invalidEmail ? "Email is required" : ""}
                                hasError={invalidEmail}
                                inputMode="text"
                                isDisabled={false}
                                isReadOnly={false}
                                isRequired={true}
                                label="Email"
                                labelHidden={false}
                                name="email"
                                defaultValue={`${user.attributes.email}`}
                                placeholder=""
                                type="text"
                                wrap="nowrap"
                                className="form-control"
                                ref={emailRef}
                                // onInput={validFormInput.bind(null, "City", cityRef, setInvalidCity)}
                            />
                        </div>
                        <div className="col-sm-12 form-field pd-y-10">
                            <TextField
                                autoComplete="off"
                                //descriptiveText="Enter a valid first name"
                                direction="column"
                                errorMessage={invalidCity ? "City is required" : ""}
                                hasError={invalidCity}
                                inputMode="text"
                                isDisabled={false}
                                isReadOnly={false}
                                isRequired={true}
                                label="City"
                                labelHidden={false}
                                name="city"
                                defaultValue={`${user.attributes.address}`}
                                placeholder=""
                                type="text"
                                wrap="nowrap"
                                className="form-control"
                                ref={cityRef}
                                // onInput={validFormInput.bind(null, "City", cityRef, setInvalidCity)}
                                textTransform="capitalize"
                            />
                        </div>
                        <div className="col-sm-12 form-field pd-lg-y-10">
                            <div className="flex-row flex-space-between flex-gap-16">
                                <button type="submit" className="btn btn-brand-02 btn-block primary-button">
                                    Update Profile
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
  );
}
export default EditProfile;
