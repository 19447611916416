import React from "react";
import { useSelector } from "react-redux";
import "./App.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import Header from "./components/HeaderNew";
import Footer from "./components/Footer";
import Dashboard from "./components/dashboard/Dashboard";
// import Home from "./components/Home";
import HomePage from "./components/HomePageNew";
import NewScreen from "./components/screen/New-screen";
import ScreenListView from "./components/screen/ScreenListView";
// import ScreenListView from "./components/screen/Screen-list-view";
import ScreenDelete from "./components/screen/ScreenDelete";
import QueryResultsListView from "./components/screen/QueryResultsListView.js";
// import QueryResultsListView from "./components/screen/Query-results-list-view.js";
import ScreenColumns from "./components/screen/Columns";
import SaveScreen from "./components/screen/Save-screen";
import Watchlists from "./components/watchlist/Watchlists";
import WatchlistAdd from "./components/watchlist/WatchlistAdd";
import WatchlistEdit from "./components/watchlist/WatchlistEdit";
import WatchlistDelete from "./components/watchlist/WatchlistDelete";
import WatchlistView from "./components/watchlist/WatchlistView";
import ManageCompnies from "./components/watchlist/ManageCompnies";

import EditProfile from "./components/authentication/EditProfile";
import ScrollToTop from "./components/ScrollToTop";

/* Admin pages */
import DematReport from "./components/admin/DematReport";
import UserReport from "./components/admin/UserReport";

import { Route, Routes, useLocation } from "react-router-dom";
import { Amplify } from "aws-amplify";
import { useAuth } from "./hooks/Authentication";

import myAppConfig from "./aws-exports";

myAppConfig.aws_appsync_graphqlEndpoint =
  "https://k2jnjpscyzhd7dazg455xgp3qa.appsync-api.ap-south-1.amazonaws.com/graphql";
Amplify.configure(myAppConfig);

function App() {
  useAuth();
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const location = useLocation();
  const redirectAuth = <> </>;
  // <HomePage alert="Please login to continue" alert_heading="Login Required" />
  return (
    <div className="App">
      <Header alert="Please login to continue" alert_heading="Login Required" />
      <div
        className={
          location.pathname === "/"
            ? "content content-fixed d-none screener_main_layout"
            : "content content-fixed screener_main_layout"
        }
      >
        <div className="container">
          <ScrollToTop>
            <Routes>
              /* admin routes */
              {/* <Route path="/admin" element={<Admin />} /> */}
              <Route path="/admin/demat-report" element={<DematReport />} />
              <Route path="/admin/user-report" element={<UserReport />} />
              <Route path="/" element={<HomePage />} />
              <Route
                path="/edit-profile"
                element={
                  isAuthenticated ? <EditProfile user={user} /> : redirectAuth
                }
              />
              <Route
                path="/screens"
                element={isAuthenticated ? <Dashboard /> : redirectAuth}
              />
              <Route
                path="/screens/:messgage"
                element={isAuthenticated ? <Dashboard /> : redirectAuth}
              />
              <Route
                path="/screen/new"
                element={isAuthenticated ? <NewScreen /> : redirectAuth}
              />
              <Route
                path="/screen/view/:screen_id"
                element={isAuthenticated ? <ScreenListView /> : redirectAuth}
              />
              <Route
                path="/screen/view/:screen_id/:messgage"
                element={isAuthenticated ? <ScreenListView /> : redirectAuth}
              />
              <Route
                path="/screen/delete/:screen_id"
                element={isAuthenticated ? <ScreenDelete /> : redirectAuth}
              />
              <Route
                path="/screen/query-result"
                element={
                  isAuthenticated ? <QueryResultsListView /> : redirectAuth
                }
              />
              <Route
                path="/screen/save"
                element={isAuthenticated ? <SaveScreen /> : redirectAuth}
              />
              <Route
                path="/screen/columns"
                element={isAuthenticated ? <ScreenColumns /> : redirectAuth}
              />
              <Route
                path="/screen/columns/:screen_id"
                element={isAuthenticated ? <ScreenColumns /> : redirectAuth}
              />
              {/* watchlist */}
              <Route
                path="/watchlists"
                element={isAuthenticated ? <Watchlists /> : redirectAuth}
              />
              <Route
                path="/watchlists/:messgage"
                element={isAuthenticated ? <Watchlists /> : redirectAuth}
              />
              <Route
                path="/watchlist/:wl_id"
                element={isAuthenticated ? <WatchlistView /> : redirectAuth}
              />
              <Route
                path="/watchlist/:wl_id/:messgage"
                element={isAuthenticated ? <WatchlistView /> : redirectAuth}
              />
              <Route
                path="/watchlist/manage/:wl_id"
                element={isAuthenticated ? <ManageCompnies /> : redirectAuth}
              />
              <Route
                path="/watchlist/edit/:wl_id"
                element={isAuthenticated ? <WatchlistEdit /> : redirectAuth}
              />
              <Route
                path="/watchlist/delete/:wl_id"
                element={isAuthenticated ? <WatchlistDelete /> : redirectAuth}
              />
              <Route
                path="/watchlist/add"
                element={isAuthenticated ? <WatchlistAdd /> : redirectAuth}
              />
            </Routes>
          </ScrollToTop>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default App;
