import React from 'react';
import {Loader} from "@aws-amplify/ui-react";

function ExportCSV (tableData){
    const [exportButtonText, setSetExportButtonText] = React.useState( 'EXPORT');
    const [isLoading, setIsLoading] = React.useState( false);

    const Export = ({ onExport }) => <button className="btn btn-white pd-x-20 btn-uppercase mg-x-10 mb-2 export-icon" size="small" onClick={e => onExport(e.target.value)}> { ( isLoading ) ? <Loader /> : '' }  { exportButtonText } </button>;

    // const columns = tableData.columns;
    const data = tableData.data;
 
    /**
     * https://codepen.io/Jacqueline34/pen/pyVoWr
     * @param {*} array 
     * @returns string
     */
    function convertArrayOfObjectsToCSV(array) {
        let result;

        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        const keys = Object.keys(data[0]);

        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;

        array.forEach(item => {
            let ctr = 0;
            keys.forEach(key => {
                if (ctr > 0) result += columnDelimiter;

                result += item[key];
                
                ctr++;
            });
            result += lineDelimiter;
        });

        return result;
    }
 
    /**
     * from https://codepen.io/Jacqueline34/pen/pyVoWr
     * @param {*} data
     * @returns string
     * @memberof ExportCSV
     * @description This function is used to convert the data to CSV
     * @author Pavan Ji <dropmail2pavan@gmail.com>
     * @date 2020-01-01
     * @version 1.0
     * @since 1.0
     * @private
     * @example
     * const data = [
     *    {
     *       "id": "1",
     *      "name": "John",
     *     "age": "30"
     *   },
     *  {
     *      "id": "2",
     *     "name": "Jane",
     *   "age": "25"
     * }
     * ];
     */ 
    function downloadCSV(array) {
        setIsLoading(true);
        // setSetExportButtonText('EXPORTING...');
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(array);
        if (csv == null){
            setTimeout(() => {
                setIsLoading(false);
                setSetExportButtonText('EXPORT');
            }, 1000);
            return;
        } 

        const filename = 'screen-search-report.csv';

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }

        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
        setTimeout(() => {
            setIsLoading(false);
            setSetExportButtonText('EXPORT');
        }, 1000);
    }

    // const IconSave = () => {
    //     return (
    //         <Icon
    //             ariaLabel=""
    //             pathData="M17 3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.89 21 5 21H19C20.1 21 21 20.1 21 19V7L17 3ZM19 19H5V5H16.17L19 7.83V19ZM12 12C10.34 12 9 13.34 9 15C9 16.66 10.34 18 12 18C13.66 18 15 16.66 15 15C15 13.34 13.66 12 12 12ZM6 6H15V10H6V6Z"
    //         />
    //     );
    // };
    return (
        <Export onExport={() => downloadCSV(data)} />
    );
};

export default ExportCSV;