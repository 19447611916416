import { useDispatch } from "react-redux";
import { authActions } from "../store/auth";
import { Auth } from "aws-amplify";
import { useEffect } from "react";

export const useAuth = () => {
  const dispatch = useDispatch();
  const checkCurrentUser = async () => {
    try {
      await Auth.currentAuthenticatedUser();
      const user = await Auth.currentUserInfo();
      dispatch(authActions.onLogin({ user }));
    } catch (e) {
      dispatch(authActions.onLogout());
      console.log(e);
    }
  };
  useEffect(() => {
    checkCurrentUser();
  }, []);
};
