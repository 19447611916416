import React from "react";
import '../styles/pages/home_page.scss';

function HomePage({ alert, alert_heading }) {

  return (
    <></>
  );
}

export default HomePage;
