import React from "react";
import { Link as ScreensLink } from "react-router-dom";

function CategoryStockScreens(CategoryStockScreensObj) {
  const screensCategory = {
    name: CategoryStockScreensObj.name,
    description: CategoryStockScreensObj.description,
  };
  const screens = CategoryStockScreensObj.screens;
  return (
    <div className="card mg-b-30">
      <div className="card-header pd-t-20  align-items-start justify-content-between bd-b-0 pd-b-0">
        <h4>{screensCategory.name}</h4>
        <p>{screensCategory.description}</p>
      </div>
      <div className="row pd-x-20">
        {screens.map((screen, index) => {
          return (
            <div className="col-sm-6 col-lg-4 pd-b-20" key={index}>
              <div className="card card-body">
                <ScreensLink
                  to={"/screen/view/" + screen.screen_id}
                  params={{ screen_id: screen.screen_id }}
                  className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-bold mg-b-8"
                >
                  {`${screen.name.substring(0, 20)}`}
                  <i className="icon-right a smaller"></i>
                </ScreensLink>
                <div className="d-flex d-lg-block d-xl-flex align-items-end">
                  <p className="tx-13 tx-color-03 mg-b-0">{`${screen.description.substring(
                    0,
                    50
                  )}`}</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
export default CategoryStockScreens;
