import React from 'react';
import {Loader} from "@aws-amplify/ui-react";

function DownloadDematReportCsv (tableData){
    const [exportButtonText, setSetExportButtonText] = React.useState( 'EXPORT');
    const [isLoading, setIsLoading] = React.useState( false);

    const Export = ({ onExport }) => <button className="btn btn-white pd-x-20 btn-uppercase mg-x-10 mb-2 export-icon" size="small" onClick={e => onExport(e.target.value)}> { ( isLoading ) ? <Loader /> : '' }  { exportButtonText } </button>;

    // const columns = tableData.columns;
    const data = tableData.data;
 
    /**
     * https://codepen.io/Jacqueline34/pen/pyVoWr
     * @param {*} array 
     * @returns string
     */
    function convertArrayOfObjectsToCSV(array) {
        let result;

        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        const keys = Object.keys(data[0]);

        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;

        array.forEach(item => {
            let ctr = 0;
            keys.forEach(key => {
                if (ctr > 0) result += columnDelimiter;

                result += item[key];
                
                ctr++;
            });
            result += lineDelimiter;
        });

        return result;
    }
 
    /**
     * from https://codepen.io/Jacqueline34/pen/pyVoWr
     * @param {*} data
     * @returns string
     * @memberof ExportCSV
     * @description This function is used to convert the data to CSV
     * @author Pavan Ji <dropmail2pavan@gmail.com>
     * @date 2020-01-01
     * @version 1.0
     * @since 1.0
     * @private
     * @example
     * const data = [
     *    {
     *       "id": "1",
     *      "name": "John",
     *     "age": "30"
     *   },
     *  {
     *      "id": "2",
     *     "name": "Jane",
     *   "age": "25"
     * }
     * ];
     */ 
    function downloadCSV(array) {
        setIsLoading(true);
        // setSetExportButtonText('EXPORTING...');
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(array);
        if (csv == null){
            setTimeout(() => {
                setIsLoading(false);
                setSetExportButtonText('EXPORT');
            }, 1000);
            return;
        } 

        const filename = 'demat-report.csv';

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }

        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
        setTimeout(() => {
            setIsLoading(false);
            setSetExportButtonText('EXPORT');
        }, 1000);
    }
    return (
        <Export onExport={() => downloadCSV(data)} />
    );
};

export default DownloadDematReportCsv;