/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createWatchlist = /* GraphQL */ `
  mutation CreateWatchlist($watchlist: CreateWatchlistInput!) {
    createWatchlist(watchlist: $watchlist) {
      wl_id
      name
      description
      owner
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;
export const updateWatchlist = /* GraphQL */ `
  mutation UpdateWatchlist($watchlist: UpdateWatchlistInput!) {
    updateWatchlist(watchlist: $watchlist) {
      wl_id
      name
      description
      owner
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;
export const deleteWatchlist = /* GraphQL */ `
  mutation DeleteWatchlist($wl_id: ID!) {
    deleteWatchlist(wl_id: $wl_id)
  }
`;
export const addWatchlistCompany = /* GraphQL */ `
  mutation AddWatchlistCompany(
    $watchlistCompanyInput: CreateDeleteWatchlistCompanyInput!
  ) {
    addWatchlistCompany(watchlistCompanyInput: $watchlistCompanyInput) {
      wl_id
      FINCODE
      createdBy
      createdAt
      COMPNAME
      SYMBOL
      SERIES
      bse_open
      bse_high
      bse_low
      bse_close
      nse_open
      nse_high
      nse_low
      nse_close
    }
  }
`;
export const deleteWatchlistCompany = /* GraphQL */ `
  mutation DeleteWatchlistCompany(
    $watchlistCompanyInput: CreateDeleteWatchlistCompanyInput!
  ) {
    deleteWatchlistCompany(watchlistCompanyInput: $watchlistCompanyInput) {
      wl_id
      FINCODE
      createdBy
      createdAt
      COMPNAME
      SYMBOL
      SERIES
      bse_open
      bse_high
      bse_low
      bse_close
      nse_open
      nse_high
      nse_low
      nse_close
    }
  }
`;
export const createScreenCategory = /* GraphQL */ `
  mutation CreateScreenCategory($screenCategory: CreateScreenCategoryInput!) {
    createScreenCategory(screenCategory: $screenCategory) {
      sc_id
      name
      description
      status
      access
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;
export const updateScreenCategory = /* GraphQL */ `
  mutation UpdateScreenCategory($screenCategory: UpdateScreenCategoryInput!) {
    updateScreenCategory(screenCategory: $screenCategory) {
      sc_id
      name
      description
      status
      access
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;
export const deleteScreenCategory = /* GraphQL */ `
  mutation DeleteScreenCategory($sc_id: ID!) {
    deleteScreenCategory(sc_id: $sc_id)
  }
`;
export const createScreen = /* GraphQL */ `
  mutation CreateScreen($screen: CreateScreenInput!) {
    createScreen(screen: $screen) {
      screen_id
      sc_id
      name
      description
      status
      access
      columns
      query
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;
export const updateScreen = /* GraphQL */ `
  mutation UpdateScreen($screen: UpdateScreenInput!) {
    updateScreen(screen: $screen) {
      screen_id
      sc_id
      name
      description
      status
      access
      columns
      query
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;
export const deleteScreen = /* GraphQL */ `
  mutation DeleteScreen($screen_id: ID!) {
    deleteScreen(screen_id: $screen_id)
  }
`;
export const setUserDefaultColumns = /* GraphQL */ `
  mutation SetUserDefaultColumns($user_id: String!, $edit_columns: String!) {
    setUserDefaultColumns(user_id: $user_id, edit_columns: $edit_columns) {
      items {
        name
        destination_column
      }
    }
  }
`;
export const createOpenAccountRequest = /* GraphQL */ `
  mutation CreateOpenAccountRequest($openAccountRequest: OpenAccountInput!) {
    createOpenAccountRequest(openAccountRequest: $openAccountRequest) {
      request_id
      service_name
      name
      mobile_number
      city
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;
