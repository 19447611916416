import React, { useEffect, useState } from "react";
// import { View,Link,Divider,Menu,MenuItem,Heading,Card,Flex,Button } from '@aws-amplify/ui-react';
import { useNavigate } from "react-router-dom";
import { Alert } from "@aws-amplify/ui-react";
import { API } from "aws-amplify";
import { createScreen, updateScreen } from "../../graphql/mutations";
import { getScreenById } from "../../graphql/queries";

function SaveScreen() {
  const navigate = useNavigate();

  const [name, setName] = useState([""]);
  const [description, setDescription] = useState("");
  const [isUserCreatedBy, setIsUserCreatedBy] = useState(false);
  const [nameError, setNameErrors] = useState(false);
  const [descriptionError, setDescriptionErrors] = useState(false);

  const queryParams = new URLSearchParams(window.location.search);

  const [query] = useState(queryParams.get("query"));
  const [source] = useState(queryParams.get("source"));
  const [screen_id] = useState(queryParams.get("source"));

  const [formAction, setAction] = useState("save");
  const [errorMessage, setErrorMessage] = useState(false);

  useEffect(() => {
    if (source) {
      console.log(`Source - ${source}`);
      async function fetchScreen() {
        const result = await API.graphql({
          query: getScreenById,
          variables: { screen_id },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });
        if (result.data.getScreenById) {
          setName(result.data.getScreenById.name);
          setDescription(result.data.getScreenById.description);
          if(result.data.getScreenById.createdBy !== 'system' ){
            setIsUserCreatedBy(true);
          }
        }
      }
      fetchScreen();
    }
  }, [screen_id, source]);
  let handleSaveScreen = async (e) => {
    e.preventDefault();
    var er = 0;
    if (name === "") {
      er = 1;
      setNameErrors("Please enter screen name!");
    }
    if (description === "") {
      er = 2;
      setDescriptionErrors("Please enter screen description!");
    }
    if (er) {
      return false;
    }
    if (formAction === "update") {
      const UpdateScreenInput = {
        screen: {
          screen_id: source,
          name: name,
          description: description,
          query: query,
        },
      };
      try {
        const result = await API.graphql({
          query: updateScreen,
          variables: UpdateScreenInput,
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });
        // console.log(result);
        if (result.data.updateScreen) {
          navigate(`/screen/view/${source}/Screen has been updated successfully.`);
        } else {
          setErrorMessage(
            e.code === "InvalidParameterException"
              ? e.message
              : `Something went wrong. Please try again later.`
          );
        }
      } catch (err) {
        console.log(err);
        setErrorMessage(
          e.code === "InvalidParameterException"
            ? e.message
            : `Something went wrong. Please try again later.`
        );
      }
    } else {
      const CreateScreenInput = {
        screen: {
          sc_id: "",
          name: name,
          description: description,
          query: query,
        },
      };
      try {
        const result = await API.graphql({
          query: createScreen,
          variables: CreateScreenInput,
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });
        if (result.data.createScreen) {
          navigate(`/screens/Screen has been saved successfully.`);
        } else {
          setErrorMessage(
            e.code === "InvalidParameterException"
              ? e.message
              : `Something went wrong. Please try again later.`
          );
        }
      } catch (e) {
        console.log(e);
        setErrorMessage(
          e.code === "InvalidParameterException"
            ? e.message
            : `Something went wrong. Please try again later.`
        );
      }
    }
    return false;
  };

  return (
    <div className="row">
      <div className="card card-body mg-lg-x-200">
        <div className="pd-lg-x-20">
          {errorMessage && (
            <Alert
              variation="error"
              isDismissible={false}
              hasIcon={true}
              heading="Error"
            >
              {errorMessage}
            </Alert>
          )}
          <form
            method="get"
            action="/screen/query-result/"
            onSubmit={handleSaveScreen}
          >
            <h3>Save query</h3>
            <input type="hidden" name="sort" id="id_sort" />

            <input type="hidden" name="order" id="id_order" />

            <input type="hidden" name="source" id="id_source" />
            <div className="row">
              <div className="col-sm-12 form-field pd-y-10">
                <label htmlFor="query-builder"> Name </label>
                <div className="flex-row flex-space-between flex-gap-16">
                  <input
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    type="text"
                    className="form-control"
                    name="name"
                    placeholder="eg. The Cash Bargins"
                    maxLength="50"
                    id="id_name"
                  />
                  {nameError ? (
                    <div
                      className="alert alert-danger alert-dismissible fade show mg-y-5"
                      role="alert"
                    >
                      {nameError}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="row tx-14">
              <div className="col-sm-12  form-field  pd-lg-y-10">
                <label htmlFor="query-builder"> Description </label>
                <div className="flex-row flex-space-between flex-gap-16">
                  <textarea
                    required
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className="form-control"
                    rows="3"
                    placeholder="eg. Companies with over 20% growth"
                    name="query"
                  ></textarea>
                  {descriptionError ? (
                    <div
                      className="alert alert-danger alert-dismissible fade show mg-y-5"
                      role="alert"
                    >
                      {descriptionError}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="form-field  pd-y-10">
              <p>
                <strong>Query:</strong> <span className="sub">{query}</span>
              </p>
            </div>
            <div className="flex-row flex-space-between flex-gap-16">
              <div className="d-md-block">
                {source ? (
                  <div>
                    {isUserCreatedBy ? (
                      <div>
                        <button
                            onClick={setAction.bind(this, "update")}
                            className="btn btn-primary pd-x-20  btn-uppercase"
                            type="submit"
                          >
                            <i className="icon-beaker"></i>
                            Save Changes
                          </button>
                          <button
                            onClick={setAction.bind(this, "save")}
                            className="btn btn-white pd-x-20  btn-uppercase mg-x-20"
                            type="submit"
                          >
                            <i className="icon-beaker"></i>
                            Save As New Screen
                          </button>
                        
                      </div>
                      ) : (
                        <div>
                          <button
                        onClick={setAction.bind(this, "save")}
                        className="btn btn-white pd-x-20  btn-uppercase"
                        type="submit"
                        >
                        <i className="icon-beaker"></i>
                          Save As New Screen
                        </button>
                        </div>
                      ) }
                  </div>
                  
                ) : (
                  <button
                    onClick={setAction.bind(this, "save")}
                    className="btn btn-primary pd-x-20  btn-uppercase"
                    type="submit"
                  >
                    <i className="icon-beaker"></i>
                    Save Query
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
export default SaveScreen;
