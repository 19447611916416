import React from "react";
import '../styles/component/_footer.scss';

function Footer() {
  return (
    <footer className="footer">
    <div className="container">
      <div className="row footer_content">
        <div className="col-12 col-md-6 column">
            <h6>ABOUT US</h6>
            <p><a href="top10stockbroker.com">Top10stockbroker</a> & <a href="indianfranchisereview.com">Indianfranchisereview</a> are websites under Medmonx Enterprises Private Limited. We are certified stock broker review & comparison website working with multiple partners.</p>
        </div>

        <div className="col-12 col-md-3 column">
            <h6>CONTACT US</h6>
            <a href="mailto:info@top10stockbroker.com" title="info@top10stockbroker.com">info@top10stockbroker.com</a>
        </div>

        <div className="col-12 col-md-3 column">
           <span>© 2022 screener.top10stockbroker.com </span>
        </div>

      </div>
      </div>
    </footer>  
   );
}
export default Footer;