import { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import {
  PhoneNumberField,
  PasswordField,
  Alert,
  TextField,
  View,
  Flex,
} from "@aws-amplify/ui-react";
import { Auth } from "aws-amplify";
import { authActions } from "../../store/auth";

function SigninForm({ alert, alert_heading }) {
  const [continueSignIn, setContinueSignIn] = useState(false);
  const [sentOtp, setSentOtp] = useState(false);
  const [invalidNumber, setInvalidNumber] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isRegistered, setIsRegistered] = useState(true);
  const [cognitoUser, setCognitoUser] = useState(null);
  const [showAlert, setShowAlert] = useState(true);
  const [invalidFirstName, setInvalidFirstName] = useState(true);
  const [invalidLastName, setInvalidLastName] = useState(true);
  const [invalidEmail, setInvalidEmail] = useState(true);
  const [invalidCity, setInvalidCity] = useState(true);
  const phoneRef = useRef(null);
  const countryCodeRef = useRef(null);
  const otpRef = useRef(null);
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const emailRef = useRef(null);
  const cityRef = useRef(null);

  const dispatch = useDispatch();
  useEffect(() => {
    if (continueSignIn) {
      async function signIn() {
        try {
          const signedInUser = await Auth.signIn(phoneNumber);
          setCognitoUser(signedInUser);
          setSentOtp(true);
        } catch (e) {
          if (e.code === "UserNotFoundException") {
            setIsRegistered(false);
            setContinueSignIn(false);
            return;
          }
          setErrorMessage(e.message);
        }
      }
      signIn();
    }
  }, [continueSignIn]);

  const removeAlert = () => {
    setShowAlert(false);
  };

  /**
   * @function handleResetState - Reset the state of the form
   * @returns {void}
   * @memberof SigninForm
   */
  const resetState = () => {
    setContinueSignIn(false);
    setSentOtp(false);
    setErrorMessage(false);
    setPhoneNumber("");
    setIsRegistered(true);
    setCognitoUser(null);
  };

  function getRandomString(bytes) {
    const randomValues = new Uint8Array(bytes);
    window.crypto.getRandomValues(randomValues);
    return Array.from(randomValues).map(intToHex).join("");
  }

  function intToHex(nr) {
    return nr.toString(16).padStart(2, "0");
  }

  const validFormInput = (name, ref, callback) => {
    const value = ref.current.value;
    switch (name) {
      case "First Name":
      case "Last Name":
      case "City":
        if (value.length === 0) {
          callback(`${name} is required.`);
          return false;
        } else if (value.length < 2) {
          callback(`${name} should be atleast 2 characters`);
          return false;
        } else {
          callback(false);
          return true;
        }
      case "Email Id":
        const validRegex =
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!value.match(validRegex)) {
          callback("Please enter a valid email id");
          return false;
        } else {
          callback(false);
          return true;
        }
      default:
        setErrorMessage("Please enter a valid value.");
        return false;
    }
  };

  const sendOtp = async () => {
    setErrorMessage(false);
    setContinueSignIn(false);
    removeAlert();
    const countryCode = countryCodeRef.current.value;
    const phoneRefVal = phoneRef.current.value;
    const phoneNum = `${countryCode}${phoneRefVal}`;

    if (isNaN(phoneRefVal)) {
      setInvalidNumber("Not a valid number");
    } else if (phoneRefVal.length !== 10) {
      setInvalidNumber("Mobile number should be valid 10 digit number");
    } else {
      setInvalidNumber(false);
      setPhoneNumber(phoneNum);
      try {
        if (!isRegistered) {
          const email = emailRef.current.value;
          const given_name = firstNameRef.current.value;
          const family_name = lastNameRef.current.value;
          const address = cityRef.current.value;

          validFormInput("First Name", firstNameRef, setInvalidFirstName);
          validFormInput("Last Name", lastNameRef, setInvalidLastName);
          validFormInput("City", cityRef, setInvalidCity);
          validFormInput("Email Id", emailRef, setInvalidEmail);

          if (
            invalidFirstName ||
            invalidLastName ||
            invalidCity ||
            invalidEmail
          ) {
            return;
          }

          const userAttributes = {
            email,
            given_name,
            family_name,
            address,
          };
          await Auth.signUp({
            username: phoneNum,
            password: getRandomString(20),
            attributes: userAttributes,
          });
        }
        setContinueSignIn(true);
      } catch (e) {
        if (e.code === "UsernameExistsException") {
          setIsRegistered(true);
          setContinueSignIn(true);
        } else {
          console.log(`SignUpError - ${e.message}`);
          setErrorMessage(
            e.code === "InvalidParameterException"
              ? e.message
              : `Something went wrong. Please try again later.`
          );
        }
      }
    }
  };

  const verifyOtp = async () => {
    setErrorMessage(false);
    const enteredOtp = otpRef.current.value;
    if (enteredOtp.length !== 5) {
      setErrorMessage("OTP should be valid 5 digit number");
    } else {
      try {
        const signInUser = await Auth.sendCustomChallengeAnswer(
          cognitoUser,
          enteredOtp
        );
        if (!signInUser.signInUserSession) {
          setErrorMessage("Invalid OTP");
        } else {
          const user = await Auth.currentUserInfo();
          dispatch(authActions.onLogin({ user }));
        }
      } catch (e) {
        setSentOtp(false);
        setContinueSignIn(false);
        setIsRegistered(true);
        if (e.code === "UserLambdaValidationException") {
          setErrorMessage("Failed to verify OTP. Please try again.");
        } else {
          setErrorMessage("Something went wrong. Please try again later.");
        }
        console.log(`VerifyOtpError - ${e.message}`);
      }
    }
  };

  let heading = "Sign In";
  let sub_heading = "Welcome back! Please signin to continue.";
  if (!isRegistered) {
    heading = "Sign Up";
    sub_heading = "First time user. Please enter your details to signup.";
  }
  if (sentOtp) {
    heading = "Verify OTP";
    sub_heading = "Please enter the OTP sent to your mobile number.";
  }

  return (
    <>
      {/* {alert && showAlert && (
        <Alert
          variation="error"
          heading={alert_heading && alert_heading !== null ? alert_heading : ""}
          isDismissible="true"
        >
          {alert}
        </Alert>
      )} */}
      {/* <h3 className="tx-color-01 mg-b-5">{heading}</h3>
      <p className="tx-color-03 tx-16 mg-b-30">{sub_heading}</p> */}

      {errorMessage && (
        <Alert
          variation="error"
          isDismissible={false}
          hasIcon={true}
          heading="Error"
        >
          {errorMessage}
        </Alert>
      )}

      <PhoneNumberField
        defaultCountryCode="+91"
        label="Phone number"
        descriptiveText={!sentOtp ? "Enter your 10 digit phone number" : ""}
        placeholder="8888888888"
        ref={phoneRef}
        dialCodeList={["+91"]}
        hasError={invalidNumber}
        errorMessage={invalidNumber}
        countryCodeRef={countryCodeRef}
        readOnly={sentOtp}
        minLength="10"
        maxLength="10"
        autoFocus={true}
      />
      {!isRegistered && !continueSignIn && (
        <div className="form-group">
          <Flex
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
            alignContent="flex-start"
            wrap="nowrap"
            gap="1rem"
            padding="1.5rem 0 1.5rem 0"
          >
            <TextField
              autoComplete="off"
              //descriptiveText="Enter a valid first name"
              direction="column"
              errorMessage={invalidFirstName}
              hasError={invalidFirstName}
              inputMode="text"
              isDisabled={false}
              isReadOnly={false}
              isRequired={true}
              label="First name"
              labelHidden={false}
              name="first_name"
              placeholder=""
              type="text"
              wrap="nowrap"
              className="form-control"
              ref={firstNameRef}
              onInput={validFormInput.bind(
                null,
                "First Name",
                firstNameRef,
                setInvalidFirstName
              )}
              textTransform="capitalize"
            />

            <TextField
              autoComplete="off"
              //descriptiveText="Enter a valid last name"
              direction="column"
              errorMessage={invalidLastName}
              hasError={invalidLastName}
              inputMode="text"
              isDisabled={false}
              isReadOnly={false}
              isRequired={true}
              label="Last name"
              labelHidden={false}
              name="last_name"
              placeholder=""
              type="text"
              wrap="nowrap"
              className="form-control"
              ref={lastNameRef}
              onInput={validFormInput.bind(
                null,
                "Last Name",
                lastNameRef,
                setInvalidLastName
              )}
              textTransform="capitalize"
            />
          </Flex>
          <TextField
            autoComplete="off"
            //descriptiveText="Enter a valid first name"
            direction="column"
            errorMessage={invalidEmail}
            hasError={invalidEmail}
            inputMode="text"
            isDisabled={false}
            isReadOnly={false}
            isRequired={true}
            label="Email id"
            labelHidden={false}
            name="email"
            placeholder=""
            type="text"
            wrap="nowrap"
            className="form-control"
            ref={emailRef}
            onInput={validFormInput.bind(
              null,
              "Email Id",
              emailRef,
              setInvalidEmail
            )}
            padding="0 0 1.5rem 0"
            textTransform="lowercase"
          />
          <TextField
            autoComplete="off"
            //descriptiveText="Enter a valid first name"
            direction="column"
            errorMessage={invalidCity}
            hasError={invalidCity}
            inputMode="text"
            isDisabled={false}
            isReadOnly={false}
            isRequired={true}
            label="City"
            labelHidden={false}
            name="city"
            placeholder=""
            type="text"
            wrap="nowrap"
            className="form-control"
            ref={cityRef}
            onInput={validFormInput.bind(null, "City", cityRef, setInvalidCity)}
            textTransform="capitalize"
          />
        </div>
      )}
      <br />
      {sentOtp && (
        <PasswordField
          autoComplete="new-password"
          descriptiveText="Please enter OTP sent to your mobile number"
          label="OTP"
          name="otp"
          ref={otpRef}
          minLength="5"
          maxLength="5"
          placeholder="*****"
          autoFocus={true}
        />
      )}

      {!sentOtp && (
        <button className="btn btn-brand-02 btn-block primary-button" onClick={sendOtp}>
          {isRegistered ? "Send Otp" : "Sign Up"}
        </button>
      )}
      <br />
      {sentOtp && (
        <button className="btn btn-brand-02 btn-block primary-button" onClick={verifyOtp}>
          Sign In
        </button>
      )}
      {/* <div className="tx-13 mg-t-20 tx-center">
                  Don't have an account? <a href="/">Create an Account</a>
                </div> */}
    </>
  );
}

export default SigninForm;
