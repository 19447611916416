import React, { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  Tabs,
  TabItem,
  View,
  Heading,
  Flex,
  Alert,
} from "@aws-amplify/ui-react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { API } from "aws-amplify";
import {
  getUserDefaultColumns,
  listRatioGalleries,
} from "../../../graphql/queries";
import { setUserDefaultColumns } from "../../../graphql/mutations";
import '../../../styles/pages/manage_columns.scss';



function ScreenColumns() {
  const navigate = useNavigate();
  const [shortableColumn, setShortableColumn] = React.useState([]);
  const [updatedShortableColumnResults, setShortableColumnUpdatedResults] =
    React.useState([]);
  const [ratioMostUsed, setMostUsed] = React.useState([]);
  const [ratioIncomeStatement, setIncomeStatement] = React.useState([]);
  const [ratioFinancialRatios, setFinancialRatios] = React.useState([]);
  const [ratioBalanceSheet, setbalanceSheet] = React.useState([]);
  const [ratioCashFlow, setCashFlow] = React.useState([]);
  const [ratioQuarterlyResults, setQuarterlyResults] = React.useState([]);
  const [ratioPrice, setPrice] = React.useState([]);
  const [search, setSearch] = useState(false);
  const [messgage, setMessage] = React.useState(false);

  useEffect(() => {
    fetchRatioGalleries();
    fatchUserDefaultColumns();
  }, []);

  async function fetchRatioGalleries() {
    const result = await API.graphql({
      query: listRatioGalleries,
      variables: {
        limit: 20,
        nextToken: "",
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    if (result.data.listRatioGalleries) {
      setMostUsed(result.data.listRatioGalleries.items.most_used);
      setIncomeStatement(result.data.listRatioGalleries.items.income_statement);
      setFinancialRatios(result.data.listRatioGalleries.items.financial_ratios);
      setbalanceSheet(result.data.listRatioGalleries.items.balance_sheet);
      setCashFlow(result.data.listRatioGalleries.items.cash_flow);
      setQuarterlyResults(
        result.data.listRatioGalleries.items.quarterly_results
      );
      setPrice(result.data.listRatioGalleries.items.price);
      setShortableColumn(result.data.listRatioGalleries.items.default_column);
      // setLoadingState(true);
      setSearch("");
    }
  }

  async function fatchUserDefaultColumns() {
    const result = await API.graphql({
      query: getUserDefaultColumns,
      variables: { user_id: "pavan" },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    if (result.data.getUserDefaultColumns.items) {
      setShortableColumnUpdatedResults(result.data.getUserDefaultColumns.items);
    }
  }
  let onDragEnd = (result) => {
    if (result !== "undefined") {
      // dropped outside the list
      if (!result.destination) {
        return;
      } else {
        const updatedResults = reorder(
          updatedShortableColumnResults,
          result.source.index,
          result.destination.index
        );
        setShortableColumnUpdatedResults(updatedResults);
      }
    } else {
      return;
    }
  };

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const grid = 5;

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    padding: grid * 2,
    margin: `0 ${grid}px 0 0`,
    float: "left",
    // change background colour if dragging
    background: isDragging ? "none" : "none",
    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "none" : "none",
    display: "inline-block",
    padding: grid,
    width: "100%",
  });

  let handleDeleteColumn = (e) => {
    e.preventDefault();
    var target = e.target;
    let col_id = target.getAttribute("data-column-id");
    try {
      let shortableColumn1 = [];
      shortableColumn1 = updatedShortableColumnResults.filter(
        (item) => item.destination_column !== col_id
      );
      setShortableColumnUpdatedResults(shortableColumn1);
    } catch (err) {
      console.log(err);
    }
    return false;
  };
  let handleResetDefaultColumn = (e) => {
    e.preventDefault();
    setShortableColumnUpdatedResults(shortableColumn);
    return false;
  };

  let handleColumnChange = (e) => {
    var target = e.target;
    let col_destination = target.getAttribute("data-destination-column");
    let col_tab_cat = target.getAttribute("data-column-category");
    try {
      if (target.checked === true) {
        if (updatedShortableColumnResults.length > 16) {
          setMessage("Only 16 columns is allowed.");
          return false;
        }
        if (col_tab_cat === "most_used") {
          let matches = ratioMostUsed.filter(
            (v) =>
              v.destination_column.toLowerCase() ===
              col_destination.toLocaleLowerCase()
          );
          if (
            typeof matches !== "undefined" &&
            matches != null &&
            matches.length
          ) {
            setShortableColumnUpdatedResults([
              ...updatedShortableColumnResults,
              matches[0],
            ]);
          }
        }
        if (col_tab_cat === "income_statement") {
          let matches = ratioIncomeStatement.filter(
            (v) =>
              v.destination_column.toLowerCase() ===
              col_destination.toLocaleLowerCase()
          );
          if (
            typeof matches !== "undefined" &&
            matches != null &&
            matches.length
          ) {
            setShortableColumnUpdatedResults([
              ...updatedShortableColumnResults,
              matches[0],
            ]);
          }
        }
        if (col_tab_cat === "financial_ratios") {
          let matches = ratioFinancialRatios.filter(
            (v) =>
              v.destination_column.toLowerCase() ===
              col_destination.toLocaleLowerCase()
          );
          if (
            typeof matches !== "undefined" &&
            matches != null &&
            matches.length
          ) {
            setShortableColumnUpdatedResults([
              ...updatedShortableColumnResults,
              matches[0],
            ]);
          }
        }
        if (col_tab_cat === "balance_sheet") {
          let matches = ratioBalanceSheet.filter(
            (v) =>
              v.destination_column.toLowerCase() ===
              col_destination.toLocaleLowerCase()
          );
          if (
            typeof matches !== "undefined" &&
            matches != null &&
            matches.length
          ) {
            setShortableColumnUpdatedResults([
              ...updatedShortableColumnResults,
              matches[0],
            ]);
          }
        }
        if (col_tab_cat === "cash_flow") {
          let matches = ratioCashFlow.filter(
            (v) =>
              v.destination_column.toLowerCase() ===
              col_destination.toLocaleLowerCase()
          );
          if (
            typeof matches !== "undefined" &&
            matches != null &&
            matches.length
          ) {
            setShortableColumnUpdatedResults([
              ...updatedShortableColumnResults,
              matches[0],
            ]);
          }
        }
        if (col_tab_cat === "quarterly_results") {
          let matches = ratioQuarterlyResults.filter(
            (v) =>
              v.destination_column.toLowerCase() ===
              col_destination.toLocaleLowerCase()
          );
          if (
            typeof matches !== "undefined" &&
            matches != null &&
            matches.length
          ) {
            setShortableColumnUpdatedResults([
              ...updatedShortableColumnResults,
              matches[0],
            ]);
          }
        }
        if (col_tab_cat === "price") {
          let matches = ratioPrice.filter(
            (v) =>
              v.destination_column.toLowerCase() ===
              col_destination.toLocaleLowerCase()
          );
          if (
            typeof matches !== "undefined" &&
            matches != null &&
            matches.length
          ) {
            setShortableColumnUpdatedResults([
              ...updatedShortableColumnResults,
              matches[0],
            ]);
          }
        }
      } else {
        let shortableColumn1 = [];
        shortableColumn1 = updatedShortableColumnResults.filter(
          (item) => item.destination_column !== col_destination
        );
        setShortableColumnUpdatedResults(shortableColumn1);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const filteredMostUsedRatios = useMemo(() => {
    if (search) {
      return ratioMostUsed.filter(
        (item) =>
          item.name.toLowerCase().indexOf(search.toLocaleLowerCase()) > -1
      );
    }
    return ratioMostUsed;
  }, [search]);

  const filteredIncomeStatementRatios = useMemo(() => {
    if (search) {
      return ratioIncomeStatement.filter(
        (item) =>
          item.name.toLowerCase().indexOf(search.toLocaleLowerCase()) > -1
      );
    }
    return ratioIncomeStatement;
  }, [search]);
  const filteredFinancialRatios = useMemo(() => {
    if (search) {
      return ratioFinancialRatios.filter(
        (item) =>
          item.name.toLowerCase().indexOf(search.toLocaleLowerCase()) > -1
      );
    }
    return ratioFinancialRatios;
  }, [search]);
  const filteredBalanceSheetRatios = useMemo(() => {
    if (search) {
      return ratioBalanceSheet.filter(
        (item) =>
          item.name.toLowerCase().indexOf(search.toLocaleLowerCase()) > -1
      );
    }
    return ratioBalanceSheet;
  }, [search]);
  const filteredCashFlowRatios = useMemo(() => {
    if (search) {
      return ratioCashFlow.filter(
        (item) =>
          item.name.toLowerCase().indexOf(search.toLocaleLowerCase()) > -1
      );
    }
    return ratioCashFlow;
  }, [search]);
  const filteredQuarterlyResultsRatios = useMemo(() => {
    if (search) {
      return ratioQuarterlyResults.filter(
        (item) =>
          item.name.toLowerCase().indexOf(search.toLocaleLowerCase()) > -1
      );
    }
    return ratioQuarterlyResults;
  }, [search]);
  const filteredPriceRatios = useMemo(() => {
    if (search) {
      return ratioPrice.filter(
        (item) =>
          item.name.toLowerCase().indexOf(search.toLocaleLowerCase()) > -1
      );
    }
    return ratioPrice;
  }, [search]);

  // Select Default Columns
  const defaultColumnList = updatedShortableColumnResults.map(
    (column) => column.destination_column
  );

  let handleSaveDefaultColumn = async (e) => {
    e.preventDefault();
    var er = 0;
    if (updatedShortableColumnResults.length > 16) {
      setMessage("Only 16 columns is allowed.");
      return false;
    }
    let columns = "";
    updatedShortableColumnResults.forEach((comRow) => {
      columns += columns ? "," + comRow.name : comRow.name;
    });
    try {
      const updateColumnInput = {
        user_id: "pavan",
        edit_columns: columns,
      };
      const result = await API.graphql({
        query: setUserDefaultColumns,
        variables: updateColumnInput,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      if (result.data.setUserDefaultColumns.items) {
        navigate(-1);
      }
    } catch (err) {
      console.log(err);
    }
    return false;
  };
  return (
    <div className="row">
      <div className="card card-body pd-20 __manage_columns">
        <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
          <div>
            <h2 className="mg-b-0 tx-spacing--1">Manage columns</h2>
          </div>
        </div>
        {messgage && (
          <Alert
            variation="error"
            isDismissible={false}
            hasIcon={true}
            heading="Error"
          >
            {messgage}
          </Alert>
        )}

        <Flex>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable" direction="horizontal">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                  {...provided.droppableProps}
                >
                  {updatedShortableColumnResults.map((item, index) => (
                    <Draggable
                      key={item.destination_column}
                      draggableId={item.destination_column}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="column_btn_listing"
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          <div className="btn btn-sm btn-white">
                            <i className="fas fa-border-none"></i>
                            {item.name}
                            <button
                              type="button"
                              className="close mg-l-15"
                              aria-label="Close"
                            >
                              <span
                                aria-hidden="true"
                                onClick={handleDeleteColumn}
                                data-column-id={item.destination_column}
                              >
                                &times;
                              </span>
                            </button>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Flex>
        <div className="d-sm-flex align-items-center justify-content-between mg-y-20 mg-lg-b-25 mg-xl-b-30">
          <div className="d-md-block save_cancel">
            <button
              onClick={handleSaveDefaultColumn}
              className="btn btn-primary pd-x-20 btn-uppercase"
            >
              Save Columns
            </button>
            <button
              onClick={(e) => navigate(-1)}
              className="btn btn-outline-light pd-x-20 btn-uppercase mg-x-20"
            >
              Cancel
            </button>
          </div>
          <div className="d-md-block mg-sm-y-20 reset_button">
            <button
              onClick={handleResetDefaultColumn}
              className="btn btn-outline-danger pd-x-20 btn-uppercase"
            >
              Reset Default
            </button>
          </div>
        </div>

        <div className="">
          <View>
            <div className="d-none d-md-block mg-y-20">
              <Heading level={3}>Filter Ratios</Heading>
            </div>
            <Flex>
              <div className="d-md-block mg-b-20 search_field">
                <div className="search-form">
                  <input
                    onChange={(e) => setSearch(e.target.value)}
                    type="search"
                    className="form-control"
                    placeholder="eg.sales"
                  />
                  <button className="btn" type="button">
                    <i data-feather="search"></i>
                  </button>
                </div>
              </div>
            </Flex>
            
            <Flex>
            <div className="horizontal_tabs">
              <Tabs>
                <TabItem title="Most Used">
                  <div className="row row-xs mg-t-30">
                    <div className="col-sm-6 col-lg-6 mg-t-10 mg-sm-t-0">
                      <p>RECENT</p>
                      <div className="flex-row">
                        {filteredMostUsedRatios.length > 0 ? (
                          filteredMostUsedRatios.map((col, index) => {
                            if (col.sub_category === "Recent") {
                              return (
                                <div
                                  className=""
                                  key={"most_used_recent_" + index}
                                >
                                  <input
                                    onChange={handleColumnChange}
                                    defaultChecked={defaultColumnList.includes(
                                      col.destination_column
                                    )}
                                    data-destination-column={
                                      col.destination_column
                                    }
                                    data-column-index={index}
                                    data-column-category="most_used"
                                    type="checkbox"
                                    className=""
                                    id={"most_used_recent_" + index}
                                  />
                                  <label
                                    className="mg-x-10"
                                    htmlFor={"most_used_recent_" + index}
                                  >
                                    {col.name}
                                  </label>
                                </div>
                              );
                            } else {
                              return false;
                            }
                          })
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </div>

                    <div className="col-sm-6 col-lg-6 mg-t-10 mg-sm-t-0">
                      <p>HISTORICAL</p>
                      <div className="flex-row">
                        {filteredMostUsedRatios.length > 0 ? (
                          filteredMostUsedRatios.map((col, index) => {
                            if (col.sub_category === "Historical") {
                              return (
                                <div
                                  className=""
                                  key={"most_used__historical_" + index}
                                >
                                  <input
                                    onChange={handleColumnChange}
                                    defaultChecked={defaultColumnList.includes(
                                      col.destination_column
                                    )}
                                    data-destination-column={
                                      col.destination_column
                                    }
                                    data-column-index={index}
                                    data-column-category="most_used"
                                    type="checkbox"
                                    className=""
                                    id={"most_used_recent_" + index}
                                  />
                                  <label
                                    className="mg-x-10"
                                    htmlFor={"most_used_recent_" + index}
                                  >
                                    {col.name}
                                  </label>
                                </div>
                              );
                            } else {
                              return false;
                            }
                          })
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </div>
                  </div>
                </TabItem>
                <TabItem title="Income Statement">
                  <div className="row row-xs mg-t-30">
                    <div className="col-sm-6 col-lg-6 mg-t-10 mg-sm-t-0">
                      <p>RECENT</p>
                      <div className="flex-row">
                        {filteredIncomeStatementRatios.length > 0 ? (
                          filteredIncomeStatementRatios.map((col, index) => {
                            if (col.sub_category === "Recent") {
                              return (
                                <div
                                  className=""
                                  key={"income_stm_recent_" + index}
                                >
                                  <input
                                    onChange={handleColumnChange}
                                    defaultChecked={defaultColumnList.includes(
                                      col.destination_column
                                    )}
                                    data-destination-column={
                                      col.destination_column
                                    }
                                    data-column-index={index}
                                    data-column-category="income_statement"
                                    type="checkbox"
                                    className=""
                                    id={"income_stm_recent_" + index}
                                  />
                                  <label
                                    className="mg-x-10"
                                    htmlFor={"income_stm_recent_" + index}
                                  >
                                    {col.name}
                                  </label>
                                </div>
                              );
                            } else {
                              return false;
                            }
                          })
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </div>

                    <div className="col-sm-6 col-lg-6 mg-t-10 mg-sm-t-0">
                      <p>HISTORICAL</p>
                      <div className="flex-row">
                        {filteredIncomeStatementRatios.length > 0 ? (
                          filteredIncomeStatementRatios.map((col, index) => {
                            if (col.sub_category === "Historical") {
                              return (
                                <div
                                  className=""
                                  key={"income_stm_historical_" + index}
                                >
                                  <input
                                    onChange={handleColumnChange}
                                    defaultChecked={defaultColumnList.includes(
                                      col.destination_column
                                    )}
                                    data-destination-column={
                                      col.destination_column
                                    }
                                    data-column-index={index}
                                    data-column-category="income_statement"
                                    type="checkbox"
                                    className=""
                                    id={"income_stm_historical_" + index}
                                  />
                                  <label
                                    className="mg-x-10"
                                    htmlFor={"income_stm_historical_" + index}
                                  >
                                    {col.name}
                                  </label>
                                </div>
                              );
                            } else {
                              return false;
                            }
                          })
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </div>
                  </div>
                </TabItem>
                <TabItem title="Financial Ratios">
                  <div className="row row-xs mg-t-30">
                    <div className="col-sm-6 col-lg-6 mg-t-10 mg-sm-t-0">
                      <p>RECENT</p>
                      <div className="flex-row">
                        {filteredFinancialRatios.length > 0 ? (
                          filteredFinancialRatios.map((col, index) => {
                            if (col.sub_category === "Recent") {
                              return (
                                <div
                                  className=""
                                  key={"financial_ratios_recent_" + index}
                                >
                                  <input
                                    onChange={handleColumnChange}
                                    defaultChecked={defaultColumnList.includes(
                                      col.destination_column
                                    )}
                                    data-destination-column={
                                      col.destination_column
                                    }
                                    data-column-index={index}
                                    data-column-category="financial_ratios"
                                    type="checkbox"
                                    className=""
                                    id={"financial_ratios_recent_" + index}
                                  />
                                  <label
                                    className="mg-x-10"
                                    htmlFor={"financial_ratios_recent_" + index}
                                  >
                                    {col.name}
                                  </label>
                                </div>
                              );
                            } else {
                              return false;
                            }
                          })
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </div>

                    <div className="col-sm-6 col-lg-6 mg-t-10 mg-sm-t-0">
                      <p>HISTORICAL</p>
                      <div className="flex-row">
                        {filteredFinancialRatios.length > 0 ? (
                          filteredIncomeStatementRatios.map((col, index) => {
                            if (col.sub_category === "Historical") {
                              return (
                                <div
                                  className=""
                                  key={"financial_ratios_historical_" + index}
                                >
                                  <input
                                    onChange={handleColumnChange}
                                    defaultChecked={defaultColumnList.includes(
                                      col.destination_column
                                    )}
                                    data-destination-column={
                                      col.destination_column
                                    }
                                    data-column-index={index}
                                    data-column-category="financial_ratios"
                                    type="checkbox"
                                    className=""
                                    id={"financial_ratios_historical_" + index}
                                  />
                                  <label
                                    className="mg-x-10"
                                    htmlFor={
                                      "financial_ratios_historical_" + index
                                    }
                                  >
                                    {col.name}
                                  </label>
                                </div>
                              );
                            } else {
                              return false;
                            }
                          })
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </div>
                  </div>
                </TabItem>
                <TabItem title="Balance Sheet">
                  <div className="row row-xs mg-t-30">
                    <div className="col-sm-6 col-lg-6 mg-t-10 mg-sm-t-0">
                      <p>RECENT</p>
                      <div className="flex-row">
                        {filteredBalanceSheetRatios.length > 0 ? (
                          filteredBalanceSheetRatios.map((col, index) => {
                            if (col.sub_category === "Recent") {
                              return (
                                <div
                                  className=""
                                  key={"balance_sheet_recent_" + index}
                                >
                                  <input
                                    onChange={handleColumnChange}
                                    defaultChecked={defaultColumnList.includes(
                                      col.destination_column
                                    )}
                                    data-destination-column={
                                      col.destination_column
                                    }
                                    data-column-index={index}
                                    data-column-category="balance_sheet"
                                    type="checkbox"
                                    className=""
                                    id={"balance_sheet_recent_" + index}
                                  />
                                  <label
                                    className="mg-x-10"
                                    htmlFor={"balance_sheet_recent_" + index}
                                  >
                                    {col.name}
                                  </label>
                                </div>
                              );
                            } else {
                              return false;
                            }
                          })
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </div>

                    <div className="col-sm-6 col-lg-6 mg-t-10 mg-sm-t-0">
                      <p>HISTORICAL</p>
                      <div className="flex-row">
                        {filteredBalanceSheetRatios.length > 0 ? (
                          filteredBalanceSheetRatios.map((col, index) => {
                            if (col.sub_category === "Historical") {
                              return (
                                <div
                                  className=""
                                  key={"balance_sheet_historical_" + index}
                                >
                                  <input
                                    onChange={handleColumnChange}
                                    defaultChecked={defaultColumnList.includes(
                                      col.destination_column
                                    )}
                                    data-destination-column={
                                      col.destination_column
                                    }
                                    data-column-index={index}
                                    data-column-category="balance_sheet"
                                    type="checkbox"
                                    className=""
                                    id={"balance_sheet_historical_" + index}
                                  />
                                  <label
                                    className="mg-x-10"
                                    htmlFor={"balance_sheet_historical_" + index}
                                  >
                                    {col.name}
                                  </label>
                                </div>
                              );
                            } else {
                              return false;
                            }
                          })
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </div>
                  </div>
                </TabItem>
                <TabItem title="Cash Flow">
                  <div className="row row-xs mg-t-30">
                    <div className="col-sm-6 col-lg-6 mg-t-10 mg-sm-t-0">
                      <p>RECENT</p>
                      <div className="flex-row">
                        {filteredCashFlowRatios.length > 0 ? (
                          filteredCashFlowRatios.map((col, index) => {
                            if (col.sub_category === "Recent") {
                              return (
                                <div
                                  className=""
                                  key={"cash_flow_recent_" + index}
                                >
                                  <input
                                    onChange={handleColumnChange}
                                    defaultChecked={defaultColumnList.includes(
                                      col.destination_column
                                    )}
                                    data-destination-column={
                                      col.destination_column
                                    }
                                    data-column-index={index}
                                    data-column-category="cash_flow"
                                    type="checkbox"
                                    className=""
                                    id={"cash_flow_recent_" + index}
                                  />
                                  <label
                                    className="mg-x-10"
                                    htmlFor={"cash_flow_recent_" + index}
                                  >
                                    {col.name}
                                  </label>
                                </div>
                              );
                            } else {
                              return false;
                            }
                          })
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </div>

                    <div className="col-sm-6 col-lg-6 mg-t-10 mg-sm-t-0">
                      <p>HISTORICAL</p>
                      <div className="flex-row">
                        {filteredCashFlowRatios.length > 0 ? (
                          filteredCashFlowRatios.map((col, index) => {
                            if (col.sub_category === "Historical") {
                              return (
                                <div
                                  className=""
                                  key={"cash_flow_historical_" + index}
                                >
                                  <input
                                    onChange={handleColumnChange}
                                    defaultChecked={defaultColumnList.includes(
                                      col.destination_column
                                    )}
                                    data-destination-column={
                                      col.destination_column
                                    }
                                    data-column-index={index}
                                    data-column-category="cash_flow"
                                    type="checkbox"
                                    className=""
                                    id={"cash_flow_historical_" + index}
                                  />
                                  <label
                                    className="mg-x-10"
                                    htmlFor={"cash_flow_historical_" + index}
                                  >
                                    {col.name}
                                  </label>
                                </div>
                              );
                            } else {
                              return false;
                            }
                          })
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </div>
                  </div>
                </TabItem>
                <TabItem title="Quarterly Results">
                  <div className="row row-xs mg-t-30">
                    <div className="col-sm-6 col-lg-6 mg-t-10 mg-sm-t-0">
                      <p>RECENT</p>
                      <div className="flex-row">
                        {filteredQuarterlyResultsRatios.length > 0 ? (
                          filteredQuarterlyResultsRatios.map((col, index) => {
                            if (col.sub_category === "Recent") {
                              return (
                                <div
                                  className=""
                                  key={"quarterly_results_recent_" + index}
                                >
                                  <input
                                    onChange={handleColumnChange}
                                    defaultChecked={defaultColumnList.includes(
                                      col.destination_column
                                    )}
                                    data-destination-column={
                                      col.destination_column
                                    }
                                    data-column-index={index}
                                    data-column-category="quarterly_results"
                                    type="checkbox"
                                    className=""
                                    id={"quarterly_results_recent_" + index}
                                  />
                                  <label
                                    className="mg-x-10"
                                    htmlFor={"quarterly_results_recent_" + index}
                                  >
                                    {col.name}
                                  </label>
                                </div>
                              );
                            } else {
                              return false;
                            }
                          })
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </div>

                    <div className="col-sm-6 col-lg-6 mg-t-10 mg-sm-t-0">
                      <p>HISTORICAL</p>
                      <div className="flex-row">
                        {filteredQuarterlyResultsRatios.length > 0 ? (
                          filteredQuarterlyResultsRatios.map((col, index) => {
                            if (col.sub_category === "Historical") {
                              return (
                                <div
                                  className=""
                                  key={"quarterly_results_historical_" + index}
                                >
                                  <input
                                    onChange={handleColumnChange}
                                    defaultChecked={defaultColumnList.includes(
                                      col.destination_column
                                    )}
                                    data-destination-column={
                                      col.destination_column
                                    }
                                    data-column-index={index}
                                    data-column-category="quarterly_results"
                                    type="checkbox"
                                    className=""
                                    id={"quarterly_results_historical_" + index}
                                  />
                                  <label
                                    className="mg-x-10"
                                    htmlFor={
                                      "quarterly_results_historical_" + index
                                    }
                                  >
                                    {col.name}
                                  </label>
                                </div>
                              );
                            } else {
                              return false;
                            }
                          })
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </div>
                  </div>
                </TabItem>
                <TabItem title="Price">
                  <div className="row row-xs mg-t-30">
                    <div className="col-sm-6 col-lg-6 mg-t-10 mg-sm-t-0">
                      <p>RECENT</p>
                      <div className="flex-row">
                        {filteredPriceRatios.length > 0 ? (
                          filteredPriceRatios.map((col, index) => {
                            if (col.sub_category === "Recent") {
                              return (
                                <div className="" key={"price_recent_" + index}>
                                  <input
                                    onChange={handleColumnChange}
                                    defaultChecked={defaultColumnList.includes(
                                      col.destination_column
                                    )}
                                    data-destination-column={
                                      col.destination_column
                                    }
                                    data-column-index={index}
                                    data-column-category="price"
                                    type="checkbox"
                                    className=""
                                    id={"price_recent_" + index}
                                  />
                                  <label
                                    className="mg-x-10"
                                    htmlFor={"price_recent_" + index}
                                  >
                                    {col.name}
                                  </label>
                                </div>
                              );
                            } else {
                              return false;
                            }
                          })
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </div>

                    <div className="col-sm-6 col-lg-6 mg-t-10 mg-sm-t-0">
                      <p>HISTORICAL</p>
                      <div className="flex-row">
                        {filteredPriceRatios.length > 0 ? (
                          filteredPriceRatios.map((col, index) => {
                            if (col.sub_category === "Historical") {
                              return (
                                <div
                                  className=""
                                  key={"price_historical_" + index}
                                >
                                  <input
                                    onChange={handleColumnChange}
                                    defaultChecked={defaultColumnList.includes(
                                      col.destination_column
                                    )}
                                    data-destination-column={
                                      col.destination_column
                                    }
                                    data-column-index={index}
                                    data-column-category="price"
                                    type="checkbox"
                                    className=""
                                    id={"price_historical_" + index}
                                  />
                                  <label
                                    className="mg-x-10"
                                    htmlFor={"price_historical_" + index}
                                  >
                                    {col.name}
                                  </label>
                                </div>
                              );
                            } else {
                              return false;
                            }
                          })
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </div>
                  </div>
                </TabItem>
              </Tabs>
              </div>
            </Flex>
          </View>
        </div>
      </div>
    </div>
  );
}
export default ScreenColumns;
