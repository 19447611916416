import React, { useEffect } from "react";

import { API } from "aws-amplify";
import { getScreenById } from "../../graphql/queries";
import { deleteScreen } from "../../graphql/mutations";

import { Link as ScreensLink, useParams, useNavigate } from "react-router-dom";

function ScreenDelete() {
  const navigate = useNavigate();
  const [screen, setScreen] = React.useState([]);

  const [screenName, setScreenName] = React.useState([]);
  const { screen_id } = useParams();

  useEffect(() => {
    async function fetchScreen() {
      const result = await API.graphql({
        query: getScreenById,
        variables: { screen_id },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      if (result.data.getScreenById) {
        setScreen(result.data.getScreenById);
      }
    }
    fetchScreen();
  }, []);
  let handleDeleteScreen = async (e) => {
    e.preventDefault();
    setScreenName(screen.name);
    try {
      await API.graphql({
        query: deleteScreen,
        variables: { screen_id },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      navigate(`/screens/Screen has been deleted successfully.`);
    } catch (err) {
      console.log(err);
    }
    return false;
  };

  return (
    <div className="row">
      <div className="card card-body mg-lg-x-100">
        <div className="pd-lg-x-20">
          <form method="get" action="/screen/query-result/">
            <h3>Delete {screen.name}?</h3>
            <input type="hidden" name="sort" id="id_sort" />
            <input
              type="hidden"
              name="screen_id"
              id="id_screen_id"
              value="{screen_id}"
            />
            <p className="tx-18">
              Are you sure you want to delete{" "}
              <ScreensLink
                to={"/screen/view/" + screen_id}
                params={{ screen_id: screen_id }}
                className=""
              >
                {screen.name}
              </ScreensLink>{" "}
              screen?
            </p>
            <div className="row tx-14"></div>

            <div className="flex-row flex-space-between flex-gap-16">
              <div className="d-md-block">
                <button
                  onClick={handleDeleteScreen}
                  className="btn btn-outline-danger pd-x-20 mg-r-20  btn-uppercase"
                  type="submit"
                >
                  <i className="icon-beaker"></i>
                  Confirm Delete
                </button>
                <ScreensLink
                  to={"/screen/view/" + screen_id}
                  params={{ screen_id: screen_id }}
                  className="btn pd-x-20 btn-white btn-uppercase"
                >
                  Cancel
                </ScreensLink>
              </div>
            </div>
          </form>
          <div className="flex-row float-right mg-y-20">
            <ScreensLink
              to={"/screen/view/" + screen_id}
              className="btn btn-primary pd-x-20  btn-uppercase"
            >
              ← Go Back
            </ScreensLink>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ScreenDelete;
