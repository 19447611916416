import React from "react";
import rightSection1, {rightSection2, rightSection3, rightSection4, rightSection5} from  "./right-section-data";
function RightSidebar() {
  return (
    <div className="card mg-b-10">
      <div className="card-widget card-contacts">
        <div className="card-header">
          <h6 className="card-title mg-b-0">Sectors or Industries</h6>
        </div> 
        <div className="card-body mg-20">
          <div className="flex-row">
            {rightSection1 && rightSection1.map((stock, index) => (
              <a key={index} href={stock.url} title={stock.name} className="btn btn-sm pd-x-15 btn-white btn-uppercase mg-5 mg-b-10">
                {stock.name}
              </a>  
            ))}
          </div> 
        </div> 
      </div>
      <hr/>
      <div className="card-widget card-contacts">
        <div className="card-header">
          <h6 className="card-title mg-b-0">Most Popular</h6>
        </div> 
        <div className="card-body mg-20">
          <div className="flex-row">
            {rightSection2 && rightSection2.map((stock, index) => (
              <a key={index} href={stock.url} title={stock.name} className="btn btn-sm pd-x-15 btn-white btn-uppercase mg-5 mg-b-10">
                {stock.name}
              </a>  
            ))}
          </div> 
        </div> 
      </div>
      <hr/>

      <div className="card-widget card-contacts">
        <div className="card-header">
          <h6 className="card-title mg-b-0">Live Markets</h6>
        </div> 
        <div className="card-body mg-20">
          <div className="flex-row">
            {rightSection3 && rightSection3.map((stock, index) => (
              <a key={index} href={stock.url} title={stock.name} className="btn btn-sm pd-x-15 btn-white btn-uppercase mg-5 mg-b-10">
                {stock.name}
              </a>  
            ))}
          </div> 
        </div> 
      </div>
      <hr/>

      <div className="card-widget card-contacts">
        <div className="card-header">
          <h6 className="card-title mg-b-0">Market Prediction</h6>
        </div> 
        <div className="card-body mg-20">
          <div className="flex-row">
            {rightSection4 && rightSection4.map((stock, index) => (
              <a key={index} href={stock.url} title={stock.name} className="btn btn-sm pd-x-15 btn-white btn-uppercase mg-5 mg-b-10">
                {stock.name}
              </a>  
            ))}
          </div> 
        </div> 
      </div>
      <hr/>

      <div className="card-widget card-contacts">
        <div className="card-header">
          <h6 className="card-title mg-b-0">Important Links</h6>
        </div> 
        <div className="card-body mg-20">
          <div className="flex-row">
            {rightSection5 && rightSection5.map((stock, index) => (
              <a key={index} href={stock.url} title={stock.name} className="btn btn-sm pd-x-15 btn-white btn-uppercase mg-5 mg-b-10">
                {stock.name}
              </a>  
            ))}
          </div> 
        </div> 
      </div>
    </div>
  );
}
export default RightSidebar;
