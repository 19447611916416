/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getWatchlistById = /* GraphQL */ `
  query GetWatchlistById($wl_id: ID!) {
    getWatchlistById(wl_id: $wl_id) {
      wl_id
      name
      description
      owner
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;
export const listWatchlists = /* GraphQL */ `
  query ListWatchlists($limit: Int, $nextToken: String) {
    listWatchlists(limit: $limit, nextToken: $nextToken) {
      items {
        wl_id
        name
        description
        owner
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getWatchlistCompanies = /* GraphQL */ `
  query GetWatchlistCompanies($wl_id: ID!, $limit: Int, $nextToken: String) {
    getWatchlistCompanies(wl_id: $wl_id, limit: $limit, nextToken: $nextToken) {
      items {
        wl_id
        FINCODE
        createdBy
        createdAt
        COMPNAME
        SYMBOL
        SERIES
        bse_open
        bse_high
        bse_low
        bse_close
        nse_open
        nse_high
        nse_low
        nse_close
      }
      nextToken
    }
  }
`;
export const getWatchlistCompaniesReport = /* GraphQL */ `
  query GetWatchlistCompaniesReport(
    $wl_id: ID!
    $industry: String
    $limit: Int
    $nextToken: String
  ) {
    getWatchlistCompaniesReport(
      wl_id: $wl_id
      industry: $industry
      limit: $limit
      nextToken: $nextToken
    ) {
      ratios {
        destination_column
        source_column
        source_table
        column_type
        name
        keyword
        calculation
        category
        screen_name
        unit
        description
        sub_category
        most_used
        default_column
        searchable
      }
      industry
      industry_rows {
        FINCODE
        COMPNAME
        S_NAME
        SCRIP_NAME
        SYMBOL
        Sector
        Industry
        company_count
      }
      nextToken
      table
    }
  }
`;
export const searchCompanyByName = /* GraphQL */ `
  query SearchCompanyByName($search_text: String) {
    searchCompanyByName(search_text: $search_text) {
      items {
        FINCODE
        COMPNAME
        S_NAME
        SCRIP_NAME
        SYMBOL
        Sector
        Industry
        company_count
      }
      nextToken
    }
  }
`;
export const listIndustries = /* GraphQL */ `
  query ListIndustries($search_text: String) {
    listIndustries(search_text: $search_text) {
      items {
        FINCODE
        COMPNAME
        S_NAME
        SCRIP_NAME
        SYMBOL
        Sector
        Industry
        company_count
      }
      nextToken
    }
  }
`;
export const getScreenCategoryById = /* GraphQL */ `
  query GetScreenCategoryById($sc_id: ID!) {
    getScreenCategoryById(sc_id: $sc_id) {
      sc_id
      name
      description
      status
      access
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;
export const listScreenCategory = /* GraphQL */ `
  query ListScreenCategory($limit: Int, $nextToken: String) {
    listScreenCategory(limit: $limit, nextToken: $nextToken) {
      items {
        sc_id
        name
        description
        status
        access
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserDefaultColumns = /* GraphQL */ `
  query GetUserDefaultColumns($user_id: String!) {
    getUserDefaultColumns(user_id: $user_id) {
      items {
        name
        destination_column
      }
    }
  }
`;
export const getScreenById = /* GraphQL */ `
  query GetScreenById($screen_id: ID!) {
    getScreenById(screen_id: $screen_id) {
      screen_id
      sc_id
      name
      description
      status
      access
      columns
      query
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;
export const listScreens = /* GraphQL */ `
  query ListScreens($limit: Int, $nextToken: String) {
    listScreens(limit: $limit, nextToken: $nextToken) {
      my_screens
      screens_by_category
    }
  }
`;
export const listScreensByCategoryId = /* GraphQL */ `
  query ListScreensByCategoryId($sc_id: ID!, $limit: Int, $nextToken: String) {
    listScreensByCategoryId(
      sc_id: $sc_id
      limit: $limit
      nextToken: $nextToken
    ) {
      category {
        sc_id
        name
        description
        status
        access
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
      items {
        screen_id
        sc_id
        name
        description
        status
        access
        columns
        query
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCategoryStockScreens = /* GraphQL */ `
  query GetCategoryStockScreens($limit: Int, $nextToken: String) {
    getCategoryStockScreens(limit: $limit, nextToken: $nextToken) {
      table
    }
  }
`;
export const listRatioGalleries = /* GraphQL */ `
  query ListRatioGalleries($limit: Int, $nextToken: String) {
    listRatioGalleries(limit: $limit, nextToken: $nextToken) {
      items {
        all_ratios {
          destination_column
          source_column
          source_table
          column_type
          name
          keyword
          calculation
          category
          screen_name
          unit
          description
          sub_category
          most_used
          default_column
          searchable
        }
        default_column {
          destination_column
          source_column
          source_table
          column_type
          name
          keyword
          calculation
          category
          screen_name
          unit
          description
          sub_category
          most_used
          default_column
          searchable
        }
        most_used {
          destination_column
          source_column
          source_table
          column_type
          name
          keyword
          calculation
          category
          screen_name
          unit
          description
          sub_category
          most_used
          default_column
          searchable
        }
        balance_sheet {
          destination_column
          source_column
          source_table
          column_type
          name
          keyword
          calculation
          category
          screen_name
          unit
          description
          sub_category
          most_used
          default_column
          searchable
        }
        cash_flow {
          destination_column
          source_column
          source_table
          column_type
          name
          keyword
          calculation
          category
          screen_name
          unit
          description
          sub_category
          most_used
          default_column
          searchable
        }
        financial_ratios {
          destination_column
          source_column
          source_table
          column_type
          name
          keyword
          calculation
          category
          screen_name
          unit
          description
          sub_category
          most_used
          default_column
          searchable
        }
        income_statement {
          destination_column
          source_column
          source_table
          column_type
          name
          keyword
          calculation
          category
          screen_name
          unit
          description
          sub_category
          most_used
          default_column
          searchable
        }
        quarterly_results {
          destination_column
          source_column
          source_table
          column_type
          name
          keyword
          calculation
          category
          screen_name
          unit
          description
          sub_category
          most_used
          default_column
          searchable
        }
        price {
          destination_column
          source_column
          source_table
          column_type
          name
          keyword
          calculation
          category
          screen_name
          unit
          description
          sub_category
          most_used
          default_column
          searchable
        }
      }
      nextToken
    }
  }
`;
export const listRatioAllOptions = /* GraphQL */ `
  query ListRatioAllOptions($limit: Int, $nextToken: String) {
    listRatioAllOptions(limit: $limit, nextToken: $nextToken) {
      items {
        destination_column
        source_column
        source_table
        column_type
        name
        keyword
        calculation
        category
        screen_name
        unit
        description
        sub_category
        most_used
        default_column
        searchable
      }
    }
  }
`;
export const listScreenReport = /* GraphQL */ `
  query ListScreenReport(
    $search_query: String!
    $screen_id: String
    $industry: String
    $limit: Int
    $nextToken: String
  ) {
    listScreenReport(
      search_query: $search_query
      screen_id: $screen_id
      industry: $industry
      limit: $limit
      nextToken: $nextToken
    ) {
      ratios {
        destination_column
        source_column
        source_table
        column_type
        name
        keyword
        calculation
        category
        screen_name
        unit
        description
        sub_category
        most_used
        default_column
        searchable
      }
      total_count
      industry
      industry_rows {
        FINCODE
        COMPNAME
        S_NAME
        SCRIP_NAME
        SYMBOL
        Sector
        Industry
        company_count
      }
      nextToken
      table
    }
  }
`;
export const listOpenAccountRequest = /* GraphQL */ `
  query ListOpenAccountRequest($limit: Int, $nextToken: String) {
    listOpenAccountRequest(limit: $limit, nextToken: $nextToken) {
      items {
        request_id
        service_name
        name
        mobile_number
        city
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers($limit: Int, $nextToken: String) {
    listUsers(limit: $limit, nextToken: $nextToken) {
      users {
        Username
        Attributes {
          Name
          Value
        }
        UserCreateDate
        UserLastModifiedDate
        Enabled
        UserStatus
      }
      nextToken
    }
  }
`;
