import React, { useEffect, useState, useMemo,useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { authActions } from "../store/auth";
import {Link as ScreensLink, useLocation } from "react-router-dom";
import { Auth,API } from "aws-amplify";
import Modal from 'react-modal';
import '../styles/component/_header.scss';
import { Icon , Button, SearchField } from "@aws-amplify/ui-react";

import SigninForm from "./authentication/SigninForm";
import ProfilePage from "./authentication/ProfilePage";
import trendingStocks,{topIndices,top10Stocks} from  "./home-page-data";

import OpenDematAccountForm from "./demat-account/OpenDematAccountForm";

import logo from ".././assets/images/screener.svg";
 
import $ from "jquery";

function Header({ alert, alert_heading }) {
    const location = useLocation();
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '40%',
    },
  };
  const [toggleProfile, setToggleProfile] = React.useState(false);
  const [searchCompnies, setSearchCompnies] = React.useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [search, setSearch] = useState(false);
  const dispatch = useDispatch();
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  // const [alert, setAlert] = useState(false);
  // const [showAlert, setShowAlert] = useState(true);
  
  
  const [mainMenuOpen, setMainMenuOpen] = useState(false);
  const [signInOpen, setSignInOpen] = useState(false);

  const profileDropDownElement = document.querySelector("#dropdown-profile");
  const mainMenuElement = document.querySelector("#navbarMenu");
  const companyDropdownContent = document.querySelector("#company-dropdown-content");
  const homeCompanyDropdownContent = document.querySelector("#home-company-dropdown-content");
  
  /**
   * @function Handle window click event close the dropdown menu or profile dropdown menu
   */
  document.addEventListener("mousedown", (e) => {
    /**
     * If the user clicks outside of the profile menu, close the profile menu
     */
    if(profileDropDownElement){
      if (!profileDropDownElement.contains(e.target)) {
        setToggleProfile(false);
      }
    }

    /**
     * If the user clicks outside of the main menu, close the main menu
     */
    if(mainMenuElement){
      if (!mainMenuElement.contains(e.target)) {
        setMainMenuOpen(false);
      }
    }

    /**
     * If the user clicks outside of the Company search menu, close the Company search menu
     */
     if(companyDropdownContent){
      if (!companyDropdownContent.contains(e.target)) {
        $('#company-dropdown-content').hasClass('visible') && $('#company-dropdown-content').removeClass('visible');
      }
    }

    /**
     * If the user clicks outside of the Home Company search menu, close the Home Company search menu
     */
     if(homeCompanyDropdownContent){
        if (!homeCompanyDropdownContent.contains(e.target)) {
          $('#home-company-dropdown-content').hasClass('visible') && $('#home-company-dropdown-content').removeClass('visible');
        }
      }

  });
  
  /**
   * Open Demot Account Form Model
   * @param {*} e 
   */
  const openModal = (e) => {
    e.preventDefault();
    setIsOpen(true);
    setSignInOpen(false);
  };

  /**
   * Close Demot Account Form Model
   * @param {*} e 
   */
  const closeModal = (e) => {
    e.preventDefault();
    setIsOpen(false);
  }
  
  useEffect(() => {
    fetchCpmpanyList();
  }, []);

    const fetchCpmpanyList = (e) => {
        fetch("https://api1.top10stockbroker.com/apiblock/company-list")
        .then((res) => res.json())
        .then(
          (result) => {
            setSearchCompnies(result);
            setIsLoaded(true);
          },
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        );
    }

  /**
   * Open Login Form id the user is not logged in and the user clicks on the sign in button
   * @param {object} e - The event object
   * @returns {void}
   * @memberof Header
   * @author Pavan Ji <dropmail2pavan@gmail.com>
   * @since 1.0.0
   * @version 1.0.0
   */
  const checkLoginState = (e) => {
    if (!isAuthenticated) {
      e.preventDefault();
      setSignInOpen(true);
    }
  }

  /**
   * Logout the user
   * @param {object} e - The event object
   * @returns {void}
   * @memberof Header
   * @author Amit Negi
   * @since 1.0.0
   * @version 1.0.0
   */
  const signOut = async () => {
    try {
      await Auth.signOut();
      dispatch(authActions.onLogout());
    } catch (e) {
      console.log(e);
    }
  };

  let handleToggleMenu = async (e) => {
    e.preventDefault();
    if (toggleProfile) {
      setToggleProfile(false);
    } else {
      setToggleProfile(true);
    }
    return false;
  };
  
  let handleCompanyDetailPage = async (e) => {
    e.preventDefault();
    setSearch("");
    document.getElementById("company-search").value = "";
    document.getElementById("home-company-search").value = "";
    var element = document
      .getElementById("company-dropdown-content")
      .classList.remove("visible");
    var element = document
      .getElementById("home-company-dropdown-content")
      .classList.remove("visible");
    if (e.target.getAttribute("href")) {
      window.open(e.target.getAttribute("href"), "_blank", "");
    }
    return false;
  };

  /**
   * Handler for the top search input field
   * @param {*} e 
   * @returns 
   */
  let handleSearchCompanies = async (e) => {
    e.preventDefault();
    if (e.target.value.length > 2) {
      setSearch(e.target.value);
      var element = document
        .getElementById("company-dropdown-content")
        .classList.add("visible");
    } else {
      var element = document
        .getElementById("company-dropdown-content")
        .classList.remove("visible");
    }

    return false;
  };

  /**
   * Handler for the Home search input field
   * @param {*} e 
   * @returns 
   */
  const handleHomeSearchCompanies = async (e) => {
    e.preventDefault();
    if (e.target.value.length > 2) {
      setSearch(e.target.value);
      var element = document
        .getElementById("home-company-dropdown-content")
        .classList.add("visible");
    } else {
      var element = document
        .getElementById("home-company-dropdown-content")
        .classList.remove("visible");
    }

    return false;
  };
  
  const handleMainMenuOpen = (e) => {
    e.preventDefault();
    setMainMenuOpen(true);
  }
  const handleMainMenuClose = (e) => {
    e.preventDefault();
    setMainMenuOpen(false);
  }
  const filteredCompniesList = useMemo(() => {
    if (search && search.length > 2) {
      return searchCompnies.filter(
        (item) =>
          item.company_name.toLowerCase().indexOf(search.toLocaleLowerCase()) >
          -1
      );
    }
    return [];
  }, [search]);
  
  /**
   * Open Sign In Form if the user is not logged in and the user clicks on the sign in button
   * @param {*} e 
   */
  const handleSignInSignUp = (e) => {
    e.preventDefault();
    setSignInOpen(true);
    setIsOpen(false);
  }

  /**
   * Close Sign In Form
   * @param {*} e 
   */
  const closeSignInModal = (e) => {
    e.preventDefault();
    setSignInOpen(false);
  } 

  /**
   * Component Render Method
   */ 
  return (
    <>
    <header className={"navbar navbar-header navbar-header-fixed "+((mainMenuOpen)? 'navbar-nav-show' :'' )}>
      <a href="" onClick={handleMainMenuOpen} id="mainMenuOpen" className="burger-menu">
        <Icon ariaLabel="Align bottom">
          <path d="M15 4H23V18H19V1Z" fill="currentColor"/>
          <path d="M13 4H17V18H13V10Z" fill="currentColor"/>
          <path d="M11 4H7V18H11V4Z" fill="currentColor" />
          {/* <path d="M18 18H6V20H18V18Z" fill="currentColor" /> */}
        </Icon>
      </a>
      <div className="navbar-brand">
        <ScreensLink to="/" className="df-logo">
          <img src={logo} className="site-logo" alt="" />
        </ScreensLink>
      </div>
      <div id="navbarMenu" className="navbar-menu-wrapper">
        <div className="navbar-menu-header">
          <ScreensLink to="/" className="df-logo">
            <img src={logo} className="site-logo" alt="" />
          </ScreensLink>
          <a id="mainMenuClose" onClick={handleMainMenuClose} href="">
          <Icon
            ariaLabel="Close"
            pathData="M6.2253 4.81108C5.83477 4.42056 5.20161 4.42056 4.81108 4.81108C4.42056 5.20161 4.42056 5.83477 4.81108 6.2253L10.5858 12L4.81114 17.7747C4.42062 18.1652 4.42062 18.7984 4.81114 19.1889C5.20167 19.5794 5.83483 19.5794 6.22535 19.1889L12 13.4142L17.7747 19.1889C18.1652 19.5794 18.7984 19.5794 19.1889 19.1889C19.5794 18.7984 19.5794 18.1652 19.1889 17.7747L13.4142 12L19.189 6.2253C19.5795 5.83477 19.5795 5.20161 19.189 4.81108C18.7985 4.42056 18.1653 4.42056 17.7748 4.81108L12 10.5858L6.2253 4.81108Z"
          />
          </a>
        </div>
        <ul className="nav navbar-menu">
          <li className="nav-item">
            <ScreensLink onClick={checkLoginState} to="/screens" className="nav-link icons screen-icon">
              Screens
            </ScreensLink>
          </li>
          <li className="nav-item">
            <ScreensLink onClick={checkLoginState} to="/watchlists" className="nav-link icons watchlist-icon">
              Watchlist
            </ScreensLink>
          </li>
          <li className="nav-item">
            <ScreensLink to="/" className="nav-link icons demat-icon" onClick={openModal}>
            {" "}<span className="desktop_text">Open Demat Account</span><span className="mobile_text">Demat A/C</span>
            </ScreensLink>
          </li>
        </ul>

        <div
          className="content-search"
          style={{ position: "relative", right: "20px" }}
        >
          <Icon pathData="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5
              3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5
              4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"
            viewBox={{
              width: 24,
              height: 24,
            }}
            ariaLabel="search"
          />
          <input
            id="company-search"
            onKeyUp={handleSearchCompanies}
            type="search"
            className="form-control"
            placeholder="Search for a company"
          />
          <ul
            className="dropdown-content dropdown-menu dropdown-menu-right"
            id="company-dropdown-content"
          >
            {filteredCompniesList.length > 0 ? (
              filteredCompniesList.map((col, index) => {
                return (
                  <li key={index} className={index}>
                    <a
                      href={col.url}
                      onClick={handleCompanyDetailPage}
                      target="_blank;"
                    >
                      {col.company_name}
                    </a>
                  </li>
                );
              })
            ) : (
              <div></div>
            )}
          </ul>
        </div>
        {isAuthenticated && (
          <div id="dropdown-profile" className="dropdown dropdown-profile mg-t-15">
            <a onClick={handleToggleMenu}
              className="btn btn-sm pd-x-15 btn-white btn-uppercase icons user-icon"
              data-toggle="dropdown"
              data-display="static"
              aria-expanded="false"
            >
              {user.attributes.given_name || user.username}
            </a>
            <div
              className={
                toggleProfile
                  ? "dropdown-menu dropdown-menu-right show"
                  : "dropdown-menu dropdown-menu-right"
              }
            >
              <a onClick={handleToggleMenu} type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </a>
              <h6 className="tx-semibold mg-b-10">{user.username}</h6>
              <ScreensLink onClick={ () => setToggleProfile() } to="/edit-profile" className="dropdown-item">
                Edit Profile
              </ScreensLink>
              {(user.username === '+918802355218' || user.username === '+919920065475') ? (
                <>
                  <ScreensLink onClick={ () => setToggleProfile() }  to="/admin/user-report" className="dropdown-item">
                  User Report
                  </ScreensLink>
                  <ScreensLink onClick={ () => setToggleProfile() }  to="/admin/demat-report" className="dropdown-item">
                  Demat Report
                  </ScreensLink>
                </>
              ):(<> </>)}  
              <button className="dropdown-item" onClick={signOut}>
                <i data-feather="user-check"></i>
                Logout
              </button>
            </div>
          </div>
        ) }
        { !isAuthenticated && (
          <div className="dropdown dropdown-profile mg-t-10">
            <Button
              variation="primary"
              loadingText=""
              size="small"
              onClick={ handleSignInSignUp }
              ariaLabel=""
            >
              Sign In <span className="desktop__only">/ Sign Up</span>
            </Button>
          </div>
        ) }
      </div>
      
      {/* Login / Signin Form Modal */}
      <Modal
        isOpen={signInOpen}
        onRequestClose={closeSignInModal}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Sign In / Sign Up"
      >
        <button onClick={closeSignInModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
        </button>
        <h2 className="tx-20"> { "Sign In / Sign Up" } </h2>
        <hr/>
        {isAuthenticated && <ProfilePage user={user} />}
        {!isAuthenticated && (
          <SigninForm alert={alert} alert_heading={alert_heading} />
        )}
      </Modal>

      {/* Open Demat Account Form Model */}
      <Modal
        isOpen={modalIsOpen}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Open Demat Account"
      > 
        
        <button onClick={closeModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
        </button>
        <h2 className="tx-16">Open 100% Free Demat A/C in just 5 Minute </h2>
        <p>Enjoy Lifetime Free Delivery Trading & Rs.20 / Trade in Intraday & F&O</p>
        <hr/>
        <OpenDematAccountForm />
        
      </Modal>
    </header>
     { location.pathname === "/" && (
        <>
            <div className="content content-fixed">
                <div className="container pd-x-10 pd-lg-x-10 pd-xl-x-0">
                    <div className="row-sm content1 content-fixed1 content-auth1 home">
                        <section className="content_box">
                            <h1>Screener - Most Advanced & Free Stock Screener in India</h1>
                            <p>Screener by Top10StockBroker is one of the most advanced & free stock Screener in India. It helps in detailed fundamental analysis & also provides details of Intraday Screener.</p>
                        </section>
                        <section className="search_section">
                            <div className="d-flex justify-content-center">
                                <div className="col-lg-8 col-md-12 col-sm-12 search_field_group">
                                    <SearchField id="home-company-search" onKeyUp={handleHomeSearchCompanies} label="search" placeholder="Search Company or Index..." />
                                    <ul
                                        className="dropdown-content dropdown-menu"
                                        id="home-company-dropdown-content"
                                    >
                                        {filteredCompniesList.length > 0 ? (
                                        filteredCompniesList.map((col, index) => {
                                            return (
                                            <li key={index} className={index}>
                                                <a
                                                href={col.url}
                                                onClick={handleCompanyDetailPage}
                                                target="_blank;"
                                                >
                                                {col.company_name}
                                                </a>
                                            </li>
                                            );
                                        })
                                        ) : (
                                        <div></div>
                                        )}
                                    </ul>
                                </div>
                            </div>
           
                            <div className="trending-stocks-container justify-content-center align-items-center mt-3">
                                <div className="item trendingStocks">
                                    <span>Trending Stocks:</span>
                                    <ul className="trending-wrap">
                                        {trendingStocks && trendingStocks.map((stock, index) => (
                                            <li className="stocks-item"  key={index}>
                                                <a href={stock.url} target="_blank">
                                                {stock.name}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </section>
                        <section className="built_screen">
                            <h2>Built your Screener Now!</h2>
                            <div className="button_group">
                                <ScreensLink  onClick={checkLoginState}
                                to="/screen/new"
                                className="btn btn-primary pd-x-20 btn-primary btn-uppercase yellow"
                                >
                                {" "}
                                Create new screen
                                </ScreensLink>
                                <ScreensLink  onClick={checkLoginState}
                                to="/watchlist/add"
                                className="btn btn-primary pd-x-20 btn-primary btn-uppercase mg-20 yellow"
                                >
                                {" "}
                                Create New Watchlist
                                </ScreensLink>
                                
                            </div>
                            
                        </section>
                    </div>
                    <div className="row-sm content1 content-fixed1 content-auth1 home">
                        <section className="top_performence">
                            <h2>Performance of Top Indices</h2>
                            <div className="row">
                                {topIndices && topIndices.map((stock, index) => (
                                    <div className="col-sm-6 col-lg-3" key={index}>
                                        <div className="card">
                                        <div className="card-header">
                                            <img src={stock.logo} alt={stock.name} width="50px"/>
                                            <h5>{stock.name}</h5>
                                        </div>
                                            <div className="card card-body">
                                                <a className="primary-sm-button"
                                                    href={stock.live_link} target="_blank">
                                                    {stock.live_name}
                                                </a>
                                                <a className="primary-sm-button"
                                                    href={stock.prediction_link} target="_blank">
                                                    {stock.prediction_name}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </section>
                        <section className="nifty_stock">
                            <h2>Top 12 Nifty Stocks</h2>
                            <div className="row">
                                {top10Stocks && top10Stocks.map((stock, index) => (
                                    <div className="col-sm-6 col-lg-3" key={index}>
                                        <div className="card mg-b-20">
                                            <div className="card-header">
                                                <img src={stock.logo} alt={stock.name} width="50px"/>
                                                <h5 className="card-title">
                                                    {stock.name}
                                                </h5>
                                            </div>
                                            <div className="card card-body">
                                                <a className="primary-sm-button"
                                                    href={stock.live_link} target="_blank">
                                                    {stock.live_name}
                                                </a>
                                                <a className="primary-sm-button"
                                                    href={stock.prediction_link} target="_blank">
                                                    {stock.prediction_name}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </>
     )}
    </>
  );
}

export default Header;
