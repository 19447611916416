import React, { useEffect, useState } from "react";
import CategoryStockScreens from "./Category-stock-screens";
import RightSidebar from "./Right-sidebar";

import { API } from "aws-amplify";
import { Alert } from "@aws-amplify/ui-react";

import { listScreens } from "../../graphql/queries";
import '../../styles/pages/dashboard.scss';
import { Link as ScreensLink, useParams } from "react-router-dom";
import { ThreeDots } from  'react-loader-spinner';

function Dashboard() {
  const [myScreens, setMyScreens] = useState(false);
  const [screensByCategory, setScreensByCategory] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const { messgage } = useParams();

  useEffect(() => {
    fetchCategoryStockScreens();
  }, []);

  async function fetchCategoryStockScreens() {
    try {
      const result = await API.graphql({
        query: listScreens,
        variables: {
          limit: 20,
          nextToken: "",
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      if (result.data.listScreens.my_screens) {
        setMyScreens(JSON.parse(result.data.listScreens.my_screens));
      }
      if (result.data.listScreens.screens_by_category) {
        setScreensByCategory(
          JSON.parse(result.data.listScreens.screens_by_category)
        );
      }
    } catch (e) {
      console.log(e);
      setErrorMessage(
        e.code === "InvalidParameterException"
          ? e.message
          : `Something went wrong. Please try again later.`
      );
    }
  }

  return (
    <div className="row __dashboard_page">
      <div className="col-lg-12 col-xl-9 mg-t-10">
        <div className="d-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30 __page_head_bar">
          <div className="title">
            <h2 className="mg-b-0 tx-spacing--1">Stock Screens</h2>
          </div>
          <div className="create_screen_btn save_query">
            <ScreensLink
              to="/screen/new"
              className="btn btn-primary pd-x-20 btn-primary btn-uppercase"
            >
              {" "}
              Create new screen
            </ScreensLink>
          </div>
        </div>
        {/* <div className="tx-center tx-center-f">
          <ThreeDots color="#e54f22" height={40} width={40} />
        </div> */}
        
        {messgage && (
          <Alert
            variation="success"
            heading="Success"
            isDismissible="true"
            marginBottom="2rem"
          >
            {messgage}
          </Alert>
        )}
        {errorMessage && (
          <Alert variation="error" isDismissible="true" marginBottom="2rem">
            {errorMessage}
          </Alert>
        )}
        {
          // Screens created by loggedin user
          myScreens && <CategoryStockScreens {...myScreens} />
        }

        {
          // Screens by category
          screensByCategory &&
            Object.keys(screensByCategory).map((key, index) => {
              return (
                <CategoryStockScreens key={index} {...screensByCategory[key]} />
              );
            })
        }
      </div>
      <div className="col-md-6 col-xl-3 mg-t-10">
        <RightSidebar />
      </div>
    </div>
  );
}
export default Dashboard;
