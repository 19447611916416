import React, { useEffect, useState } from "react";
// import { View,Link,Divider,Menu,MenuItem,Heading,Card,Flex,Button } from '@aws-amplify/ui-react';

import { Link as ScreensLink, useParams } from "react-router-dom";

import { API } from "aws-amplify";
import { listWatchlists } from "../../graphql/queries";
import { Alert } from "@aws-amplify/ui-react";

function Watchlists() {
  const [yourWatchlists, setYourWatchlists] = useState([]);
  const [yourScreensCategory, setYourScreensCategory] = useState([]);
  const { messgage } = useParams();

  useEffect(() => {
    async function fetchScreens() {
      const result = await API.graphql({
        query: listWatchlists,
        variables: { limit: 15, nextToken: "" },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      if (result.data.listWatchlists.items.length > 0) {
        setYourWatchlists(result.data.listWatchlists.items);
      }
      // if (result.data.listScreensByCategoryId.category) {
      //   setYourScreensCategory(result.data.listScreensByCategoryId.category);
      // }
    }
    fetchScreens();
  }, []);

  return (
    <div className="row">
      <div className="card card-body">
        <div className="card-header pd-t-20  align-items-start justify-content-between bd-b-0 pd-b-0">
          <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
            <div>
              <h2 className="mg-b-0 tx-spacing--1">{yourScreensCategory.name}</h2>
              <p>{yourScreensCategory.description}</p>
            </div>
            <div className="watch_list_button text-right ">
              <ScreensLink
                to="/watchlist/add"
                className="btn btn-primary pd-x-20 btn-primary btn-uppercase"
              >
                {" "}
                Create New Watchlist
              </ScreensLink>
            </div>
          </div>

          {messgage ? (
            <Alert isDismissible="true" variation="success" marginBottom="2rem">
              {messgage}
            </Alert>
          ) : (
            ""
          )}
        </div>
        <div className="row">
          {yourWatchlists.map((watchlist, index) => {
            return (
              <div className="col-sm-6 col-lg-4 pd-b-20" key={index}>
                <div className="card card-body">
                  <ScreensLink
                    to={"/watchlist/" + watchlist.wl_id}
                    params={{ wl_id: watchlist.wl_id }}
                    className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-bold mg-b-8"
                  >
                    {`${watchlist.name.substring(0, 20)}`}
                    <i className="icon-right a smaller"></i>
                  </ScreensLink>
                  <div className="d-flex d-lg-block d-xl-flex align-items-end">
                    <p className="tx-13 tx-color-03 mg-b-0">{`${watchlist.name.substring(
                      0,
                      50
                    )}`}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
export default Watchlists;
