import React, { useEffect } from "react";

import { API } from "aws-amplify";
import { getWatchlistById } from "../../graphql/queries";
import { deleteWatchlist } from "../../graphql/mutations";

import { Link as ScreensLink, useParams, useNavigate } from "react-router-dom";

function WatchlistDelete() {
  const navigate = useNavigate();
  const [watchlist, setWatchlist] = React.useState([]);
  const { wl_id } = useParams();

  useEffect(() => {
    async function fetchWatchlist() {
      const result = await API.graphql({
        query: getWatchlistById,
        variables: { wl_id },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      if (result.data.getWatchlistById) {
        setWatchlist(result.data.getWatchlistById);
      }
    }
    fetchWatchlist();
  }, []);
  let handleDeleteScreen = async (e) => {
    e.preventDefault();
    try {
      await API.graphql({
        query: deleteWatchlist,
        variables: { wl_id },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      navigate(`/watchlists/Watchlist has been deleted successfully.`);
    } catch (err) {
      console.log(err);
    }
    return false;
  };

  return (
    <div className="row">
      <div className="card card-body mg-lg-x-100">
        <div className="pd-lg-x-20">
          <form method="get" action="/screen/query-result/">
            <h3>Delete {watchlist.name}?</h3>
            <input type="hidden" name="sort" id="id_sort" />
            <input type="hidden" name="wl_id" id="id_wl_id" value="{wl_id}" />
            <p className="tx-18">
              Are you sure you want to delete{" "}
              <ScreensLink
                to={"/watchlist/" + wl_id}
                params={{ wl_id: wl_id }}
                className=""
              >
                {watchlist.name}
              </ScreensLink>{" "}
              screen?
            </p>
            <div className="row tx-14"></div>

            <div className="flex-row flex-space-between flex-gap-16">
              <div className="d-md-block">
                <button
                  onClick={handleDeleteScreen}
                  className="btn btn-outline-danger pd-x-20 mg-r-20  btn-uppercase"
                  type="submit"
                >
                  <i className="icon-beaker"></i>
                  Confirm Delete
                </button>
                <ScreensLink
                  to={"/watchlist/" + wl_id}
                  params={{ wl_id: wl_id }}
                  className="btn pd-x-20 btn-white btn-uppercase"
                >
                  Cancel
                </ScreensLink>
              </div>
            </div>
          </form>
          <div className="flex-row float-right mg-y-20">
            <ScreensLink
              to={"/watchlist/" + wl_id}
              className="btn btn-primary pd-x-20  btn-uppercase"
            >
              ← Go Back
            </ScreensLink>
          </div>
        </div>
      </div>
    </div>
  );
}
export default WatchlistDelete;
