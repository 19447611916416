import React, { useEffect, useState } from "react";
import { Link as ScreensLink, useParams } from "react-router-dom";
import SearchQueryScreen from "./SearchQueryScreen.js";
import { API } from "aws-amplify";
import {
  getScreenById,
  listScreenReport,
  listRatioAllOptions,
  listRatioGalleries,
  listIndustries,
} from "../../graphql/queries";

import { Alert } from "@aws-amplify/ui-react";

// Import data table components
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";

import { ThreeDots } from  'react-loader-spinner';

// Import CSV export component
import ExportCSV from './DownloadCsv';

function ScreenListView() {
    const [screen, setScreen] = React.useState([]);
    const [deleteButton, setDeleteButton] = React.useState([]);
    const [errorMessage, setErrorMessage] = useState(false);
    const { screen_id } = useParams();
    const { messgage } = useParams();
    const [query, setQuery] = useState(false);
    const [allOption, setRatioOption] = React.useState([]);
    const [ratioGalleryList, setRatioGalleryList] = React.useState(false);
    const [columns, setColumns] = React.useState([]);
    const [pageLoading, setPageLoading] = React.useState(true);
    const [tableData, setTableData] = React.useState([]);
    const [tableDataJson, setTableJson] = React.useState(false);
    const [page, setPage] = React.useState(1);
    const [rowPrePage, setRowPrePage] = React.useState(25);
    const [industries, setIndustries] = React.useState([]);
    const [industry_filter_open, setIndustryFilterOpen] = React.useState(false);
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);
    const [isFilter, setIsFilter] = useState(false);
    const [industry_selected, setIndustrySelected] = useState([]);
    
    const industryFilterMenu = document.querySelector("#industry-filter-menu");
    document.addEventListener("mousedown", (e) => {
      /**
       * If the user clicks outside of the profile menu, close the profile menu
       */
      if(industryFilterMenu){
        if (!industryFilterMenu.contains(e.target)) {
          setIndustryFilterOpen(false);
        }
      }
 
    });

  useEffect(() => {
    if(tableDataJson){
       setReportTable();
    }else{
      if(query === false) {
          fetchScreenReport();
          fetchScreen();
          fetchRatioGalleries();
      }
    }
    if(isFilter){
      setPageLoading(true);
      fetchScreenReport();
    }
  }, [tableDataJson,isFilter,industry_selected]);

  /**
   * @function fetchRatioGalleries - Fetch Ratio Galleries from API
   * @returns {void}
   */
  async function fetchRatioGalleries() {
      const result = await API.graphql({
        query: listRatioGalleries,
        variables: {
          limit: 20,
          nextToken: "",
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      if (result.data.listRatioGalleries) {
        
        setRatioGalleryList(result.data.listRatioGalleries.items);
      }
  }

  /**
   * @function fetchScreen - Fetch Screen from API
   * @returns {void}
   * @memberof ScreenListView
   * @param {string} screen_id
   */
  async function fetchScreen() {
    const result = await API.graphql({
      query: getScreenById,
      variables: { screen_id },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    if (result.data.getScreenById) {
      setScreen(result.data.getScreenById);
      setQuery(result.data.getScreenById.query);
      if (
        result.data.getScreenById.sc_id ===null) {
        setDeleteButton(
          <ScreensLink
            to={"/screen/delete/" + screen_id}
            params={{ screen_id: screen_id }}
            className="btn btn-outline-primary pd-x-20 btn-uppercase mg-x-10"
          >
            Delete Screen
          </ScreensLink>
        );
      }
    }
  }

  /**
   * @function fetchScreenReport - Fetching screen report data
   * @returns {void}
   * @memberof ScreenListView
   * @param {string} screen_id
   */

  const fetchScreenReport = async (e) => {
    // setPageLoading(true);
    var limit = rowPrePage;
    if(page === 1){
      setPageLoading(true);
      setPage('All');
    }else{
      limit = 1000;
    }
    try {
      let comma_sep_industry = '';
      if(industry_selected && industry_selected !== undefined){
        comma_sep_industry = industry_selected.map(item => item).join(',');
      }
      
      const result = await API.graphql({
          query: listScreenReport,
          variables: { search_query: query , screen_id:screen_id, industry:comma_sep_industry, limit:limit, nextToken:"" },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });
      var tblData = JSON.parse(result.data.listScreenReport.table);
      if (tblData.length) {
        if(page === 1){
          setRatioOption(result.data.listScreenReport.ratios);
          setIndustries(result.data.listScreenReport.industry_rows);
          setTableJson(JSON.parse(result.data.listScreenReport.table));
        }else{
          setTableData({
            data: JSON.parse(result.data.listScreenReport.table)
          });
          setPageLoading(false);
        }
      }else{
        setTableData({
            data: JSON.parse(result.data.listScreenReport.table)
        });
        setIsFilter(false);
        setPageLoading(false);
      }
    } catch (e) {
      console.log(e);
      setErrorMessage(`Invalid Search Query : [${query}]`);
      setPageLoading(false);
    }
  };

  let setReportTable = () => {
    let table_header_columns = getTableHeader(tableDataJson);
    // console.log(table_header_columns);
    setTableData({
       columns: table_header_columns,
       data: tableDataJson
    });
    setPageLoading(false);
    setTableJson(false);
    // Load more data after randaring the first page
    fetchScreenReport();
  }
  
  /**
   * 
   * @param {*} table_data 
   * @returns 
   */
  let getTableHeader = (table_data) => {
    // console.log(Object.keys(table_data[0]));
    if(table_data.length > 0){
        let fisrt_row = [];
        let columns = [];
        fisrt_row = Object.keys(table_data[0]);
        fisrt_row.forEach(( key,  idx) => {
            if(idx === 0){
              columns.push({
                name: handleInfoHelpText(key),
                selector: row => row[key],
                cell: (row, index, column, id) => {
                  return row[key];
                },
                sortable: true,
                width:'150px',
              });
            }else{
              columns.push({
                name: handleInfoHelpText(key),
                selector: row => row[key],
                sortable: true,
                right: (idx !== 0) ? true: false,
                cell: (row, index, column, id) => {
                  if(row[key] !== null){
                    return (isNaN(row[key]) ? row[key] : row[key].toFixed(2));
                  }else{
                    return '0.00';
                  }
                }
              });
            }
         }
        );
        setColumns(columns);
        return columns;
    }
  };

  /**
   * 
   * @param {*} screen_name - screen name 
   * @returns 
   */
  let handleInfoHelpText = (screen_name) => {
    // console.log(screen_name);
    if(allOption){
      let matches = allOption.filter(
        (v) => v.screen_name.toLowerCase() === screen_name.toLocaleLowerCase()
      );
      if (typeof matches !== "undefined" && matches != null && matches.length) {
        var colOptionValue = matches[0];
        if (colOptionValue.unit !== "NA") {
          return (
            <span title={colOptionValue.name} className="tx-primary">
              {" "}
              {colOptionValue.screen_name}{" "}
              <span className="btn-white">{colOptionValue.unit}</span>{" "}
            </span>
          );
        } else {
          return (
            <span className="tx-primary" title={colOptionValue.name}>
              {" "}
              {screen_name}
              <span className="btn-white"></span>{" "}
            </span>
          );
        }
      } else {
        return (
          <span className="tx-primary" title={screen_name}>
            {" "}
            {screen_name}
            <span className="btn-white"></span>{" "}
          </span>
        );
      }
    }
  };

  const handleIndustryFilter = (e) => {
    if(! industry_filter_open ){
      setIndustryFilterOpen(true);
    }else{
      setIndustryFilterOpen(false);
    }
  }

  // const handleSelectAll = (e) => {
  //   setIsCheckAll(!isCheckAll);
  //   setIsCheck(industries.map(li => li.FINCODE));
  //   if (isCheckAll) {
  //       setIsCheck([]);
  //   }
  // };


  const handleSelectIndustry = (e) => {
    var target = e.target;
    if(target.checked === true){
      setIndustrySelected([...industry_selected, target.value ] )
    }else{
      var selected = industry_selected;
      var unSelected = selected.filter(function(indust) { 
          return indust !== target.value 
      });
      setIndustrySelected(unSelected);
    }
    setIsFilter(true); 
   };
  
  /**
   * @function return - Component return
   * @returns {html Element}
   */
  return (
    <div className="row">
      <div className="card card-body pd-15">
      {messgage && (
        <Alert
          variation="success"
          heading="Success"
          isDismissible="true"
          marginBottom="2rem"
        >
          {messgage}
        </Alert>
      )}
      <div className="align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30 __page_head_bar d-flex">
        <div className="title">
          <h2 className="mg-b-0 tx-spacing--1">{screen.name}</h2>
        </div>
        <div className="delete_screen_btn">{deleteButton}</div>
      </div>
      <div className="d-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30 flex-wrap">
        <div className="table_body_content mb-3">
          <p className="mg-b-0 tx-spacing--1">{screen.description}</p>
          {errorMessage && (
            <Alert
              variation="error"
              isDismissible={false}
              hasIcon={true}
              heading="Error"
            >
              {errorMessage}
            </Alert>
          )}
        </div>
        <div className="pd-0 col-12 d-flex align-items-md-end justify-content-md-end flex-wrap button_wrap" style={{ position: "relative" }}>
            
          <button className="btn btn-white pd-x-20 btn-uppercase mg-x-10 mb-2 filter_icon_added " onClick={handleIndustryFilter}>
            {" "}
            Industry
          </button>
           
          <ul  style={{ minWidth: "225px" }} className={(industry_filter_open) ? 'dropdown-content font-size-14 visible' : 'dropdown-content font-size-14' } id="industry-filter-menu">
            {/*<li data-idx="all">
              <label className="label-body">
              <input type="checkbox" name="selectAll" id="selectAll" onClick={handleSelectAll} /> <span> Check All </span>
              </label>
            </li> */}
            {industries.length > 0 ? (
              industries.map((col, index) => {
                return (
                  <li key={index} className={index}>
                    <label className="label-body">
                      <input  onClick={handleSelectIndustry}  key={'ind_checkbox_'+ index} type="checkbox" value={col.Industry} /> 
                      <span> {col.Industry} - {col.company_count} </span>
                    </label>
                  </li>
                );
              })
              ) : (
              <div></div>
            )}
          </ul>
           
          <ExportCSV {...tableData} />
          <ScreensLink
            to={"/screen/columns"}
            className="btn btn-outline-primary pd-x-20 btn-uppercase mg-x-10 mb-2 edit-icon "
          >
            Edit Columns
          </ScreensLink>
        </div>
      </div>
      <div className="table-data-wrap">
        <DataTableExtensions  export={false} columns={columns} filter={false} print={false} {...tableData}>
        <DataTable
            columns={columns}
            // data={dematReport}
            noHeader
            defaultSortField="MCap"
            defaultSortAsc={false}
            highlightOnHover
            persistTableHead
            pagination 
            // paginationServer
            // paginationTotalRows={totalRows}
            // onChangePage={page => setPage(page)}
            paginationPerPage={rowPrePage}
            noDataComponent={" No Data found in this search criteria. "}
            progressPending ={pageLoading}
            progressComponent ={<ThreeDots color="#e54f22" height={40} width={40} />}
          />
        </DataTableExtensions>
      </div>     
      <hr />
      {ratioGalleryList && <SearchQueryScreen {...{screen:screen,ratioGalleryList:ratioGalleryList}}/>}
      </div>
    </div>
  );
}
export default ScreenListView;
